import {
  GET_ORDER,
  SET_ORDER_DETAILS,
  SET_ORDER_CUSTOMER,
  SET_ORDER_REFERENCE,
  SET_ORDER_DELIVERY_ADDRESS,
  SET_ORDER_FULFILMENT_TIME,
  DELIVERY_ORDER,
} from "./orderConstants";

const initialState = {
  type: null,
  locationId: null,
  locationName: null,
  locationAddress: null,
  customerName: null,
  customerEmail: null,
  customerPhone: null,
  customerMarketingOptIn: false,
  messageForRestaurant: null,
  deliveryAddress: {
    line1: null,
    line2: null,
    city: null,
    postcode: null,
    distanceToPostcode: 0.0,
  },
  fulfilmentTime: null,
  reference: null,
};

const order = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDER:
      return {
        ...state,
      };
    case SET_ORDER_DETAILS:
      return {
        ...state,
        type: action.payload.type,
        locationId: action.payload.location.id,
        locationName: action.payload.location.name,
        locationAddress: action.payload.location.address,
        deliveryAddress: {
          line1: null,
          line2: null,
          city: null,
          postcode:
            action.payload.type === DELIVERY_ORDER
              ? action.payload.deliveryPostcode
              : null,
          distanceToPostcode:
            action.payload.type === DELIVERY_ORDER
              ? action.payload.distanceToPostcode
              : 0,
        },
      };
    case SET_ORDER_CUSTOMER:
      const { name, email, phone, marketingOptIn, messageForRestaurant } =
        action.payload;
      return {
        ...state,
        customerName: name,
        customerEmail: email,
        customerPhone: phone,
        customerMarketingOptIn: marketingOptIn,
        messageForRestaurant: messageForRestaurant
          ? messageForRestaurant
          : null,
      };
    case SET_ORDER_REFERENCE:
      return {
        ...state,
        reference: action.payload.orderReference,
      };
    case SET_ORDER_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryAddress: {
          line1: action.payload.line1,
          line2: action.payload.line2 || null,
          city: action.payload.city,
          postcode: state.deliveryAddress.postcode,
          distanceToPostcode: state.deliveryAddress.distanceToPostcode,
        },
      };
    case SET_ORDER_FULFILMENT_TIME:
      return {
        ...state,
        fulfilmentTime: action.payload.fulfilmentTime,
      };
    default:
      return state;
  }
};

export default order;

// SELECTORS
// ================================================
export const getOrder = (state) => {
  return state.order;
};

export const getDeliveryAddressForOrderSubmission = (state) => {
  if (state.order.type === DELIVERY_ORDER) {
    return {
      line1: state.order.deliveryAddress.line1,
      line2: state.order.deliveryAddress.line2,
      city: state.order.deliveryAddress.city,
      postcode: state.order.deliveryAddress.postcode,
      distance: state.order.deliveryAddress.distanceToPostcode,
    };
  } else {
    return null;
  }
};

export const getDeliveryAddressAsText = (state) => {
  if (state.order.type === DELIVERY_ORDER) {
    var addressText = state.order.deliveryAddress.line1;

    if (state.order.deliveryAddress.line2) {
      addressText += `, ${state.order.deliveryAddress.line2}`;
    }

    return (
      addressText +
      `, ${state.order.deliveryAddress.city}, ${state.order.deliveryAddress.postcode}`
    );
  } else {
    return null;
  }
};
