export const GET_ORDER = "GET_ORDER";
export const SET_ORDER_DETAILS = "SET_ORDER_DETAILS";
export const SET_ORDER_CUSTOMER = "SET_ORDER_CUSTOMER";
export const SET_ORDER_DELIVERY_ADDRESS = "SET_ORDER_DELIVERY_ADDRESS";
export const SET_ORDER_REFERENCE = "SET_ORDER_REFERENCE";
export const SET_ORDER_FULFILMENT_TIME = "SET_ORDER_FULFILMENT_TIME";

export const COLLECTION_ORDER = "COLLECTION_ORDER";
export const DELIVERY_ORDER = "DELIVERY_ORDER";
export const TABLE_ORDER = "TABLE_ORDER";

const defaultExports = {
  COLLECTION_ORDER,
  DELIVERY_ORDER,
};
export default defaultExports;
