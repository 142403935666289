export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";

export const VERIFY_TOKEN_REQUEST = "TOKEN_REQUEST";
export const VERIFY_TOKEN_SUCCESS = "TOKEN_SUCCESS";
export const VERIFY_TOKEN_FAILURE = "TOKEN_FAILURE";

export const LOGOUT = "LOGOUT";

export const PASSWORD_RESET = "PASSWORD_RESET";
export const NEW_PASSWORD_REQUEST = "NEW_PASSWORD_REQUEST";
export const NEW_PASSWORD_SUCCESS = "NEW_PASSWORD_SUCCESS";
export const NEW_PASSWORD_FAILURE = "NEW_PASSWORD_FAILURE";

export const SET_LOGGED_IN_USER_PHONE = "SET_LOGGED_IN_USER_PHONE";
export const SET_LOGGED_IN_USER_MARKETING_OPT_IN =
  "SET_LOGGED_IN_USER_MARKETING_OPT_IN";
export const SET_LOGGED_IN_USER_DELIVERY_ADDRESS =
  "SET_LOGGED_IN_USER_DELIVERY_ADDRESS";

export const PASSWORD_RESET_ERROR_TOKEN_EXPIRED = "Token Expired";
