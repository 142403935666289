import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const StyledBreadcrumbLink = styled(Link)({
  textDecoration: "none",
});

export const StyledPaymentFormContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(0, 0),
}));

export const StyledRadioGroupFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  border: "1px solid rgba(0, 0, 0, 0.12)",
  borderRadius: 4,
  width: "100%",
}));

export const StyledRadioGroupFormControlLabel = styled(FormControlLabel)({
  margin: 0,
});

export const StyledFormContainer = styled(Container)(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(3),
}));
