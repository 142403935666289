import { styled } from "@mui/material/styles";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: 0,
  display: "block",
}));

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  margin: `${theme.spacing(1)} 0`,
}));

export const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  margin: `${theme.spacing(1)} 0`,
}));
