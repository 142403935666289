import getPalette from "./palette";
import { createTheme } from "@mui/material/styles";
//import red from '@material-ui/core/colors/red';

let theme = createTheme({});
let palette = getPalette(theme);

theme = createTheme(theme, {
  palette: palette,
  typography: {
    useNextVariants: true,
    fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    color: "#262626",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    h1: {
      fontWeight: 600,
      fontSize: "1.5rem",
    },
    h2: {
      fontWeight: 400,
      fontSize: "1.2rem",
    },
    h6: {
      textTransform: "uppercase",
      letterSpacing: 2,
      fontSize: 17,
      fontWeight: 600,
    },
    body1: {
      fontWeight: 300,
    },
    body2: {
      fontWeight: 200,
    },
    captionMobile: {
      fontSize: 10,
      lineHeight: 1.3,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText || "#fff",
            "&:hover": {
              color: palette.primary.contrastText || "#fff",
              backgroundColor: palette.primary.hover,
            },
            "&:active": {
              color: palette.primary.contrastText || "#fff",
              backgroundColor: palette.primary.hover,
            },
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            backgroundColor: palette.secondary.main,
            color: palette.primary.contrastText || "#fff",
            "&:hover": {
              color: palette.primary.contrastText || "#fff",
              backgroundColor: palette.secondary.hover,
            },
            "&:active": {
              color: palette.primary.contrastText || "#fff",
              backgroundColor: palette.secondary.hover,
            },
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: "close" },
          style: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: "black",
          },
        },
      ],
    },
  },
  shadows: Array(25).fill("none"),
});
export default theme;
