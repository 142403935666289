import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  useStripe,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import {
  PAYMENT_TYPE_CARD,
  PAYMENT_TYPE_APPLE_PAY,
  PAYMENT_TYPE_GOOGLE_PAY,
} from "./paymentConstants";

const getPaymentType = (type) => {
  switch (type) {
    case "applePay":
      return PAYMENT_TYPE_APPLE_PAY;
    case "googlePay":
      return PAYMENT_TYPE_GOOGLE_PAY;
    default:
      return PAYMENT_TYPE_CARD;
  }
};

const useOptions = (paymentRequest) => {
  const options = useMemo(
    () => ({
      paymentRequest,
      style: {
        paymentRequestButton: {
          theme: "dark",
          height: "40px",
          type: "default",
        },
      },
    }),
    [paymentRequest]
  );

  return options;
};

const usePaymentRequest = ({ options, onPaymentMethod }) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  useEffect(() => {
    if (stripe && paymentRequest === null) {
      const pr = stripe.paymentRequest(options);
      setPaymentRequest(pr);
    }
  }, [stripe, options, paymentRequest]);

  useEffect(() => {
    let subscribed = true;
    if (paymentRequest) {
      paymentRequest.canMakePayment().then((res) => {
        if (res && subscribed) {
          setCanMakePayment(true);
        }
      });
    }

    return () => {
      subscribed = false;
    };
  }, [paymentRequest]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on("paymentmethod", onPaymentMethod);
    }
    return () => {
      if (paymentRequest) {
        paymentRequest.off("paymentmethod", onPaymentMethod);
      }
    };
  }, [paymentRequest, onPaymentMethod]);

  return canMakePayment ? paymentRequest : null;
};

const PaymentRequestForm = (props) => {
  const stripe = useStripe();
  const [walletPaymentFailed, setWalletPaymentFailed] = useState(false);
  const {
    currency,
    label,
    amount,
    paymentToken,
    submitPlaceholderOrder,
    orderSubmissionFailed,
    paymentSucceeded,
    paymentFailed,
  } = props;

  useEffect(() => {
    if (walletPaymentFailed) {
      paymentFailed();
    }
  }, [walletPaymentFailed, paymentFailed]);

  const paymentRequest = usePaymentRequest({
    options: {
      country: "GB",
      currency,
      total: {
        label,
        amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    },
    onPaymentMethod: async ({ complete, paymentMethod, ...data }) => {
      //console.log("[PaymentMethod]", paymentMethod);
      //console.log("[Customer Data]", data);

      submitPlaceholderOrder(getPaymentType(data.walletName))
        .then(async (orderReference) => {
          //console.log("PaymentRequestForm.js orderReference", orderReference);

          const { paymentIntent, error: confirmError } =
            await stripe.confirmCardPayment(
              paymentToken,
              { payment_method: paymentMethod.id },
              { handleActions: false }
            );

          if (confirmError) {
            console.error(confirmError);
            complete("fail"); // Notify browser of the failure
            setWalletPaymentFailed(true);
          } else {
            complete("success"); // Notify browser of success

            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11"
            // instead check for: `paymentIntent.status === "requires_source_action"`.
            if (
              paymentIntent.status === "requires_action" ||
              paymentIntent.status === "requires_source_action"
            ) {
              // Let Stripe.js handle the rest of the payment flow.
              const { error } = await stripe.confirmCardPayment(paymentToken);

              if (error) {
                setWalletPaymentFailed(true);
              } else {
                paymentSucceeded(orderReference);
              }
            } else {
              paymentSucceeded(orderReference);
            }
          }
        })
        .catch((error) => {
          complete("success"); // Notify browser of success because we didn't even attempt payment.
          orderSubmissionFailed(error);
        });
    },
  });
  const options = useOptions(paymentRequest);

  if (!paymentRequest) {
    return null;
  }

  return (
    <PaymentRequestButtonElement
      options={options}
      onClick={() => setWalletPaymentFailed(false)}
    />
  );
};

PaymentRequestForm.propTypes = {
  currency: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  paymentToken: PropTypes.string.isRequired,
  paymentFailed: PropTypes.func.isRequired,
  paymentSucceeded: PropTypes.func.isRequired,
  submitPlaceholderOrder: PropTypes.func.isRequired,
  orderSubmissionFailed: PropTypes.func.isRequired,
};

export default PaymentRequestForm;
