import {
  initCart,
  applyPromotionsToCart,
  removeAllItemsFromCart,
} from "../actions/cartActions";
import { initCheckout } from "../actions/checkoutActions";
import { CART_EXPIRATION } from "../reducers/cartConstants";

export function startNewOrder(
  dispatch,
  cart,
  order,
  locationDetails,
  promotions,
  fees
) {
  return new Promise(function (resolve, reject) {
    dispatch(initCart(locationDetails, order, promotions, fees)).then(() => {
      dispatch(initCheckout());
      var emptyCart = false;

      if (cart.locationId === null && order.locationId !== null) {
        // console.log("Empty cart because we are no longer multi-location.");
        emptyCart = true;
      }

      if (cart.locationId !== order.locationId) {
        // console.log(
        //   "Empty cart because the contents are for a different location. New location ID: " +
        //     cart.locationId +
        //     " Previous location ID: " +
        //     order.locationId
        // );
        emptyCart = true;
      }

      if (
        cart.lastUpdate === 0 ||
        ((Date.now() / 1000) | 0) - cart.lastUpdate > CART_EXPIRATION
      ) {
        // console.log("Empty cart because it has expired.");
        emptyCart = true;
      }

      if (emptyCart) {
        dispatch(removeAllItemsFromCart());
      } else {
        dispatch(applyPromotionsToCart(promotions));
      }

      resolve();
    });
  });
}
