import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMerchantDetails } from "../../reducers";

import Header from "../Header/Header";
import MapMarker from "../MapMarker/MapMarker";

import { getCdnImageUrl } from "../../utils/assetUtils";
import { createMapOptions, MAP_ZOOM } from "../../utils/maps";
import GoogleMapReact from "google-map-react";

import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Link from "@mui/material/Link";

import cssStyles from "./Contact.module.scss";

export const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: 500,
  padding: theme.spacing(2),
  textAlign: "center",
}));

function Contact(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { merchant } = props.getMerchantDetails;
  const backgroundImageUrl = getCdnImageUrl(
    "web/background.webp",
    merchant.name
  );
  const mapMarkerImageUrl = getCdnImageUrl("web/logo.webp", merchant.name);

  function renderContactDetails() {
    var contactDetails = [];
    for (let location of merchant.locations) {
      contactDetails.push(
        <StyledPaper key={location.id} square={isMobile}>
          <Typography variant="h2" gutterBottom>
            {location.name}
          </Typography>

          <div className={cssStyles.iconContainer}>
            <PhoneIcon sx={{ marginRight: 1 }} />
            <p>
              <a href={`tel:${location.phone}`}>{location.phone}</a>
            </p>
          </div>

          <div className={cssStyles.iconContainer}>
            <LocationOnIcon sx={{ marginRight: 1 }} />
            <p>
              <Link
                variant="body1"
                color="inherit"
                underline="always"
                href={`http://maps.google.co.uk/?q=${encodeURI(
                  location.address
                )}`}
                target="_blank"
                rel="noreferrer"
              >
                {location.address}
              </Link>
            </p>
          </div>

          <div className={cssStyles.map}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
              }}
              defaultZoom={MAP_ZOOM}
              defaultCenter={{
                lat: location.latitude,
                lng: location.longitude,
              }}
              options={createMapOptions}
            >
              <MapMarker
                lat={location.latitude}
                lng={location.longitude}
                imageUrl={mapMarkerImageUrl}
              />
            </GoogleMapReact>
          </div>
        </StyledPaper>
      );
    }

    return contactDetails;
  }

  return (
    <>
      <Header
        showNav={true}
        merchant={merchant}
        loginCompletionUrl={window.location.pathname}
      />
      <main
        style={
          isMobile && merchant.locations.length === 1
            ? { background: "none" }
            : { backgroundImage: `url("${backgroundImageUrl}")` }
        }
        className={cssStyles.main}
      >
        {renderContactDetails()}
      </main>
    </>
  );
}

function mapStateToProps(state) {
  return {
    getMerchantDetails: getMerchantDetails(state),
  };
}

Contact.propTypes = {
  getMerchantDetails: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Contact);
