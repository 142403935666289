import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMerchantDetails } from "../../reducers";
import Header from "../Header/Header";

function Privacy(props) {
  const { merchant } = props.getMerchantDetails;

  return (
    <>
      <Header
        showCartIcon={false}
        showNav={true}
        merchant={merchant}
        loginCompletionUrl="/"
      />

      <main style={{ width: "100%", minHeight: "100vh" }}>
        <iframe
          style={{
            width: "100%",
            minHeight: "calc(100vh - 65px)",
            marginTop: 65,
          }}
          frameBorder="0"
          title="privacy"
          src={merchant.privacy_policy}
        ></iframe>
      </main>
    </>
  );
}

function mapStateToProps(state) {
  return {
    getMerchantDetails: getMerchantDetails(state),
  };
}

Privacy.propTypes = {
  getMerchantDetails: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Privacy);
