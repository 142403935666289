import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  getMerchantDetails,
  getOrder,
  getDeliveryAddressAsText,
} from "../../reducers";
import { getMerchantLocation } from "../../utils/merchant";
import { isCollectionOrder, isDeliveryOrder } from "../../utils/order";
import { createMapOptions, MAP_ZOOM } from "../../utils/maps";
import { getCdnImageUrl } from "../../utils/assetUtils";
import { getCurrentFulfilmentTime } from "../../utils/fulfilment";

import Header from "../Header/Header";
import MapMarker from "../MapMarker/MapMarker";
import GoogleMapReact from "google-map-react";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

import cssStyles from "./OrderComplete.module.scss";
import mapPin from "../../images/map-pin.svg";

function OrderComplete(props) {
  const navigate = useNavigate();
  const { merchant } = props.getMerchantDetails;
  const order = props.getOrder;
  const collectionOrder = isCollectionOrder(order);
  const locationDetails = getMerchantLocation(merchant, order.locationId);
  const backgroundImageUrl = getCdnImageUrl(
    "web/background.webp",
    merchant.name
  );
  const deliveryOrder = isDeliveryOrder(order);
  const mapMarkerImageUrl = deliveryOrder
    ? mapPin
    : getCdnImageUrl("web/logo.webp", merchant.name);

  useEffect(() => {
    if (!order.reference) {
      console.warn("No order reference. Session expired? Returning to index.");
      navigate("/", { replace: true });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const printFulfilmentInstructions = () => {
    const currentFulfilmentTime = getCurrentFulfilmentTime(
      locationDetails,
      order.type,
      order.fulfilmentTime !== null
    );

    if (!currentFulfilmentTime) {
      console.error("Failed to get fulfilment time for order.");
      Sentry.captureException(
        new Error("Failed to get fulfilment time for order."),
        {
          extra: order,
        }
      );
    }

    if (collectionOrder) {
      return (
        <Container sx={{ marginBottom: 2 }}>
          {order.fulfilmentTime === null && (
            <Typography variant="body1" display="block" paragraph>
              {`The current prep time for collection orders is ${currentFulfilmentTime.text}, but this can change quickly during busy periods. Once your order has been accepted you'll receive an order confirmation email with an estimated time for collection.`}
            </Typography>
          )}

          <Typography variant="body1" display="inline">
            Please collect your order from:&nbsp;
          </Typography>
          <Link
            variant="body1"
            color="inherit"
            underline="always"
            href={`http://maps.google.co.uk/?q=${encodeURI(
              locationDetails.address
            )}`}
            target="_blank"
            rel="noreferrer"
          >
            {locationDetails.address}
          </Link>
          <Typography variant="body1" display="inline">
            &nbsp;.
          </Typography>
        </Container>
      );
    } else {
      if (order.fulfilmentTime === null) {
        // TODO: Remove this check once we've fixed currentFulfilmentTime sometimes being undefined
        if (currentFulfilmentTime) {
          return (
            <Container sx={{ marginBottom: 2 }}>
              <Typography variant="body1" display="block">
                {`The current prep time for delivery orders is ${currentFulfilmentTime.text}, but this can change quickly during busy periods. Once your order has been accepted you'll receive an order confirmation email with an estimated delivery time.`}
              </Typography>
            </Container>
          );
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  };

  return (
    <>
      <Header showCartIcon={false} merchant={merchant} showLoginIcon={false} />

      <main
        style={{ backgroundImage: `url("${backgroundImageUrl}")` }}
        className={cssStyles.main}
      >
        <Paper className={cssStyles.paper}>
          <Container
            sx={{ display: "flex", flexDirection: "row", marginBottom: 1 }}
          >
            <div>
              <CheckCircleOutline
                sx={{ fontSize: 70, marginRight: 1, color: "primary.main" }}
              />
            </div>
            <div>
              <Typography variant="overline" display="block">
                {`order #${order.reference}`}
              </Typography>
              <Typography variant="h5" display="block">
                {`Thank you ${order.customerName}!`}
              </Typography>
            </div>
          </Container>

          <Container sx={{ marginBottom: 2 }}>
            <Typography variant="body1" display="block" paragraph>
              {`Your order has been sent to ${locationDetails.name}.`}
            </Typography>
          </Container>

          <Container sx={{ marginBottom: 2 }}>
            <Typography variant="body1" display="inline">
              A confirmation email will be sent to&nbsp;
            </Typography>
            <Box
              variant="body1"
              display="inline"
              fontWeight="fontWeightRegular"
            >
              {order.customerEmail}
            </Box>
            <Typography variant="body1" display="inline">
              &nbsp;once your order has been accepted.
            </Typography>
          </Container>

          {printFulfilmentInstructions()}

          {order.fulfilmentTime !== null && (
            <Container sx={{ marginBottom: 2 }}>
              {`Requested ${
                collectionOrder ? "collection" : "delivery"
              } time: ${moment(order.fulfilmentTime).format("h:mm A")}`}
            </Container>
          )}

          <Container className={cssStyles.map}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                region: "GB",
                authReferrerPolicy: "origin",
                language: "en",
                version: "quarterly",
                channel: "",
                mapIds: "",
              }}
              defaultZoom={MAP_ZOOM}
              defaultCenter={{
                lat: locationDetails.latitude,
                lng: locationDetails.longitude,
              }}
              options={createMapOptions}
            >
              <MapMarker
                lat={locationDetails.latitude}
                lng={locationDetails.longitude}
                imageUrl={mapMarkerImageUrl}
                width={35}
                height={35}
              />
            </GoogleMapReact>
            {deliveryOrder && (
              <div className={cssStyles.mapDeliveryDialog}>
                <>
                  <div className={cssStyles.mapDeliveryDialogHeading}>
                    Delivering to
                  </div>
                  <div className={cssStyles.mapDeliveryDialogText}>
                    {props.getDeliveryAddressAsText}
                  </div>
                </>
              </div>
            )}
          </Container>
        </Paper>
      </main>
    </>
  );
}

function mapStateToProps(state) {
  return {
    getMerchantDetails: getMerchantDetails(state),
    getOrder: getOrder(state),
    getDeliveryAddressAsText: getDeliveryAddressAsText(state),
  };
}

OrderComplete.propTypes = {
  getMerchantDetails: PropTypes.object.isRequired,
  getOrder: PropTypes.object.isRequired,
  getDeliveryAddressAsText: PropTypes.string,
};

export default connect(mapStateToProps)(OrderComplete);
