import MyOrdersDialog from "../Dialog/MyOrdersDialog";
import Typography from "@mui/material/Typography";

function CustomerMessage(props) {
  const { visible, message, onClose } = props;
  return (
    <MyOrdersDialog
      dialogOpen={visible}
      title={message.title}
      body={
        <Typography variant="body" paragraph>
          {message.body}
        </Typography>
      }
      buttonText="OK"
      onClose={onClose}
      disableBackdropClick={false}
    />
  );
}

export default CustomerMessage;
