import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { postSignupRequest } from "../../actions/loginActions";
import { getLoginDetails, getMerchantDetails } from "../../reducers";
import { usePrevious } from "../../utils/state";
import { getCdnImageUrl } from "../../utils/assetUtils";

import Header from "../Header/Header";
import SignupForm from "./SignupForm";
import { StyledPaper } from "./StyledComponents";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";

import cssStyles from "./Login.module.scss";

const SIGN_UP_ERROR_EXISTING_USER =
  "A user with this email address already exists.";
const SIGN_UP_ERROR_GENERIC = "Sorry, something has gone wrong.";

function Signup(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { merchant } = props.getMerchantDetails;
  const user = props.getLoginDetails;

  const backgroundImageUrl = getCdnImageUrl(
    "web/background.webp",
    merchant.name
  );

  const [signUpError, setSignUpError] = useState(null);

  useEffect(() => {
    if (user.isAuthenticated) {
      const nextUrl =
        location.state && location.state.completionUrl
          ? location.state.completionUrl
          : "/";
      navigate(nextUrl, { replace: true });
    }
  }, [user.isAuthenticated, location, navigate]);

  const previousLoginDetails = usePrevious(props.getLoginDetails);
  useEffect(() => {
    if (
      previousLoginDetails &&
      previousLoginDetails.isAuthenticating &&
      !user.isAuthenticating
    ) {
      if (user.isAuthenticated) {
        const nextUrl =
          location.state && location.state.completionUrl
            ? location.state.completionUrl
            : "/";
        navigate(nextUrl, { replace: true });
      } else {
        const errorMessage =
          user.error &&
          typeof user.error === "string" &&
          user.error === "User Exists"
            ? SIGN_UP_ERROR_EXISTING_USER
            : SIGN_UP_ERROR_GENERIC;

        setSignUpError(errorMessage);
      }
    }
  }, [previousLoginDetails, user, location, navigate]);

  function signup(name, email, password) {
    props.dispatch(postSignupRequest({ name, email, password }));
  }

  function hideSignUpError() {
    setSignUpError(null);
  }

  return (
    <>
      <Header showLoginIcon={false} merchant={merchant} />

      <main
        style={
          isMobile
            ? { background: "none" }
            : { backgroundImage: `url("${backgroundImageUrl}")` }
        }
        className={cssStyles.main}
      >
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Create an account
          </Typography>
          <SignupForm
            onSignup={signup}
            signUpError={signUpError}
            hideSignUpError={hideSignUpError}
          />
        </StyledPaper>
      </main>
    </>
  );
}

function mapStateToProps(state) {
  return {
    getMerchantDetails: getMerchantDetails(state),
    getLoginDetails: getLoginDetails(state),
  };
}

Signup.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getMerchantDetails: PropTypes.object.isRequired,
  getLoginDetails: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Signup);
