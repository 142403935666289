import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { getCookie, setCookie } from "../../utils/cookies";

import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import MyOrdersDialog from "../Dialog/MyOrdersDialog";

const PREFIX = "CookieBanner";

const classes = {
  banner: `${PREFIX}-banner`,
  content: `${PREFIX}-content`,
  buttonWrapper: `${PREFIX}-buttonWrapper`,
  privacyLink: `${PREFIX}-privacyLink`,
};

const StyledLink = styled(Link)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.primary.main,
  "&:hover": {
    background: "transparent",
    color: theme.palette.primary.main,
  },
  "&:visited": {
    color: theme.palette.primary.main,
  },
}));

const StyledMyOrdersDialog = styled(MyOrdersDialog)(({ theme }) => ({
  [`& .${classes.banner}`]: {
    borderRadius: 4,
    width: "auto",
    margin: "81px auto",
    padding: theme.spacing(1),
  },

  [`& .${classes.content}`]: {
    color: "black",
    backgroundColor: "white",
  },

  [`& .${classes.buttonWrapper}`]: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
}));

export const ACCEPTS_COOKIE_NAME = "accepts-cookies";

export class CookieBanner extends Component {
  constructor(props) {
    super(props);
    const { cookies } = this.props;
    this.state = { open: getCookie(cookies, ACCEPTS_COOKIE_NAME) !== "true" };
  }

  onAccept = () => {
    const { cookies } = this.props;
    setCookie(cookies, ACCEPTS_COOKIE_NAME, "true");
    this.setState({ open: false });
  };

  message = () => {
    return (
      <>
        <Typography variant="body1" paragraph>
          We use cookies and similar technologies to enhance your experience
          whilst using this website.
        </Typography>
        <Typography variant="body1" paragraph>
          We <b>do not</b> use cookies for advertising, but cookies are required
          for the website to function as intended. You can find out more by
          viewing our <StyledLink href="/privacy">Privacy Policy</StyledLink>.
        </Typography>
      </>
    );
  };

  render() {
    return (
      <StyledMyOrdersDialog
        dialogOpen={this.state.open}
        title="Cookies"
        body={this.message()}
        buttonId="accept-cookies-button"
        buttonText="Accept Necessary Cookies"
        onClose={this.onAccept}
        disableBackdropClick={true}
      />
    );
  }
}

CookieBanner.propTypes = {
  //theme: PropTypes.object.isRequired,
  cookies: instanceOf(Cookies).isRequired,
};

//export default withTheme(withCookies(CookieBanner));
export default withCookies(CookieBanner);
