import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import { Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import "./slider.scss";

const getPromotionText = (promotion) => {
  if (!promotion.title_emphasis_text) {
    return promotion.title_text;
  } else {
    return (
      <>
        <span>{promotion.title_emphasis_text}</span> {promotion.title_text}
      </>
    );
  }
};

export function PromotionsSlider(props) {
  const { promotions } = props;

  const sliderPromotions = promotions.map((promotion, index) => {
    return (
      <SwiperSlide key={index}>
        <div className="sliderContent">
          <div className="inner">
            <h1>{getPromotionText(promotion)}</h1>
            {promotion.description && (
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                onClick={() => showDetails(index)}
              >
                Details
              </Button>
            )}
          </div>
        </div>
      </SwiperSlide>
    );
  });

  const showDetails = (promotionId) => {
    props.showDetails(promotionId);
  };

  if (promotions.length === 0) {
    return null;
  }

  return (
    <Swiper
      navigation={true}
      modules={[Navigation, Autoplay]}
      autoplay={{
        delay: 2500,
      }}
      className="promotion-slider"
    >
      {sliderPromotions}
    </Swiper>
  );
}

PromotionsSlider.propTypes = {
  promotions: PropTypes.array.isRequired,
  showDetails: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default PromotionsSlider;
