export const MAP_ZOOM = 16;

export function createMapOptions(maps) {
  return {
    panControl: false,
    mapTypeControl: false,
    scrollwheel: false,
    disableDefaultUI: true,
    draggable: false,
    keyboardShortcuts: false,
    disableDoubleClickZoom: true,
  };
}
