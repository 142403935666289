import React, { Component } from "react";
import PropTypes from "prop-types";
import isEmail from "validator/lib/isEmail";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LOGIN_ERROR_MESSAGE =
  "Sorry, we don't recognise this email or password. Please check and try again.";

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.emailAddress || "",
      password: "",
      emailError: false,
      passwordError: false,
    };
  }

  handleInputChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  onSubmit = (event) => {
    const { email, password } = this.state;

    event.preventDefault();

    if (!isEmail(email)) {
      this.setState({ emailError: true });
    } else if (0 === password.length) {
      this.setState({ passwordError: true });
    } else {
      this.setState({
        emailError: false,
        passwordError: false,
      });
      this.props.onLogin(email, password);
    }
  };

  hideLoginError = () => {
    this.props.hideLoginError();
  };

  render() {
    const { email, password, emailError, passwordError } = this.state;
    const { showLoginError } = this.props;

    return (
      <Stack
        component="form"
        mt={2}
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={this.onSubmit}
      >
        <FormControl>
          <TextField
            value={email}
            id="email-input"
            label="Email"
            type="email"
            autoComplete="email"
            onChange={this.handleInputChange("email")}
            error={emailError}
          />
          {emailError && (
            <FormHelperText error={true}>
              Please check your email address.
            </FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <TextField
            value={password}
            id="password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            onChange={this.handleInputChange("password")}
            error={passwordError}
          />
          {passwordError && (
            <FormHelperText error={true}>
              Please enter your password.
            </FormHelperText>
          )}
        </FormControl>

        <Button
          id="log-in-button"
          variant="contained"
          color="primary"
          type="submit"
        >
          Log In
        </Button>

        <Snackbar
          id="error-snackbar"
          open={showLoginError}
          onClose={this.hideLoginError}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert onClose={this.hideLoginError} severity="info">
            {LOGIN_ERROR_MESSAGE}
          </Alert>
        </Snackbar>
      </Stack>
    );
  }
}

LoginForm.propTypes = {
  emailAddress: PropTypes.string,
  onLogin: PropTypes.func.isRequired,
  hideLoginError: PropTypes.func.isRequired,
  showLoginError: PropTypes.bool.isRequired,
};

export default LoginForm;
