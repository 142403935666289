import {
  MERCHANT_REQUEST,
  MERCHANT_SUCCESS,
  MERCHANT_FAILURE,
} from "../reducers/merchantConstants";
import { post } from "../api/post";

export function getMerchant(locationId) {
  return (dispatch) => {
    dispatch(merchantRequest({ locationId }));

    post("/merchant", { locationId })
      .then((response) => {
        dispatch(merchantSuccess(response));
      })
      .catch((err) => {
        console.error("Failed to refresh merchant details.", err);
        dispatch(merchantFailure());
      });
  };
}

export function merchantRequest(payload) {
  return { type: MERCHANT_REQUEST, payload };
}

export function merchantSuccess(payload) {
  return { type: MERCHANT_SUCCESS, payload };
}

export function merchantFailure(payload) {
  return { type: MERCHANT_FAILURE, payload };
}
