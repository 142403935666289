import React, { Component } from "react";
import PropTypes from "prop-types";
import isEmail from "validator/lib/isEmail";

import { StyledLoginLink } from "./StyledComponents";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";

class PasswordResetForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      emailError: false,
    };
  }

  handleInputChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  onSubmit = (event) => {
    const { email } = this.state;

    event.preventDefault();

    if (!isEmail(email)) {
      this.setState({ emailError: true });
    } else {
      this.setState({
        emailError: false,
      });
      this.props.onSendReset(email);
    }
  };

  render() {
    const { email, emailError } = this.state;

    return (
      <Stack
        component="form"
        mt={2}
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={this.onSubmit}
      >
        <FormControl>
          <TextField
            value={email}
            id="email-input"
            label="Email"
            type="email"
            autoComplete="email"
            onChange={this.handleInputChange("email")}
            error={emailError}
          />
          {emailError && (
            <FormHelperText error={true}>
              Please check your email address.
            </FormHelperText>
          )}
        </FormControl>

        <Button variant="contained" color="primary" type="submit" fullWidth>
          Send Password Reset Email
        </Button>

        <StyledLoginLink href="/login">Log In</StyledLoginLink>
      </Stack>
    );
  }
}

PasswordResetForm.propTypes = {
  onSendReset: PropTypes.func.isRequired,
};

export default PasswordResetForm;
