const post = (url, params) => {
  return new Promise((resolve, reject) => {
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(params),
    };

    fetch(`/api${url}`, options)
      .then((res) => resolve(res.json()))
      //.then((data) => resolve(data))
      .catch((err) => reject(err));
  });
};

export { post };
