import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router";
import throttle from "lodash/throttle";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import clsx from "clsx";
import {
  getLoginDetails,
  getMerchantDetails,
  getOrder,
  getCartDetails,
  getCartItemsCount,
  getCartTotal,
  isRequestingCheckout,
  checkoutRequestSucceeded,
  checkoutRequestFailed,
  checkoutRequestFailureErrorCode,
} from "../../reducers";
import {
  addItemToCart,
  removeItemFromCart,
  incrementCartItemQuantity,
  decrementCartItemQuantity,
  applyPromotionsToCart,
} from "../../actions/cartActions";
import { postCheckoutRequest } from "../../actions/checkoutActions";

import { getMerchantLocation } from "../../utils/merchant";
import { currentOrderIsValid } from "../../utils/currentOrderIsValid";
import { isCollectionOrder } from "../../utils/order";
import { getCurrentFulfilmentTime } from "../../utils/fulfilment";
import { usePrevious } from "../../utils/state";
import { getCdnImageUrl } from "../../utils/assetUtils";

import Header from "../Header/Header";
import MenuCategoryList from "./MenuCategoryList";
import MenuItemList from "./MenuItemList";
import MenuItemDialog from "./MenuItemDialog";
import LoginDialog from "./LoginDialog";
import Cart from "./Cart";
import Spinner from "../Spinner/Spinner";

import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Paper from "@mui/material/Paper";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";

import cssStyles from "./Menu.module.scss";

const HEADER_HEIGHT = 65;

const StyledAppBar = styled(AppBar)({
  top: "auto",
  bottom: 0,
});

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(3),
}));

function scrollBottom() {
  const windowHeight =
    "innerHeight" in window
      ? window.innerHeight
      : document.documentElement.offsetHeight;
  const body = document.body;
  const html = document.documentElement;
  const docHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  const windowBottom = Math.round(windowHeight + window.pageYOffset);
  return windowBottom >= docHeight;
}

function Menu(props) {
  const navigate = useNavigate();
  const { merchant } = props.getMerchantDetails;
  const order = props.getOrder;
  const cartItemsCount = props.getCartItemsCount;
  const collectionOrder = isCollectionOrder(order);
  const dispatch = props.dispatch;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const backgroundImageUrl = getCdnImageUrl(
    "web/background.webp",
    merchant.name
  );

  const [viewOnly] = useState(order.type === null);
  const [locationDetails] = useState(
    getMerchantLocation(merchant, order.locationId)
  );
  const [activeCat, setActiveCat] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [menuItemDialogOpen, setMenuItemDialogOpen] = useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [categorySelectorOpen, setCategorySelectorOpen] = useState(false);
  const [bottomNavAppBarHidden, setBottomNavAppBarHidden] = useState(true);
  const [shouldAnimateCartIcon, setShouldAnimateCartIcon] = useState(false);
  const [orderIsValid] = useState(currentOrderIsValid({ merchant, order }));

  const [menuCategoryScrollOffsets, setMenuCategoryScrollOffsets] = useState(
    new Map()
  );
  const fulfilmentTime = getCurrentFulfilmentTime(
    locationDetails,
    order.type,
    order.fulfilmentTime !== null
  );
  const fulfilmentTimeText = fulfilmentTime ? fulfilmentTime.text : null;

  useEffect(() => {
    dispatch(applyPromotionsToCart(merchant.promotions));
  }, [dispatch, merchant.promotions]);

  useEffect(() => {
    function handleScroll() {
      if (menuCategoryScrollOffsets.size > 0) {
        var activeCategoryKey = null;
        var firstCatKey = menuCategoryScrollOffsets.keys().next().value;

        menuCategoryScrollOffsets.forEach((value, key) => {
          if (
            menuCategoryScrollOffsets.get(key).offset <
            menuCategoryScrollOffsets.get(firstCatKey).offset
          ) {
            firstCatKey = key;
          }

          if (
            window.pageYOffset + 10 >
            menuCategoryScrollOffsets.get(key).offset - HEADER_HEIGHT
          ) {
            activeCategoryKey = key;
          }
        });

        if (null === activeCategoryKey) {
          activeCategoryKey = firstCatKey;
        }

        if (!activeCat || activeCat.key !== activeCategoryKey) {
          setActiveCat({
            key: activeCategoryKey,
            name: menuCategoryScrollOffsets.get(activeCategoryKey).name,
          });
        }
      }

      if (isMobile) {
        if (window.pageYOffset > 50) {
          if (scrollBottom()) {
            if (!bottomNavAppBarHidden) {
              setBottomNavAppBarHidden(true);
            }
          } else {
            if (bottomNavAppBarHidden) {
              setBottomNavAppBarHidden(false);
            }
          }
        } else {
          setBottomNavAppBarHidden(true);
        }
      }
    }

    const throttledHandleScroll = throttle(handleScroll, 500);
    window.addEventListener("scroll", throttledHandleScroll, false);
    return () => {
      window.removeEventListener("scroll", throttledHandleScroll, false);
    };
  }, [activeCat, isMobile, menuCategoryScrollOffsets, bottomNavAppBarHidden]);

  const wasRequestingCheckout = usePrevious(props.isRequestingCheckout);
  useEffect(() => {
    if (wasRequestingCheckout) {
      if (props.checkoutRequestSucceeded) {
        navigate("/information");
      } else if (props.checkoutRequestFailed) {
        console.error(
          "Start Checkout Failed. Error code: " +
            props.checkoutRequestFailureErrorCode
        );
      }
    }
  }, [
    props.isRequestingCheckout,
    props.checkoutRequestSucceeded,
    props.checkoutRequestFailed,
    props.checkoutRequestFailureErrorCode,
    navigate,
    wasRequestingCheckout,
  ]);

  function openCategorySelectionDrawer() {
    setCategorySelectorOpen(true);
  }

  function closeCategorySelectionDrawer() {
    setCategorySelectorOpen(false);
  }

  function setMenuCategoryOffset(key, name, offset) {
    if (!menuCategoryScrollOffsets.has(key)) {
      setMenuCategoryScrollOffsets((prevState) =>
        prevState.set(key, { name, offset })
      );
    }
  }

  function closeMenuItemDialog() {
    setMenuItemDialogOpen(false);
  }

  function closeLoginDialog() {
    setLoginDialogOpen(false);
  }

  function addItemToCartClicked(item, optionChoices, extrasChoices, qty) {
    const options = [];
    const extras = [];
    var itemName = item.name;
    var itemPrice =
      collectionOrder && item.price_collection
        ? Number(item.price_collection)
        : Number(item.price);

    for (const optionChoice of optionChoices) {
      options.push(optionChoice.id);
      itemName += ` ${optionChoice.name}`;
      itemPrice += Number(optionChoice.price);
    }

    for (const extraChoice of extrasChoices) {
      extras.push(extraChoice.id);
      itemName += ` +${extraChoice.name}`;
      itemPrice += Number(extraChoice.price);
    }

    dispatch(
      addItemToCart({
        qty,
        id: item.id,
        categoryId: item.menu_category_id,
        name: itemName,
        price: itemPrice,
        options: options.length === 0 ? null : options,
        extras: extras.length === 0 ? null : extras,
        excludedFromOffers: item.excluded_from_offers,
      })
    ).then(() => {
      closeMenuItemDialog();
      dispatch(applyPromotionsToCart(merchant.promotions));
      animateCartIcon();
    });
  }

  function incrementCartItemQtyClicked(id) {
    dispatch(incrementCartItemQuantity(id)).then(() => {
      dispatch(applyPromotionsToCart(merchant.promotions));
      animateCartIcon();
    });
  }

  function decrementCartItemQtyClicked(id) {
    dispatch(decrementCartItemQuantity(id)).then(() => {
      dispatch(applyPromotionsToCart(merchant.promotions));
      animateCartIcon();
    });
  }

  function removeItemFromCartClicked(id) {
    dispatch(removeItemFromCart(id)).then(() => {
      dispatch(applyPromotionsToCart(merchant.promotions));
      animateCartIcon();
    });
  }

  function startCheckoutClicked() {
    const user = props.getLoginDetails;

    if (user.isAuthenticated) {
      startCheckout();
    } else {
      setLoginDialogOpen(true);
    }
  }

  function startCheckout() {
    const cart = props.getCartDetails;
    const order = props.getOrder;
    const cartTotal = props.getCartTotal;

    dispatch(
      postCheckoutRequest({
        locationId: order.locationId,
        cart,
        cartTotal,
        order,
      })
    );
  }

  function animateCartIcon() {
    setShouldAnimateCartIcon(true);
  }

  function menuItemSelected(item) {
    setSelectedMenuItem(item);
    setMenuItemDialogOpen(true);
  }

  function cartIconAnimationComplete() {
    setShouldAnimateCartIcon(false);
  }

  if (!viewOnly && !orderIsValid) {
    return <Navigate to="/" />;
  }

  if (merchant) {
    const { isRequestingCheckout } = props;
    const cartTotal = props.getCartTotal;

    return (
      <div>
        <Spinner text="Starting Checkout" active={isRequestingCheckout} />

        <Header
          merchant={merchant}
          showCartIcon={isMobile && !viewOnly}
          showLoginIcon={!isMobile}
          loginCompletionUrl={window.location.pathname}
          animateCartIcon={shouldAnimateCartIcon}
          cartItemsCount={cartItemsCount}
          cartTotal={cartTotal}
          cartIconAnimationComplete={cartIconAnimationComplete}
        >
          <Cart
            fulfilmentTime={fulfilmentTimeText}
            incrementQty={incrementCartItemQtyClicked}
            decrementQty={decrementCartItemQtyClicked}
            removeItem={removeItemFromCartClicked}
            startCheckout={startCheckoutClicked}
          />
        </Header>

        <main
          style={
            isMobile
              ? { background: "none" }
              : { backgroundImage: `url("${backgroundImageUrl}")` }
          }
          className={cssStyles.main}
        >
          <div className={cssStyles.col1}>
            <Paper className={cssStyles.menuCategoryListContainer}>
              <MenuCategoryList
                menu={merchant.menu}
                activeCatKey={activeCat == null ? null : activeCat.key}
              />
            </Paper>
          </div>

          <div className={cssStyles.col2}>
            <Paper id="menu-item-list" className={cssStyles.menuItemsContainer}>
              <MenuItemList
                merchantName={merchant.name}
                collectionOrder={collectionOrder}
                menu={merchant.menu}
                setMenuCategoryOffset={setMenuCategoryOffset}
                itemSelected={menuItemSelected}
                isMobile={isMobile}
              />
            </Paper>
          </div>

          <div className={cssStyles.col3}>
            <Paper className={cssStyles.cartContainer}>
              {!viewOnly && (
                <Cart
                  fulfilmentTime={fulfilmentTimeText}
                  incrementQty={incrementCartItemQtyClicked}
                  decrementQty={decrementCartItemQtyClicked}
                  removeItem={removeItemFromCartClicked}
                  startCheckout={startCheckoutClicked}
                />
              )}
            </Paper>
          </div>

          <MenuItemDialog
            dialogOpen={menuItemDialogOpen}
            onClose={closeMenuItemDialog}
            item={selectedMenuItem}
            addToCartClicked={addItemToCartClicked}
            viewOnly={viewOnly}
            merchantName={merchant.name}
            collectionOrder={collectionOrder}
          />

          <LoginDialog
            dialogOpen={loginDialogOpen}
            startCheckout={startCheckout}
            onClose={closeLoginDialog}
          />

          {isMobile && (
            <div>
              <StyledAppBar
                position="fixed"
                color="primary"
                className={clsx({
                  [cssStyles.bottomNavAppBar]: true,
                  [cssStyles.show]: !bottomNavAppBarHidden,
                })}
              >
                <StyledToolbar variant="dense">
                  <Typography variant="h6" color="inherit">
                    {activeCat !== null && activeCat.name}
                  </Typography>
                  <IconButton
                    color="inherit"
                    aria-label="Menu Category"
                    onClick={openCategorySelectionDrawer}
                    size="large"
                  >
                    <MenuIcon />
                  </IconButton>
                </StyledToolbar>
              </StyledAppBar>

              <Drawer
                anchor="bottom"
                open={categorySelectorOpen}
                onClose={closeCategorySelectionDrawer}
              >
                <div className={cssStyles.bottomNavCategoryList}>
                  <MenuCategoryList
                    menu={merchant.menu}
                    onCategorySelection={closeCategorySelectionDrawer}
                  />
                </div>
              </Drawer>
            </div>
          )}
        </main>
      </div>
    );
  } else {
    return <p>Loading Menu</p>;
  }
}

function mapStateToProps(state) {
  return {
    getLoginDetails: getLoginDetails(state),
    getMerchantDetails: getMerchantDetails(state),
    getOrder: getOrder(state),
    getCartDetails: getCartDetails(state),
    getCartItemsCount: getCartItemsCount(state),
    getCartTotal: getCartTotal(state),
    isRequestingCheckout: isRequestingCheckout(state),
    checkoutRequestSucceeded: checkoutRequestSucceeded(state),
    checkoutRequestFailed: checkoutRequestFailed(state),
    checkoutRequestFailureErrorCode: checkoutRequestFailureErrorCode(state),
  };
}

Menu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getLoginDetails: PropTypes.object.isRequired,
  getMerchantDetails: PropTypes.object.isRequired,
  getOrder: PropTypes.object.isRequired,
  getCartDetails: PropTypes.object.isRequired,
  getCartItemsCount: PropTypes.number.isRequired,
  getCartTotal: PropTypes.string.isRequired,
  isRequestingCheckout: PropTypes.bool.isRequired,
  checkoutRequestSucceeded: PropTypes.bool.isRequired,
  checkoutRequestFailed: PropTypes.bool.isRequired,
  checkoutRequestFailureErrorCode: PropTypes.string,
};

export default connect(mapStateToProps)(Menu);
