import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes, { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { setCookie, getCookie } from "../../utils/cookies";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { postLoginRequest } from "../../actions/loginActions";
import { getLoginDetails, getMerchantDetails } from "../../reducers";
import { usePrevious } from "../../utils/state";
import { getCdnImageUrl } from "../../utils/assetUtils";

import Header from "../Header/Header";
import LoginForm from "./LoginForm";
import { StyledPaper } from "./StyledComponents";

import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import Box from "@mui/material/Box";

import cssStyles from "./Login.module.scss";

export const MAX_NAME_LENGTH = 8;
export const MAX_EMAIL_LENGTH = 100;
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 20;
export const PASSWORD_VALIDATION_ERROR = `Please use a password with between ${MIN_PASSWORD_LENGTH} and ${MAX_PASSWORD_LENGTH} characters.`;

const PREFIX = "Login";
const classes = {
  signupLink: `${PREFIX}-signupLink`,
  forgotPasswordLink: `${PREFIX}-forgotPasswordLink`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.signupLink}`]: {
    cursor: "pointer",
    fontSize: "0.875rem",
    color: theme.palette.primary.main,
    textDecoration: "underline",
    "&:hover": {
      background: "transparent",
      color: theme.palette.primary.main,
    },
    "&:visited": {
      color: theme.palette.primary.main,
    },
  },

  [`& .${classes.forgotPasswordLink}`]: {
    display: "block",
    fontWeight: 200,
    marginTop: theme.spacing(2),
    textDecoration: "underline",
    fontSize: "0.875rem",
    color: "inherit",
    "&:hover": {
      background: "transparent",
      color: "inherit",
    },
    "&:visited": {
      color: "inherit",
    },
  },
}));

const LOGIN_COOKIE_NAME = "login-email";

function Login(props) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { cookies } = props;

  const { merchant } = props.getMerchantDetails;
  const user = props.getLoginDetails;
  const emailAddress = user.email || getCookie(cookies, LOGIN_COOKIE_NAME);
  const backgroundImageUrl = getCdnImageUrl(
    "web/background.webp",
    merchant.name
  );

  const [showLoginError, shouldShowLoginError] = useState(false);

  useEffect(() => {
    if (user.isAuthenticated) {
      setCookie(cookies, LOGIN_COOKIE_NAME, user.email);
      navigate("/", { replace: true });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const previousLoginDetails = usePrevious(props.getLoginDetails);
  useEffect(() => {
    if (
      previousLoginDetails &&
      previousLoginDetails.isAuthenticating &&
      !user.isAuthenticating
    ) {
      if (user.isAuthenticated) {
        const nextUrl =
          location.state && location.state.completionUrl
            ? location.state.completionUrl
            : "/";
        navigate(nextUrl, { replace: true });
      } else {
        shouldShowLoginError(true);
      }
    }
  }, [previousLoginDetails, user, location, navigate]);

  function hideLoginError() {
    shouldShowLoginError(false);
  }

  function login(email, password) {
    hideLoginError();
    props.dispatch(postLoginRequest({ email: email, password: password }));
  }

  //console.log('Authenticating', user.isAuthenticating);
  //console.log('Authenticated', user.Authenticated);

  return (
    <Root>
      <Header showLoginIcon={false} merchant={merchant} />

      <main
        style={
          isMobile
            ? { background: "none" }
            : { backgroundImage: `url("${backgroundImageUrl}")` }
        }
        className={cssStyles.main}
      >
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Log in
          </Typography>

          <LoginForm
            emailAddress={emailAddress}
            onLogin={login}
            showLoginError={showLoginError}
            hideLoginError={hideLoginError}
          />

          <Box mt={2}>
            <Typography variant="body2">Don't have an account yet?</Typography>

            <Link
              className={classes.signupLink}
              to={{
                pathname: "/signup",
                state: {
                  completionUrl: "/",
                },
              }}
            >
              Create an account
            </Link>

            <MuiLink href="/password" className={classes.forgotPasswordLink}>
              Forgot your password?
            </MuiLink>
          </Box>
        </StyledPaper>
      </main>
    </Root>
  );
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,

  getMerchantDetails: PropTypes.object.isRequired,
  getLoginDetails: PropTypes.object.isRequired,
  cookies: instanceOf(Cookies).isRequired,
};

function mapStateToProps(state) {
  return {
    getLoginDetails: getLoginDetails(state),
    getMerchantDetails: getMerchantDetails(state),
  };
}

export default connect(mapStateToProps)(withCookies(Login));
