export function merchantHasMultipleLocations(merchant) {
  return merchant.locations.length > 1;
}

export function merchantHasTableBooking(merchant) {
  for (const location of merchant.locations) {
    if (location.has_book_a_table) {
      return true;
    }
  }
  return false;
}

export function getMerchantLocation(merchant, locationId) {
  var location = null;

  if (!merchantHasMultipleLocations(merchant)) {
    location = merchant.locations[0];
  } else if (locationId) {
    const locations = merchant.locations.filter(function (merchantLocation) {
      return merchantLocation.id === locationId;
    });

    if (locations.length === 1) {
      location = locations[0];
    }
  }

  return location;
}

export function getMerchantPhoneNumber(merchant, locationId) {
  const location = getMerchantLocation(merchant, locationId);
  return location.phone;
}

export function getMerchantMessageToCustomers(merchant, locationId) {
  const location = merchant ? getMerchantLocation(merchant, locationId) : null;

  return {
    enabled: location ? location.customer_message_enabled : false,
    title: location ? location.customer_message_title : null,
    body: location ? location.customer_message_content : null,
  };
}

export function orderEligibleForOnlinePayment(
  merchantPaymentOptions,
  collectionOrder
) {
  if (
    (collectionOrder &&
      merchantPaymentOptions.cash_only_for_collection_orders) ||
    merchantPaymentOptions.provider.name === "no_payment_provider"
  ) {
    return false;
  } else {
    return true;
  }
}

export function orderRequiresOnlinePayment(
  merchantPaymentOptions,
  collectionOrder
) {
  return (
    !merchantPaymentOptions.allow_cash_payments ||
    (!collectionOrder &&
      merchantPaymentOptions.upfront_payment_for_delivery_orders)
  );
}

export function getMerchantPaymentProvider(merchant) {
  return merchant.payment.provider;
}

// Accolades for Home page
export const ACCOLADE_HALAL = "Halal";
export const ACCOLADE_TRIP_ADVISOR_TOP_RATED = "Trip Advisor Top Rated";
export const ACCOLADE_FOOD_HYGIENE = "Food Hygiene Rating 5";

const accolades = [
  { name: ACCOLADE_HALAL, image: "accolade-halal.png" },
  { name: ACCOLADE_FOOD_HYGIENE, image: "accolade-food-hygiene.png" },
  {
    name: ACCOLADE_TRIP_ADVISOR_TOP_RATED,
    image: "accolade-trip-advisor-top-rated.png",
  },
];

export function getMerchantAccolades(accoladesToGet) {
  return accolades.filter((accolade) => accoladesToGet.includes(accolade.name));
}
