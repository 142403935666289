import orderConstants from "../reducers/orderConstants.mjs";
const { COLLECTION_ORDER, DELIVERY_ORDER } = orderConstants;

export function isCollectionOrder(order) {
  return order.type === COLLECTION_ORDER;
}

export function isDeliveryOrder(order) {
  return order.type === DELIVERY_ORDER;
}

export function isBeatTheQueueOrder(location, order) {
  return (
    order.type === COLLECTION_ORDER &&
    location.has_beat_the_queue &&
    order.fulfilmentTime == null
  );
}

const defaultExports = {
  isCollectionOrder,
  isDeliveryOrder,
};

export default defaultExports;
