const TAG_SPICY = "spicy";
const TAG_VERY_SPICY = "very spicy";
const TAG_EXTRA_SPICY = "extra spicy";
const TAG_VEGETARIAN = "vegetarian";
const TAG_VEGAN = "vegan";

const ALLERGEN_TAG_NAMES = {
  contains_nuts: "nuts",
  peanuts: "peanuts",
  eggs: "eggs",
  sesame: "sesame",
  contains_gluten: "gluten",
  gluten_free: "gluten free",
  wheat_free: "wheat free",
  dairy_free: "dairy free",
  crustacean: "crustacean",
  mustard: "mustard",
  fish: "fish",
  shellfish: "shellfish",
  celery: "celery",
  soya: "soya",
  lupins: "lupins",
  sulphite: "sulphite",
  milk: "milk",
};

const allergenTag = (style, text) => {
  return (
    <span className={style} key={text}>
      {text}
    </span>
  );
};

export const renderTags = (menuItem, cssStyles) => {
  var tags = [];

  if (menuItem.extra_spicy || menuItem.very_spicy || menuItem.spicy) {
    const spicy = menuItem.extra_spicy
      ? TAG_EXTRA_SPICY
      : menuItem.very_spicy
      ? TAG_VERY_SPICY
      : TAG_SPICY;
    tags.push(allergenTag(cssStyles.spicy, spicy));
  }

  if (menuItem.vegan || menuItem.vegetarian) {
    const veg = menuItem.vegan ? TAG_VEGAN : TAG_VEGETARIAN;
    tags.push(allergenTag(cssStyles.veg, veg));
  }

  for (const allergenTagName in ALLERGEN_TAG_NAMES) {
    if (menuItem[allergenTagName]) {
      tags.push(
        allergenTag(cssStyles.allergen, ALLERGEN_TAG_NAMES[allergenTagName])
      );
    }
  }

  if (tags.length > 0) {
    return <div className={cssStyles.tags}>{tags}</div>;
  }
};
