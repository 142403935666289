import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { postLoginRequest } from "../../actions/loginActions";
import { getLoginDetails } from "../../reducers";
import { usePrevious } from "../../utils/state";

import LoginForm from "../Login/LoginForm";

import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";

function LoginDialog(props) {
  const { dialogOpen, onClose, startCheckout } = props;
  const loginDetails = props.getLoginDetails;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [showLoginError, shouldShowLoginError] = useState(false);

  const dialogWasOpen = usePrevious(props.dialogOpen);
  const previousLoginDetails = usePrevious(props.getLoginDetails);
  useEffect(() => {
    if (dialogWasOpen) {
      if (
        previousLoginDetails.isAuthenticating &&
        !loginDetails.isAuthenticated &&
        !showLoginError
      ) {
        shouldShowLoginError(true);
      } else if (loginDetails.isAuthenticated) {
        startCheckout();
      }
    }
  }, [
    dialogWasOpen,
    previousLoginDetails,
    loginDetails,
    showLoginError,
    startCheckout,
  ]);

  function login(email, password) {
    shouldShowLoginError(false);
    props.dispatch(postLoginRequest({ email: email, password: password }));
  }

  function hideLoginError() {
    shouldShowLoginError(false);
  }

  return (
    <Dialog
      id="login-dialog"
      open={dialogOpen}
      onClose={onClose}
      scroll="body"
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <IconButton
          variant="close"
          aria-label="close"
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography variant="h6">New Customers</Typography>
        <Typography variant="body2" gutterBottom>
          You can create an account later if you like.
        </Typography>
        <Button
          id="guest-checkout-button"
          onClick={startCheckout}
          color="primary"
          variant="contained"
          fullWidth
        >
          Continue as Guest
        </Button>

        <Box component="div" mt={3}>
          <Typography variant="h6">Returning Customers</Typography>
          <Typography variant="body2">Log in for faster checkout.</Typography>

          <LoginForm
            onLogin={login}
            showLoginError={showLoginError}
            hideLoginError={hideLoginError}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    getLoginDetails: getLoginDetails(state),
  };
}

LoginDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getLoginDetails: PropTypes.object.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  startCheckout: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(LoginDialog);
