import * as Sentry from "@sentry/react";
import base64 from "base-64";
import utf8 from "utf8";

const oneYearInSeconds = 31536000;
const options = { sameSite: true, maxAge: oneYearInSeconds };

export const setCookie = (cookies, cookieName, cookieValue) => {
  try {
    const bytes = utf8.encode(cookieValue);
    cookies.set(cookieName, base64.encode(bytes), options);
  } catch (err) {
    Sentry.captureException(err, {
      extra: {
        cookieValue,
      },
    });
  }
};

export const getCookie = (cookies, cookieName) => {
  const cookie = cookies.get(cookieName);

  if (cookie) {
    const cookieValue = base64.decode(cookie);
    try {
      return utf8.decode(cookieValue);
    } catch {
      return cookieValue;
    }
  }
};
