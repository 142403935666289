import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_FAILURE,
  LOGOUT,
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_FAILURE,
  SET_LOGGED_IN_USER_PHONE,
  SET_LOGGED_IN_USER_MARKETING_OPT_IN,
  SET_LOGGED_IN_USER_DELIVERY_ADDRESS,
} from "./loginConstants";

const initialState = {
  token: null,
  refreshToken: null,
  userId: null,
  name: null,
  email: null,
  phone: null,
  marketingOptIn: false,
  deliveryAddress: null,
  //billingAddress: null,
  isAuthenticated: false,
  isAuthenticating: false,
  error: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case SIGN_UP_REQUEST:
    case NEW_PASSWORD_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
        isAuthenticated: false,
        error: false,
      };
    case VERIFY_TOKEN_REQUEST: // verify token doesn't change authenticated status
      return {
        ...state,
        isAuthenticating: true,
        error: false,
      };
    case LOGIN_SUCCESS:
    case VERIFY_TOKEN_SUCCESS:
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        error: false,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
        userId: action.payload.userId,
        name: action.payload.name,
        email: action.payload.email,
        phone: action.payload.phone,
        marketingOptIn: action.payload.marketingOptIn,
        deliveryAddress: action.payload.deliveryAddress,
        //billingAddress: action.payload.billingAddress,
      };
    case NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        error: false,
      };
    case LOGIN_FAILURE:
    case VERIFY_TOKEN_FAILURE:
    case SIGN_UP_FAILURE:
    case NEW_PASSWORD_FAILURE:
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        token: null,
        refreshToken: null,
        userId: null,
        name: null,
        email: null,
        phone: null,
        marketingOptIn: false,
        deliveryAddress: null,
        //billingAddress: null,
        error: action.payload.err?.message ? action.payload.err.message : true,
      };
    case LOGOUT:
      return initialState;
    case SET_LOGGED_IN_USER_PHONE:
      return {
        ...state,
        phone: action.phone,
      };
    case SET_LOGGED_IN_USER_MARKETING_OPT_IN:
      return {
        ...state,
        marketingOptIn: action.marketingOptIn,
      };
    case SET_LOGGED_IN_USER_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryAddress: action.deliveryAddress,
      };
    default:
      return state;
  }
};

export default login;

// SELECTORS
// ================================================
export const getLoginDetails = (state) => {
  return state.login;
};
/*
export const isAuthenticating = state => {
  return state.login.isAuthenticating;
};
*/
export const isAuthenticated = (state) => {
  return state.login.isAuthenticated;
};
