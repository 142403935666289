import * as Sentry from "@sentry/react";
import { post } from "../api/post";

import {
  INIT_CHECKOUT,
  START_CHECKOUT_REQUEST,
  START_CHECKOUT_SUCCESS,
  START_CHECKOUT_FAILURE,
  // GET_PAYMENT_TOKEN_STATUS_REQUEST,
  // GET_PAYMENT_TOKEN_STATUS_SUCCESS,
  // GET_PAYMENT_TOKEN_STATUS_FAILURE,
} from "../reducers/checkoutConstants";

export function initCheckout() {
  return (dispatch) => {
    dispatch({ type: INIT_CHECKOUT });
  };
}

export function postCheckoutRequest(payload) {
  return (dispatch) => {
    dispatch(startCheckoutRequest(payload));

    post("/checkout", payload)
      .then((response) => {
        //console.log("post /checkout result:", response);
        if (response.success) {
          dispatch(startCheckoutSuccess(response));
        } else {
          console.error("Checkout validation failed.");
          Sentry.captureException(new Error("Checkout validation failed."), {
            extra: payload,
          });
          dispatch(startCheckoutFailure(response));
        }
      })
      .catch((response) => {
        //console.log("post /checkout result:", response);
        console.error("Checkout validation failed.");
        Sentry.captureException(new Error("Checkout validation failed."), {
          extra: payload,
        });
        dispatch(startCheckoutFailure(response));
      });
  };
}

export function startCheckoutRequest(payload) {
  return { type: START_CHECKOUT_REQUEST, payload };
}

export function startCheckoutSuccess(payload) {
  //console.log(payload);
  return { type: START_CHECKOUT_SUCCESS, payload };
}

export function startCheckoutFailure(payload) {
  return { type: START_CHECKOUT_FAILURE, payload };
}

// We aren't using the Checkout reducer for order submission because
// are accepting wallet payments via the Payment Request API and
// the payment flow didn't really lend itself for integrating with
// our order submission states.
export const postSubmitOrderRequest = (payload) => {
  return new Promise((resolve, reject) => {
    post("/order", payload)
      .then((response) => {
        //console.log("post /order result:", response);

        if (response.success) {
          const { orderReference } = response;
          resolve(orderReference);
        } else {
          const { error } = response;
          reject(error);
        }
      })
      .catch((error) => {
        //console.error("post /order failed:", error);
        reject(error);
      });
  });
};

/*
export function getPaymentTokenStatus(payload) {
  return (dispatch) => {
    dispatch(getPaymentTokenStatusRequest(payload));

    post("/paymentTokenStatus", payload)
      .then((response) => {
        //console.log("post /paymentTokenStatus result:", response);

        if (response.success) {
          const { paymentTokenStatus } = response;
          dispatch(
            getPaymentTokenStatusSuccess({ status: paymentTokenStatus })
          );
        } else {
          const { error } = response;
          dispatch(getPaymentTokenStatusFailure({ error }));
        }
      })
      .catch(() => {
        dispatch(getPaymentTokenStatusFailure());
      });
  };
}

function getPaymentTokenStatusRequest(payload) {
  return { type: GET_PAYMENT_TOKEN_STATUS_REQUEST, payload };
}

function getPaymentTokenStatusSuccess(payload) {
  //console.log(payload);

  return { type: GET_PAYMENT_TOKEN_STATUS_SUCCESS, payload };
}

function getPaymentTokenStatusFailure(payload) {
  return { type: GET_PAYMENT_TOKEN_STATUS_FAILURE, payload };
}
*/
