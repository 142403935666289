export const CollectionIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 87.736 87.736"
      style={{ enableBackground: "new 0 0 87.736 87.736" }}
    >
      <g>
        <path
          d="M58.514,41.095l-8.387-0.149c-0.618-0.011-1.244-0.557-1.34-1.167l-2.3-14.621c-0.053-0.34-0.149-0.626-0.274-0.863
		c-0.426-1.098-1.487-1.885-2.728-1.885l-13.5,0.586c-1.629,0.071-2.954,1.455-2.954,3.086l0.01,29.591
		c-0.016,1.424-0.082,3.49-0.156,4.806l-0.407,7.213c-0.072,1.271-0.696,3.259-1.364,4.342l-5.928,9.623
		c-0.507,0.823-0.654,1.786-0.413,2.711c0.241,0.926,0.839,1.695,1.684,2.167l1.326,0.739c0.541,0.303,1.159,0.462,1.788,0.462
		c1.297,0,2.518-0.674,3.186-1.758l5.927-9.623c0.828-1.343,1.635-3.621,1.838-5.188l1.707-13.193l1.847-0.18l7.943,12.265
		c0.693,1.072,1.372,3.049,1.482,4.317L48.399,84.7c0.142,1.635,1.33,2.776,2.888,2.776c0.371,0,0.747-0.066,1.119-0.198l1.43-0.507
		c1.783-0.632,3.052-2.603,2.888-4.487l-0.898-10.322c-0.135-1.554-0.765-3.874-1.435-5.282l-7.629-16.041
		c-0.018-0.038-0.042-0.072-0.061-0.109l-0.032-2.852l12.184-0.502c0.809-0.033,1.546-0.383,2.077-0.981
		c0.527-0.596,0.783-1.363,0.72-2.161C61.524,42.442,60.118,41.124,58.514,41.095z"
        />
        <path
          d="M34.321,19.35c1.098,0,2.184-0.188,3.228-0.558c2.436-0.861,4.39-2.621,5.502-4.953c1.113-2.332,1.25-4.958,0.388-7.393
		C42.074,2.591,38.409,0,34.319,0c-1.098,0-2.183,0.187-3.227,0.556c-2.436,0.863-4.39,2.622-5.502,4.954
		c-1.113,2.332-1.25,4.958-0.388,7.392C26.567,16.759,30.232,19.35,34.321,19.35z"
        />
        <path
          d="M68.272,22.931c-0.114-1.634-1.544-2.891-3.198-2.791l-12.76,0.851c-0.797,0.053-1.527,0.414-2.055,1.019
		c-0.528,0.605-0.789,1.378-0.733,2.177l0.796,11.438c0.109,1.568,1.426,2.798,2.998,2.798c0.066,0,0.133-0.003,0.2-0.007
		l12.76-0.851c0.797-0.053,1.527-0.414,2.055-1.019c0.528-0.605,0.789-1.378,0.733-2.177L68.272,22.931z"
        />
      </g>
    </svg>
  );
};

export const DeliveryIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 581.4 581.4"
      style={{ enableBackground: "new 0 0 581.4 581.4" }}
    >
      <g>
        <g id="Layer_1_117_">
          <g>
            <path
              d="M289.425,376.125c-28.05,0-51,22.95-51,51V530.4c0,28.05,22.95,51,51,51s51-22.95,51-51V427.125
            C340.425,399.075,317.476,376.125,289.425,376.125z"
            />
            <path
              d="M474.3,63.75h-98.175C364.65,26.775,330.226,0,289.425,0s-75.225,26.775-86.7,63.75h-96.9
            c-14.025,0-25.5,11.475-25.5,25.5s11.475,25.5,25.5,25.5H204l0,0c6.375,21.675,20.4,39.525,39.525,51
            c-68.85,20.4-119.85,84.15-119.85,159.375V459c0,22.95,19.125,42.075,42.075,42.075h34.425V428.4
            c0-49.726,40.8-90.525,90.525-90.525c49.725,0,90.525,40.8,90.525,90.525v72.675h34.425c22.95,0,42.075-19.125,42.075-42.075
            V326.4c0-75.226-51-140.25-119.851-159.375c19.125-11.475,33.15-29.325,39.525-51l0,0h98.175c14.025,0,25.5-11.475,25.5-25.5
            C499.8,75.225,488.325,63.75,474.3,63.75z M289.425,141.525c-28.05,0-51-22.95-51-51c0-28.05,22.95-51,51-51s51,22.95,51,51
            C341.7,118.575,318.75,141.525,289.425,141.525z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
