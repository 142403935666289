//import session from './sessionReducer';
import login from './loginReducer';
import merchant from './merchantReducer';
import order from './orderReducer';
import postcode from './postcodeReducer';
import cart from './cartReducer';
import checkout from './checkoutReducer';

const reducers = {
  //session,
  login,
  merchant,
  order,
  postcode,
  cart,
  checkout,
};

export default reducers;

//export * from './sessionReducer';
export * from './loginReducer';
export * from './merchantReducer';
export * from './orderReducer';
export * from './postcodeReducer';
export * from './cartReducer';
export * from './checkoutReducer';
