import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCartItems } from "../../reducers";
import MenuItem from "./MenuItem";
import { Element } from "react-scroll";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";

import cssStyles from "./Menu.module.scss";

function MenuItemList(props) {
  const { menu, isMobile } = props;
  const cartItems = props.getCartItems;

  const getNumberOfItemsAlreadyInCart = (itemId) => {
    var result = 0;
    Object.keys(cartItems).forEach((key) => {
      if (cartItems[key].id === itemId) {
        result += cartItems[key].qty;
      }
    });

    return result;
  };

  const renderCategoryName = (categoryName, categoryIndex) => {
    return (
      <li
        key={categoryIndex}
        ref={(node) => {
          if (node) {
            const key = `cat-${categoryIndex}`;
            const rect = node.getBoundingClientRect();
            props.setMenuCategoryOffset(key, categoryName, rect.top);
          }
        }}
      >
        <Element name={`cat-${categoryIndex}`}>
          <Typography
            variant="h6"
            color="#FFF"
            sx={{
              padding: 2,
              paddingBottom: 1,
              letterSpacing: 2,
              fontWeight: 400,
              textTransform: "uppercase",
              fontSize: 15,
              backgroundColor: "primary.main",
              borderRadius: categoryIndex === 0 ? "4px 4px 0 0" : 0,
            }}
          >
            {categoryName}
          </Typography>
        </Element>
      </li>
    );
  };

  const renderCategoryItems = (categoryItems, categoryIndex) => {
    const { merchantName, collectionOrder } = props;
    return categoryItems.reduce((menuItems, item, itemIndex) => {
      if (item.enabled) {
        menuItems.push(
          <li key={`cat-${categoryIndex}-item-${itemIndex}`}>
            <ButtonBase
              key={itemIndex}
              sx={{ width: "100%", textAlign: "left" }}
              onClick={() => props.itemSelected(item)}
            >
              <MenuItem
                key={itemIndex}
                item={item}
                collectionOrder={collectionOrder}
                merchantName={merchantName}
                isMobile={isMobile}
                numberInCart={getNumberOfItemsAlreadyInCart(item.id)}
              />
            </ButtonBase>
          </li>
        );
      }

      return menuItems;
    }, []);
  };

  const renderCategory = (category, categoryIndex) => {
    return (
      <ul key={categoryIndex} className={cssStyles.menuItemList}>
        {renderCategoryName(category.name, categoryIndex)}
        {renderCategoryItems(category.menu_items, categoryIndex)}
      </ul>
    );
  };

  return menu.categories.reduce((output, category, categoryIndex) => {
    if (category.menu_items.length > 0) {
      output.push(renderCategory(category, categoryIndex));
    }

    return output;
  }, []);
}

function mapStateToProps(state) {
  return {
    getCartItems: getCartItems(state),
  };
}

MenuItemList.propTypes = {
  getCartItems: PropTypes.object.isRequired,
  merchantName: PropTypes.string.isRequired,
  collectionOrder: PropTypes.bool.isRequired,
  menu: PropTypes.object.isRequired,
  setMenuCategoryOffset: PropTypes.func.isRequired,
  itemSelected: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(MenuItemList);
