import React from "react";
import PropTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

function MyOrdersDialog(props) {
  const {
    dialogOpen,
    title,
    buttonId,
    buttonText,
    body,
    disableBackdropClick,
    fullScreen,
  } = props;

  function onClose(event, reason) {
    if (reason !== "backdropClick" || !disableBackdropClick) {
      props.onClose(event, reason);
    }
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      scroll="body"
      fullScreen={fullScreen}
    >
      <DialogContent>
        {title && (
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
        )}

        {body}
        <Box mt={2}>
          <Button
            id={buttonId ? buttonId : null}
            onClick={onClose}
            color="primary"
            variant="contained"
            fullWidth
          >
            {buttonText}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

MyOrdersDialog.propTypes = {
  title: PropTypes.string,
  body: PropTypes.element,
  buttonId: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  disableBackdropClick: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
};

MyOrdersDialog.defaultProps = {
  disableBackdropClick: false,
  fullScreen: false,
};

export default MyOrdersDialog;
