//import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMerchantDetails } from "../../reducers";
import * as Sentry from "@sentry/react";
import Home from "./../Home/Home";
import Hours from "./../Home/Hours";
import Contact from "./../Home/Contact";
//import Gallery from "./../Home/Gallery";
//import MenuPDF from "./../Home/Menu";
import Privacy from "./../Home/Privacy";
import TableBooking from "../TableBooking/TableBooking";

import Location from "./../Order/Location";
import Order from "./../Order/Order";
import Fulfilment from "./../Fulfilment/Fulfilment";
import Menu from "./../Menu/Menu";
import Information from "./../Checkout/Information";
import Delivery from "./../Checkout/Delivery";
import Payment from "./../Checkout/Payment";
import OrderComplete from "./../Checkout/OrderComplete";

import Login from "./../Login/Login";
import Signup from "./../Login/Signup";
import PasswordReset from "./../Login/PasswordReset";
import NewPassword from "./../Login/NewPassword";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function MerchantProtectedRoute({ merchant, children }) {
  if (!merchant) {
    return <Navigate to="/" />;
  }

  return children;
}

function TableBookingProtectedRoute({ merchant, children }) {
  const hasTableBooking =
    merchant &&
    merchant.locations.filter((location) => location.has_book_a_table).length >
      0;

  if (!hasTableBooking) {
    return <Navigate to="/" />;
  }

  return children;
}

function App(props) {
  const { merchant } = props.getMerchantDetails;

  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/hours"
          element={
            <MerchantProtectedRoute merchant={merchant}>
              <Hours />
            </MerchantProtectedRoute>
          }
        />
        <Route
          exact
          path="/contact"
          element={
            <MerchantProtectedRoute merchant={merchant}>
              <Contact />
            </MerchantProtectedRoute>
          }
        />
        <Route
          exact
          path="/privacy"
          element={
            <MerchantProtectedRoute merchant={merchant}>
              <Privacy />
            </MerchantProtectedRoute>
          }
        />
        <Route
          exact
          path="/location"
          element={
            <MerchantProtectedRoute merchant={merchant}>
              <Location />
            </MerchantProtectedRoute>
          }
        />
        <Route
          exact
          path="/order"
          element={
            <MerchantProtectedRoute merchant={merchant}>
              <Order />
            </MerchantProtectedRoute>
          }
        />
        <Route
          exact
          path="/fulfilment"
          element={
            <MerchantProtectedRoute merchant={merchant}>
              <Fulfilment />
            </MerchantProtectedRoute>
          }
        />
        <Route
          exact
          path="/menu"
          element={
            <MerchantProtectedRoute merchant={merchant}>
              <Menu />
            </MerchantProtectedRoute>
          }
        />
        <Route
          exact
          path="/information"
          element={
            <MerchantProtectedRoute merchant={merchant}>
              <Information />
            </MerchantProtectedRoute>
          }
        />
        <Route
          exact
          path="/delivery"
          element={
            <MerchantProtectedRoute merchant={merchant}>
              <Delivery />
            </MerchantProtectedRoute>
          }
        />
        <Route
          exact
          path="/payment"
          element={
            <MerchantProtectedRoute merchant={merchant}>
              <Payment />
            </MerchantProtectedRoute>
          }
        />
        <Route
          exact
          path="/complete"
          element={
            <MerchantProtectedRoute merchant={merchant}>
              <OrderComplete />
            </MerchantProtectedRoute>
          }
        />
        <Route
          exact
          path="/login"
          element={
            <MerchantProtectedRoute merchant={merchant}>
              <Login />
            </MerchantProtectedRoute>
          }
        />
        <Route
          exact
          path="/signup"
          element={
            <MerchantProtectedRoute merchant={merchant}>
              <Signup />
            </MerchantProtectedRoute>
          }
        />
        <Route
          exact
          path="/password"
          element={
            <MerchantProtectedRoute merchant={merchant}>
              <PasswordReset />
            </MerchantProtectedRoute>
          }
        />
        <Route
          exact
          path="/newPassword"
          element={
            <MerchantProtectedRoute merchant={merchant}>
              <NewPassword />
            </MerchantProtectedRoute>
          }
        />
        <Route
          exact
          path="/book"
          element={
            <TableBookingProtectedRoute merchant={merchant}>
              <TableBooking />
            </TableBookingProtectedRoute>
          }
        />
        <Route path="*" element={<Home />} />
      </SentryRoutes>
    </BrowserRouter>
  );
}

function mapStateToProps(state) {
  return {
    getMerchantDetails: getMerchantDetails(state),
  };
}

App.propTypes = {
  getMerchantDetails: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(App);
