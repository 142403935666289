import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import MuiLink from "@mui/material/Link";

export const StyledPaper = styled(Paper)(({ theme }) => ({
  maxWidth: 400,
  margin: "0 auto",
  padding: theme.spacing(2),
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    paddingTop: 0,
    borderRadius: 0,
    boxShadow: "none",
  },
}));

export const StyledLoginLink = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: "0.875rem",
  "&:hover": {
    color: theme.palette.primary.main,
  },
  "&:visited": {
    color: theme.palette.primary.main,
  },
}));
