import React, { useState } from "react";
import PropTypes, { instanceOf } from "prop-types";
import { Navigate } from "react-router";
import { useNavigate } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { getCookie, setCookie } from "../../utils/cookies";
import { connect } from "react-redux";
import {
  getLoginDetails,
  getOrder,
  getCartDetails,
  getCartDeliveryFee,
  getCartTotal,
  getMerchantDetails,
} from "../../reducers";
import { setLoggedInUserDeliveryAddress } from "../../actions/loginActions";
import { setOrderDeliveryAddress } from "../../actions/orderActions";
import { getCdnImageUrl } from "../../utils/assetUtils";
import { getMerchantLocation } from "../../utils/merchant";
import { normalizePostcode } from "../../utils/postcode";
import { currentOrderIsValid } from "../../utils/currentOrderIsValid";
import Header from "../Header/Header";
import DeliveryAddressForm from "./DeliveryAddressForm";
import OrderDetails from "./OrderDetails";

import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import {
  StyledBreadcrumbs,
  StyledBreadcrumbLink,
  StyledPaper,
  StyledFormContainer,
} from "./StyledComponents";

import cssStyles from "./Checkout.module.scss";

const PREFIX = "DeliveryAddress";

const classes = {
  container: `${PREFIX}-container`,
  breadcrumbs: `${PREFIX}-breadcrumbs`,
  contactInformation: `${PREFIX}-contactInformation`,
  continueButton: `${PREFIX}-continueButton`,
  fontWeightLight: `${PREFIX}-fontWeightLight`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.container}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.contactInformation}`]: {
    padding: 0,
    marginTop: theme.spacing(3),
  },

  [`& .${classes.fontWeightLight}`]: {
    fontWeight: "light",
  },
}));

export const DELIVERY_ADDRESS_COOKIE_NAME = "delivery-address";

function Delivery(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { cookies } = props;
  const { merchant } = props.getMerchantDetails;
  const order = props.getOrder;
  const locationDetails = getMerchantLocation(merchant, order.locationId);
  const user = props.getLoginDetails;
  const cartDetails = props.getCartDetails;
  const cartTotal = props.getCartTotal;
  const cartDeliveryFee = props.getCartDeliveryFee;

  var previousDeliveryAddress = null;

  if (order.deliveryAddress.line1) {
    //console.log("Prefill delivery address with address from order state.");
    previousDeliveryAddress = order.deliveryAddress;
  } else if (
    user.isAuthenticated &&
    user.deliveryAddress &&
    user.deliveryAddress.postcode &&
    normalizePostcode(user.deliveryAddress.postcode) ===
      normalizePostcode(order.deliveryAddress.postcode)
  ) {
    // console.log(
    //   "Prefill delivery address with address from user login details."
    // );
    previousDeliveryAddress = user.deliveryAddress;
  } else {
    const cookiedDeliveryAddressJson = getCookie(
      cookies,
      DELIVERY_ADDRESS_COOKIE_NAME
    );

    if (cookiedDeliveryAddressJson) {
      const cookiedDeliveryAddress = JSON.parse(cookiedDeliveryAddressJson);
      if (
        normalizePostcode(cookiedDeliveryAddress.postcode) ===
        normalizePostcode(order.deliveryAddress.postcode)
      ) {
        //console.log("Prefill delivery address with address from cookie.");
        previousDeliveryAddress = cookiedDeliveryAddress;
      }
    }
  }

  const backgroundImageUrl = getCdnImageUrl(
    "web/background.webp",
    merchant.name
  );

  const [validateDeliveryAddress, shouldValidateDeliveryAddress] =
    useState(false);
  const [orderIsValid] = useState(currentOrderIsValid({ merchant, order }));

  if (!orderIsValid) {
    return <Navigate to="/" />;
  }

  function setDeliveryAddress(deliveryAddress) {
    //console.log("Setting delivery address", deliveryAddress);

    shouldValidateDeliveryAddress(false);

    if (deliveryAddress) {
      setCookie(
        cookies,
        DELIVERY_ADDRESS_COOKIE_NAME,
        JSON.stringify({
          line1: deliveryAddress.line1,
          line2: deliveryAddress.line2,
          city: deliveryAddress.city,
          postcode: deliveryAddress.postcode,
        })
      );

      props
        .dispatch(setOrderDeliveryAddress(deliveryAddress))
        .then((isAuthenticated) => {
          if (isAuthenticated) {
            props.dispatch(setLoggedInUserDeliveryAddress(deliveryAddress));
          }
          navigate("/payment");
        });
    }
  }

  function renderBreadcrumbs() {
    return isMobile ? null : (
      <StyledBreadcrumbs
        separator="›"
        className={classes.breadcrumbs}
        aria-label="breadcrumb"
      >
        <StyledBreadcrumbLink color="inherit" href="/menu">
          Order
        </StyledBreadcrumbLink>
        <StyledBreadcrumbLink color="inherit" href="/information">
          Information
        </StyledBreadcrumbLink>
        <Typography color="textPrimary">Delivery Address</Typography>
      </StyledBreadcrumbs>
    );
  }

  function renderDeliveryAddress(postcode) {
    return (
      <>
        <Typography variant="h2" align="left" gutterBottom>
          Delivery Address
        </Typography>
        <StyledFormContainer>
          <DeliveryAddressForm
            postcode={postcode}
            locationDetails={locationDetails}
            submit={validateDeliveryAddress}
            setDeliveryAddress={setDeliveryAddress}
            previousDeliveryAddress={previousDeliveryAddress}
          />
        </StyledFormContainer>
      </>
    );
  }

  function submit() {
    shouldValidateDeliveryAddress(true);
  }

  return (
    <Root>
      <Header
        showCartIcon={false}
        merchant={merchant}
        loginCompletionUrl={window.location.pathname}
      />

      <main
        style={
          isMobile
            ? { background: "none" }
            : { backgroundImage: `url("${backgroundImageUrl}")` }
        }
        className={cssStyles.main}
      >
        {isMobile && (
          <StyledPaper square={true}>
            {renderBreadcrumbs()}
            {renderDeliveryAddress(order.deliveryAddress.postcode)}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className={classes.continueButton}
              onClick={submit}
            >
              Continue
            </Button>
          </StyledPaper>
        )}

        {!isMobile && (
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item xs={8}>
              <StyledPaper className={classes.container}>
                {renderBreadcrumbs()}
                {renderDeliveryAddress(order.deliveryAddress.postcode)}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.continueButton}
                  fullWidth
                  onClick={submit}
                >
                  Continue
                </Button>
              </StyledPaper>
            </Grid>

            <Grid item xs={4}>
              <OrderDetails
                order={order}
                items={cartDetails.items}
                discounts={cartDetails.discounts}
                orderCharges={cartDetails.orderCharges}
                cartTotal={cartTotal}
                deliveryFee={cartDeliveryFee}
              />
            </Grid>
          </Grid>
        )}
      </main>
    </Root>
  );
}

function mapStateToProps(state) {
  return {
    getLoginDetails: getLoginDetails(state),
    getOrder: getOrder(state),
    getCartDetails: getCartDetails(state),
    getCartDeliveryFee: getCartDeliveryFee(state),
    getCartTotal: getCartTotal(state),
    getMerchantDetails: getMerchantDetails(state),
  };
}

Delivery.propTypes = {
  getLoginDetails: PropTypes.object.isRequired,
  getOrder: PropTypes.object.isRequired,
  getCartDetails: PropTypes.object.isRequired,
  getCartDeliveryFee: PropTypes.number.isRequired,
  getCartTotal: PropTypes.string.isRequired,
  getMerchantDetails: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  cookies: instanceOf(Cookies).isRequired,
};

export default connect(mapStateToProps)(withCookies(Delivery));
