import Typography from "@mui/material/Typography";

export const getBookingConfirmationMessage = (numberOfGuests) => {
  return (
    <>
      <Typography variant="body" paragraph>
        Thanks for your request to book a table.
      </Typography>
      <Typography variant="body" paragraph>
        We'll be in touch to confirm your booking request as soon as possible.
      </Typography>
    </>
  );
};
