import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import { connect } from "react-redux";
import { getOrder, getMerchantDetails, getCartDetails } from "../../reducers";
import { setOrderFulfilmentTime } from "../../actions/orderActions";

import moment from "moment";
import { getMerchantLocation } from "../../utils/merchant";
import { startNewOrder } from "../../utils/newOrder";
import { getCdnImageUrl } from "../../utils/assetUtils";
import {
  merchantLocationIsOpen,
  nextOpenPeriod,
  nextClosingTime,
  getCurrentFulfilmentTime,
  locationCanAcceptOrdersNow,
} from "../../utils/fulfilment";
import { isCollectionOrder } from "../../utils/order";
import { currentOrderIsValid } from "../../utils/currentOrderIsValid";

import Header from "../Header/Header";
import SegmentedControl from "../SegmentedControl/SegmentedControl";

import "date-fns";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import cssStyles from "./Fulfilment.module.scss";

const createFulfilmentTimeOptions = (first, second) => {
  return [
    { index: 0, name: first },
    { index: 1, name: second },
  ];
};

function Fulfilment(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { merchant } = props.getMerchantDetails;
  const order = props.getOrder;
  const location = getMerchantLocation(merchant, order.locationId);

  const nextOpen = nextOpenPeriod(
    getMerchantLocation(merchant, order.locationId)
  );

  var orderIsValid = currentOrderIsValid({ merchant, order });
  var earliestFulfilment = null;
  var latestFulfilment = null;

  const backgroundImageUrl = getCdnImageUrl(
    "web/background.webp",
    merchant.name
  );

  const collectionOrder = isCollectionOrder(order);
  const timePickerText = collectionOrder ? "Collection Time" : "Delivery Time";

  const open = merchantLocationIsOpen(location);
  var fulfilmentTimeOptions = collectionOrder
    ? createFulfilmentTimeOptions("Collect ASAP", "Collect Later")
    : createFulfilmentTimeOptions("Deliver ASAP", "Deliver Later");

  const [selectedFulfilmentTimeOption, setSelectedFulfilmentTimeOption] =
    useState(0);
  const currentPrepTime = getCurrentFulfilmentTime(
    location,
    order.type,
    selectedFulfilmentTimeOption === fulfilmentTimeOptions[1].index
  );

  if (open) {
    //console.log("merchant is open");
    earliestFulfilment = moment().add(currentPrepTime.minutes, "minutes");
    latestFulfilment = moment(nextClosingTime(location), ["HH:mm"]).subtract(
      30,
      "minutes"
    );
  } else {
    //console.log("nextOpen.open", nextOpen.open.format("hh:mm A"));
    earliestFulfilment = moment(nextOpen.open).add(
      currentPrepTime.minutes,
      "minutes"
    );
    latestFulfilment = moment(nextOpen.close).subtract(30, "minutes");
    fulfilmentTimeOptions = collectionOrder
      ? createFulfilmentTimeOptions(
          earliestFulfilment.format("h:mm A"),
          "Collect Later"
        )
      : createFulfilmentTimeOptions(
          earliestFulfilment.format("h:mm A"),
          "Deliver Later"
        );
  }

  const [fulfilmentTime, setFulfilmentTime] = useState(
    earliestFulfilment === null ? null : earliestFulfilment.toDate()
  );
  const [pickerError, setPickerError] = useState(null);

  if (earliestFulfilment === null || latestFulfilment === null) {
    console.error("Invalid range for order fulfilment time selection.");
    Sentry.captureException(
      new Error("Invalid range for order fulfilment time selection."),
      { extra: location }
    );
    console.error(
      "Invalid range for order fulfilment time selection.",
      location
    );
    orderIsValid = false;
  }

  //console.log("earliestFulfilment", earliestFulfilment.format("hh:mm A"));
  //console.log("latestFulfilment", latestFulfilment);

  function orderForLater() {
    return selectedFulfilmentTimeOption === fulfilmentTimeOptions[1].index;
  }

  function fulfilmentTimeOptionChanged(index) {
    setSelectedFulfilmentTimeOption(index);
  }

  function fulfilmentTimeChosen(date) {
    const chosen = moment(date);

    //console.log("fulfilmentTimeChosen", date);
    //console.log("latestFulfilment", latestFulfilment);

    if (chosen.isBefore(earliestFulfilment)) {
      setPickerError(
        `Please pick a time after ${earliestFulfilment.format("h:mm A")}`
      );
    } else if (chosen.isAfter(latestFulfilment)) {
      setPickerError(
        `Please pick a time before ${latestFulfilment.format("h:mm A")}`
      );
    } else {
      setPickerError(null);
      setFulfilmentTime(date);
    }
  }

  function proceedToMenu() {
    props
      .dispatch(
        setOrderFulfilmentTime({
          fulfilmentTime: orderForLater() ? fulfilmentTime : null,
        })
      )
      .then(() => {
        const cart = props.getCartDetails;
        startNewOrder(
          props.dispatch,
          cart,
          order,
          location,
          merchant.promotions,
          merchant.fees
        ).then(() => navigate("/menu"));
      });
  }

  function renderPrompt() {
    if (open) {
      return (
        <Typography variant="body1">
          {`The current preparation time for ${
            collectionOrder ? "collection" : "delivery"
          } orders is ${currentPrepTime.text}`}
        </Typography>
      );
    } else {
      return (
        <Typography variant="body1">
          {`The earliest ${
            collectionOrder ? "collection" : "delivery"
          } time for orders is ${earliestFulfilment.format("h:mm A")}`}
        </Typography>
      );
    }
  }

  if (!locationCanAcceptOrdersNow(location) || !orderIsValid) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Header
        showCartIcon={false}
        merchant={merchant}
        loginCompletionUrl={window.location.pathname}
      />
      <main
        style={{ backgroundImage: `url("${backgroundImageUrl}")` }}
        className={cssStyles.main}
      >
        <Paper className={cssStyles.content} square={isMobile}>
          {renderPrompt()}

          <SegmentedControl
            segments={fulfilmentTimeOptions}
            selected={0}
            onChangeSegment={fulfilmentTimeOptionChanged}
          />

          {orderForLater() && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                margin="dense"
                id="time-picker"
                label={timePickerText}
                value={fulfilmentTime}
                onChange={fulfilmentTimeChosen}
                textField={(params) => {
                  params.inputProps.readOnly = true;
                  params.inputProps["data-testid"] = "time-picker";
                  return (
                    <FormControl>
                      <TextField {...params} />
                      {pickerError && (
                        <FormHelperText error={true}>
                          {pickerError}
                        </FormHelperText>
                      )}
                    </FormControl>
                  );
                }}
              />
            </LocalizationProvider>
          )}

          <Button
            id="continue-button"
            variant="contained"
            color="primary"
            fullWidth
            onClick={proceedToMenu}
            sx={{ mt: 2 }}
          >
            Continue
          </Button>
        </Paper>
      </main>
    </>
  );
}

function mapStateToProps(state) {
  return {
    getOrder: getOrder(state),
    getMerchantDetails: getMerchantDetails(state),
    getCartDetails: getCartDetails(state),
  };
}

Fulfilment.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getOrder: PropTypes.object.isRequired,
  getCartDetails: PropTypes.object.isRequired,
  getMerchantDetails: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Fulfilment);
