import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Welcome to Yummy Thai Kitchen's website.
      </Typography>
      <Typography variant="body1" paragraph>
        We are located at 136 Hermon Hill, E18 and serve all the surrounding
        areas of Wanstead, South Woodford, Snaresbrook, Woodford, Chigwell,
        Clayhall, Leytonstone, Upper Walthamstow, Redbridge and anywhere else
        within a 2 mile radius.
      </Typography>
      <Typography variant="body1" paragraph>
        We are a small independent family business, all our food is cooked fresh
        to order using the best ingredients. Our food is authentic Thai and if
        you want dishes extra spicy or mild let us know.
      </Typography>
      <Typography variant="body1" paragraph>
        The normal local delivery time is 30-45mins but at busy times when many
        orders come at once the delivery time maybe up to 2 hours. Please call
        and check our estimated delivery before you order. We apologise for the
        long waits our food is not fast food and some dishes takes longer to
        prepare than others.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
