import React, { Component } from "react";
import PropTypes from "prop-types";
import isEmpty from "validator/lib/isEmpty";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Switch from "../Switch/Switch";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import {
  MAX_TEXT_FIELD_INPUT_LENGTH,
  MAX_MULTILINE_TEXT_FIELD_INPUT_LENGTH,
} from "../../config";

const DEFAULT_GREETING = "Come and join us for a meal at our restaurant.";

const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const dateIsInThePast = (date) => {
  var now = new Date();
  now.setHours(0, 0, 0, 0);
  return date < now;
};

const timeIsInThePast = (date, timeHours, timeMinutes) => {
  var now = new Date();

  if (dateIsInThePast(date)) {
    return true;
  } else if (isToday(date)) {
    const dateTime = date.setHours(timeHours, timeMinutes, 0);
    return dateTime < now;
  } else {
    return false;
  }
};

class TableBookingForm extends Component {
  constructor(props) {
    super(props);

    const { prefill, locations } = this.props;
    const location = locations[0]; // TODO: remember last used location
    const tableBookingTimes = location.table_booking_times.split(",");
    this.greeting = location.custom_table_booking_message || DEFAULT_GREETING;

    this.todayDate = new Date();

    this.state = {
      location: location,
      selectedDate: this.todayDate,
      tableBookingTimes: tableBookingTimes,
      selectedTime: tableBookingTimes[0],
      maxGuests: location.max_table_size,
      selectedGuests: 2,
      name: prefill.name || "",
      email: prefill.email || "",
      phone: prefill.phone || "",
      message: "",
      dateError: false,
      timeError: false,
      nameError: false,
      emailError: false,
      phoneError: false,
      marketingOptIn: prefill.marketingOptIn || false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.submit && this.props.submit) {
      this.validateRequest();
    }
  }

  handleInputChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleSwitchChange = (name) => (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  validateRequest = () => {
    const {
      location,
      selectedDate,
      selectedTime,
      selectedGuests,
      name,
      email,
      phone,
      message,
      marketingOptIn,
    } = this.state;
    const selectedTimeComponents = selectedTime.split(":");

    if (dateIsInThePast(selectedDate)) {
      this.setState({
        dateError: true,
        timeError: false,
        nameError: false,
        emailError: false,
        phoneError: false,
      });
      this.props.sendBookingRequest(null);
    } else if (
      timeIsInThePast(
        selectedDate,
        selectedTimeComponents[0],
        selectedTimeComponents[1]
      )
    ) {
      this.setState({
        dateError: false,
        timeError: true,
        nameError: false,
        emailError: false,
        phoneError: false,
      });
      this.props.sendBookingRequest(null);
    } else if (isEmpty(name, { ignore_whitespace: true })) {
      this.setState({
        dateError: false,
        timeError: false,
        nameError: true,
        emailError: false,
        phoneError: false,
      });
      this.props.sendBookingRequest(null);
    } else if (!isEmail(email)) {
      this.setState({
        dateError: false,
        timeError: false,
        nameError: false,
        emailError: true,
        phoneError: false,
      });
      this.props.sendBookingRequest(null);
    } else if (!isMobilePhone(phone)) {
      this.setState({
        dateError: false,
        timeError: false,
        nameError: false,
        emailError: false,
        phoneError: true,
      });
      this.props.sendBookingRequest(null);
    } else {
      this.setState({
        dateError: false,
        timeError: false,
        nameError: false,
        emailError: false,
        phoneError: false,
      });
      this.props.sendBookingRequest(location, {
        selectedDate,
        selectedTime,
        selectedGuests,
        name,
        email,
        phone,
        message,
        marketingOptIn,
      });
    }
  };

  handleDatePickerChange = (selectedDate) => {
    this.setState({ selectedDate });
  };

  handleTimePickerChange = (event) => {
    this.setState({ selectedTime: event.target.value });
  };

  handleGuestsPickerChange = (event) => {
    this.setState({ selectedGuests: event.target.value });
  };

  handleLocationChange = (event) => {
    const selectedLocation = this.props.locations.find(
      (location) => location.id === event.target.value
    );
    const tableBookingTimes = selectedLocation.table_booking_times.split(",");

    this.setState({
      location: selectedLocation,
      tableBookingTimes: tableBookingTimes,
      selectedTime: tableBookingTimes[0],
      maxGuests: selectedLocation.max_table_size,
      selectedGuests: 2,
    });
  };

  renderLocationPicker = (locations) => {
    const { location } = this.state;

    if (locations.length > 1) {
      return (
        <FormControl fullWidth>
          <InputLabel id="location-label">Location</InputLabel>
          <Select
            labelId="location-label"
            value={location.id}
            renderValue={(value) => {
              const selectedLocation = locations.find(
                (location) => location.id === value
              );
              return selectedLocation.name;
            }}
            label="Location"
            onChange={this.handleLocationChange}
          >
            {locations.map((location) => (
              <MenuItem value={location.id} key={location.id}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    return null;
  };

  renderTimePicker = () => {
    const { tableBookingTimes, selectedTime, timeError } = this.state;

    return (
      <FormControl fullWidth>
        <InputLabel id="time-label">Time</InputLabel>
        <Select
          labelId="time-label"
          value={selectedTime}
          label="Time"
          error={timeError}
          onChange={this.handleTimePickerChange}
        >
          {tableBookingTimes.map((time, index) => (
            <MenuItem value={time} key={index}>
              {time}
            </MenuItem>
          ))}
        </Select>
        {timeError && (
          <FormHelperText error={true}>
            The time you've selected is in the past.
          </FormHelperText>
        )}
      </FormControl>
    );
  };

  renderGuestsPicker = () => {
    const { maxGuests, selectedGuests } = this.state;
    var menuItems = [];

    for (let i = 1; i <= maxGuests; i++) {
      menuItems.push(
        <MenuItem value={i} key={i}>
          {i}
        </MenuItem>
      );
    }

    return (
      <FormControl fullWidth>
        <InputLabel id="guests-label">Number of Guests</InputLabel>
        <Select
          labelId="guests-label"
          value={selectedGuests}
          label="Number of Guests"
          onChange={this.handleGuestsPickerChange}
        >
          {menuItems}
        </Select>
      </FormControl>
    );
  };

  render() {
    const { locations } = this.props;
    const {
      selectedDate,
      name,
      email,
      phone,
      message,
      dateError,
      nameError,
      emailError,
      phoneError,
      marketingOptIn,
    } = this.state;

    return (
      <form noValidate autoComplete="off">
        <Typography variant="body" align="center" paragraph>
          {this.greeting}
        </Typography>

        <Stack spacing={2}>
          {this.renderLocationPicker(locations)}

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="dd/MM/yyyy"
              value={selectedDate}
              minDate={this.todayDate}
              onChange={this.handleDatePickerChange}
              textField={(params) => (
                <TextField {...params} error={dateError} />
              )}
            />
          </LocalizationProvider>

          {this.renderTimePicker()}

          {this.renderGuestsPicker()}

          <FormControl>
            <TextField
              variant="outlined"
              value={name}
              fullWidth
              id="name-input"
              label="Contact Name"
              type="text"
              autoComplete="name"
              onChange={this.handleInputChange("name")}
              error={nameError}
              inputProps={{
                maxLength: MAX_TEXT_FIELD_INPUT_LENGTH,
              }}
            />
            {nameError && (
              <FormHelperText error={true}>
                Please enter your name.
              </FormHelperText>
            )}
          </FormControl>

          <FormControl>
            <TextField
              variant="outlined"
              value={email}
              fullWidth
              id="email-input"
              label="Contact Email"
              type="email"
              autoComplete="email"
              onChange={this.handleInputChange("email")}
              error={emailError}
              inputProps={{
                maxLength: MAX_TEXT_FIELD_INPUT_LENGTH,
              }}
            />
            {emailError && (
              <FormHelperText error={true}>
                Please check your email address.
              </FormHelperText>
            )}
          </FormControl>

          <FormControl>
            <TextField
              variant="outlined"
              value={phone}
              fullWidth
              id="phone-input"
              label="Contact Phone"
              type="tel"
              autoComplete="phone"
              onChange={this.handleInputChange("phone")}
              error={phoneError}
            />
            {phoneError && (
              <FormHelperText error={true}>
                Please enter your mobile phone number.
              </FormHelperText>
            )}
          </FormControl>
          <FormControl>
            <TextField
              variant="outlined"
              value={message}
              fullWidth
              id="message-input"
              label="Message for Restaurant"
              placeholder="(optional)"
              autoComplete="none"
              onChange={this.handleInputChange("message")}
              inputProps={{
                maxLength: MAX_MULTILINE_TEXT_FIELD_INPUT_LENGTH,
              }}
              multiline
              rows={2}
            />
          </FormControl>

          <FormControlLabel
            control={
              <Switch
                checked={marketingOptIn}
                onChange={this.handleSwitchChange()}
                name="marketingOptIn"
                sx={{ mx: 1 }}
              />
            }
            label="Send me exclusive offers by email"
          />
        </Stack>
      </form>
    );
  }
}

TableBookingForm.propTypes = {
  prefill: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  submit: PropTypes.bool.isRequired,
  sendBookingRequest: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default TableBookingForm;
