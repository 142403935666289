import {
  MERCHANT_REQUEST,
  MERCHANT_SUCCESS,
  MERCHANT_FAILURE,
} from "./merchantConstants";

const initialState = {
  merchantRequestInProgress: false,
  merchantRequestSuccess: false,
  merchantRequestFailed: false,
  merchant: null,
  locationId: null,
  timestamp: null,
};

const merchant = (state = initialState, action) => {
  switch (action.type) {
    case MERCHANT_REQUEST:
      return {
        ...state,
        merchantRequestInProgress: true,
        merchantRequestSuccess: false,
        locationId: action.payload.locationId,
      };
    case MERCHANT_SUCCESS:
      return {
        ...state,
        merchantRequestInProgress: false,
        merchantRequestFailed: false,
        merchantRequestSuccess: true,
        merchant: action.payload.merchant,
        timestamp: Date.now(),
      };
    case MERCHANT_FAILURE:
      return {
        ...state,
        merchantRequestInProgress: false,
        merchantRequestFailed: true,
        merchantRequestSuccess: false,
        locationId: null,
      };
    default:
      return state;
  }
};

export default merchant;

// SELECTORS
// ================================================
export const getMerchantDetails = (state) => {
  return state.merchant;
};

export const merchantRequestInProgress = (state) => {
  return state.merchant.merchantRequestInProgress;
};

export const merchantRequestSuccess = (state) => {
  return state.merchant.merchantRequestSuccess;
};

export const merchantRequestFailed = (state) => {
  return state.merchant.merchantRequestFailed;
};
