import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import clsx from "clsx";
import { getMerchant } from "../../actions/merchantActions";
import { getMerchantDetails } from "../../reducers";
import {
  merchantHasMultipleLocations,
  merchantHasTableBooking,
  getMerchantMessageToCustomers,
} from "../../utils/merchant";
import { getCdnImageUrl } from "../../utils/assetUtils";

import Header from "../Header/Header";
import CookieBanner from "../CookieBanner/CookieBanner";
import CustomerMessage from "../CustomerMessage/CustomerMessage";
import {
  getAboutText,
  getAdditionalAboutText,
  getGalleryMenuImages,
  getGalleryRestaurantImages,
  getAccolades,
} from "./About";

import { Swiper } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

import cssStyles from "./Home.module.scss";
import facebookIcon from "../../images/facebook-icon.svg";
import instagramIcon from "../../images/instagram-icon.svg";
import twitterIcon from "../../images/twitter-icon.svg";
import tripadvisorIcon from "../../images/tripadvisor-icon.png";

export const StyledGridMerchantBgColor = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const GALLERY_MODE_MENU = "GALLERY_MODE_MENU";
const GALLERY_MODE_RESTAURANT = "GALLERY_MODE_RESTAURANT";
const ONE_HOUR_IN_MS = 3600000;

function Home(props) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { merchant, timestamp: merchantDataTimestamp } =
    props.getMerchantDetails;
  const menuImages = getGalleryMenuImages();
  const hasMenuImages = menuImages.length > 0;
  const restaurantImages = getGalleryRestaurantImages();
  const hasRestaurantImages = restaurantImages.length > 0;
  const hasImageGallery = hasMenuImages || hasRestaurantImages;
  const accolades = getAccolades();
  const hasAccolades = accolades.length > 0;
  const customerMessage = getMerchantMessageToCustomers(merchant);
  const merchantDataIsCurrent =
    merchant &&
    merchantDataTimestamp &&
    Date.now() - merchantDataTimestamp < ONE_HOUR_IN_MS;

  const [galleryMode, setGalleryMode] = useState(GALLERY_MODE_MENU);
  const [showCustomerMessage, shouldShowCustomerMessage] = useState(false);

  useEffect(() => {
    localStorage.setItem("persist:root", {});
    props.dispatch(getMerchant(null));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (location.hash) {
      let element = document.getElementById(location.hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const orderOnlineClicked = () => {
    if (merchantHasMultipleLocations(merchant)) {
      navigate("/location");
    } else {
      if (customerMessage.enabled) {
        shouldShowCustomerMessage(true);
      } else {
        navigate("/order");
      }
    }
  };

  const customerMessageAcknowledged = () => {
    navigate("/order");
  };

  const bookTableClicked = () => {
    if (merchant.table_booking.table_booking_url) {
      window.open(merchant.table_booking.table_booking_url, '_blank').focus();
    }
    else {
      navigate("/book");
    }
  };

  const switchGalleryMode = (mode) => {
    if (mode !== galleryMode) {
      setGalleryMode(mode);
    }
  };

  const renderContactInfo = () => {
    return (
      <>
        <Typography variant="h1" color="#FFF">
          Contact Us
        </Typography>
        <Typography variant="body1" color="#FFF">
          {merchant.contact_info.email}
        </Typography>
      </>
    );
  };

  const renderSocialLinks = () => {
    return (
      <>
        <Typography variant="h1" color="#FFF">
          Follow and Review Us
        </Typography>
        <Box m={2} sx={{ display: "flex", justifyContent: "space-between" }}>
          {merchant.contact_info.facebook_url && (
            <a
              href={merchant.contact_info.facebook_url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                width="50"
                height="50"
                src={facebookIcon}
                className={cssStyles.socialIcon}
                alt="Facebook"
              />
            </a>
          )}
          {merchant.contact_info.instagram_url && (
            <a
              href={merchant.contact_info.instagram_url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                width="50"
                height="50"
                src={instagramIcon}
                className={cssStyles.socialIcon}
                alt="Instagram"
              />
            </a>
          )}
          {merchant.contact_info.twitter_url && (
            <a
              href={merchant.contact_info.twitter_url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                width="50"
                height="50"
                src={twitterIcon}
                className={cssStyles.socialIcon}
                alt="Twitter"
              />
            </a>
          )}
          {merchant.contact_info.tripadvisor_url && (
            <a
              href={merchant.contact_info.tripadvisor_url}
              target="_blank"
              rel="noreferrer"
            >
              <img
                width="50"
                height="50"
                src={tripadvisorIcon}
                className={cssStyles.socialIcon}
                alt="Tripadvisor"
              />
            </a>
          )}
        </Box>
      </>
    );
  };

  const renderAdditionalAboutText = () => {
    if (getAdditionalAboutText()) {
      const aboutImageUrl = getCdnImageUrl("web/about.webp", merchant.name);

      if (isMobile) {
        return (
          <Grid container spacing={0}>
            <Grid
              item
              sm={12}
              xs={12}
              sx={{
                height: isMobile ? "auto" : 450,
                padding: isMobile ? 3 : 4,
                paddingTop: 3,
                paddingBottom: 3,
              }}
            >
              <img
                src={aboutImageUrl}
                height="100"
                style={{
                  borderRadius: 8,
                  float: "left",
                  marginRight: 20,
                  marginBottom: 20,
                }}
                alt="about"
              />
              {getAdditionalAboutText()}
            </Grid>
          </Grid>
        );
      } else
        return (
          <Grid container spacing={0}>
            <Grid
              item
              sm={5}
              xs={12}
              sx={{
                height: 450,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={aboutImageUrl}
                height="200"
                style={{ borderRadius: 8 }}
                alt="about"
              />
            </Grid>

            <Grid
              item
              sm={7}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: 4,
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              {getAdditionalAboutText()}
            </Grid>
          </Grid>
        );
    } else {
      return null;
    }
  };

  const renderGallery = () => {
    const imageCount = menuImages.length + restaurantImages.length;
    const slidesPerView = isMobile ? 1 : imageCount > 2 ? 3 : imageCount;

    if (hasImageGallery) {
      return (
        <Container
          maxWidth={false}
          sx={{
            textAlign: "center",
            backgroundColor: "#00000066",
            padding: isMobile ? 0 : 4,
            paddingTop: 2,
            paddingBottom: 2,
            maxWidth: 1100,
          }}
        >
          <Typography
            variant="h4"
            color="#FFF"
            gutterBottom={!(hasMenuImages && hasRestaurantImages)}
          >
            Photos
          </Typography>
          {hasMenuImages && hasRestaurantImages && (
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="text"
                color={galleryMode === GALLERY_MODE_MENU ? "primary" : "white"}
                size="large"
                onClick={() => switchGalleryMode(GALLERY_MODE_MENU)}
              >
                Our Menu
              </Button>

              <Typography variant="h6" color="#FFF" mb={0.5} mt={0.75}>
                |
              </Typography>

              <Button
                variant="text"
                color={
                  galleryMode === GALLERY_MODE_RESTAURANT ? "primary" : "white"
                }
                size="large"
                onClick={() => switchGalleryMode(GALLERY_MODE_RESTAURANT)}
              >
                Our Restaurant
              </Button>
            </Box>
          )}
          <Swiper
            modules={[Navigation, Pagination]}
            slidesPerView={isMobile ? 1 : slidesPerView}
            spaceBetween={30}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            lazy="true"
            className={cssStyles.imageSwiper}
          >
            {galleryMode === GALLERY_MODE_MENU && menuImages}
            {galleryMode === GALLERY_MODE_RESTAURANT && restaurantImages}
          </Swiper>
        </Container>
      );
    } else {
      return null;
    }
  };

  const renderAccolades = () => {
    const accoladeImages = accolades.map((accolade, index) => {
      return (
        <img
          src={getCdnImageUrl(`${accolade.image}`)}
          alt={accolade.name}
          key={index}
          className={cssStyles.accoladeImage}
        />
      );
    });

    if (hasAccolades) {
      return (
        <Container
          maxWidth={false}
          sx={{
            textAlign: "center",
            backgroundColor: "#00000066",
            padding: isMobile ? 0 : 4,
            paddingTop: 2,
            paddingBottom: 1,
            maxWidth: 1100,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              padding: 1,
            }}
          >
            <Typography variant="h5" color="#FFF" gutterBottom>
              {`${merchant.name} are proud to be`}
            </Typography>
            <Box
              mt={2}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {accoladeImages}
            </Box>
          </Box>
        </Container>
      );
    } else {
      return null;
    }
  };

  if (merchant && merchantDataIsCurrent) {
    const logoImageUrl = getCdnImageUrl("web/logo.webp", merchant.name);
    const bannerImageUrl = getCdnImageUrl(
      "web/home-banner.webp",
      merchant.name
    );
    const galleryBackgroundImage = getCdnImageUrl(
      "web/gallery-background.webp",
      merchant.name
    );
    const hasTableBooking = merchantHasTableBooking(merchant);
    const hasSocialLinks =
      merchant.contact_info.facebook_url ||
      merchant.contact_info.instagram_url ||
      merchant.contact_info.twitter_url ||
      merchant.contact_info.tripadvisor_url;
    const hasContactEmail = merchant.contact_info.email;

    return (
      <>
        <Header
          showCartIcon={false}
          showNav={true}
          merchant={merchant}
          hasImageGallery={hasImageGallery}
          loginCompletionUrl="/"
        />

        <main>
          <div
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 46%, rgba(0,0,0,0.77) 71%, rgba(0,0,0,0.77) 100%), url("${bannerImageUrl}")`,
            }}
            className={cssStyles.banner}
          >
            <div
              style={{ backgroundImage: `url("${logoImageUrl}")` }}
              className={cssStyles.logo}
            ></div>
            <Typography
              variant="h1"
              align="center"
              color="#FFF"
              sx={{
                textTransform: "uppercase",
                letterSpacing: 0.11,
                marginTop: 4,
              }}
              className={cssStyles.name}
            >
              {merchant.name}
            </Typography>

            <Stack
              spacing={2}
              mt={2}
              mb={hasTableBooking ? 3 : 8}
              sx={{ display: "inline-flex" }}
            >
              <Button
                id="order-online-button"
                variant="contained"
                color="primary"
                onClick={orderOnlineClicked}
                size="large"
              >
                Order Online
              </Button>

              {hasTableBooking && (
                <Button
                  id="book-table-button"
                  variant="contained"
                  color="secondary"
                  onClick={bookTableClicked}
                  size="large"
                  sx={{}}
                >
                  Book a Table
                </Button>
              )}
            </Stack>

            {merchant.app_links.ios && merchant.app_links.android && (
              <div className={cssStyles.appLinks}>
                <p>Download our app</p>
                <a
                  href={merchant.app_links.ios}
                  className={clsx(cssStyles.appLink, cssStyles.appleAppStore)}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download in the App Store
                </a>
                <a
                  href={merchant.app_links.android}
                  className={clsx(cssStyles.appLink, cssStyles.googlePlayStore)}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download on Google Play
                </a>
              </div>
            )}
          </div>

          <StyledGridMerchantBgColor container spacing={0}>
            <Grid
              item
              sm={hasSocialLinks || hasContactEmail ? 6 : 12}
              xs={12}
              sx={{
                height: isMobile ? "auto" : 450,
                padding: isMobile ? 3 : 4,
                paddingTop: 3,
                paddingBottom: 3,
                fontWeight: 200,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderBottom: isMobile ? 4 : 0,
                borderColor: "#FFF",
              }}
            >
              {getAboutText()}
            </Grid>
            {(hasSocialLinks || hasContactEmail) && (
              <Grid
                item
                sm={6}
                xs={12}
                sx={{
                  padding: 4,
                  height: isMobile ? "auto" : 450,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {hasSocialLinks && renderSocialLinks()}

                {hasContactEmail && renderContactInfo()}
              </Grid>
            )}
          </StyledGridMerchantBgColor>

          {renderAdditionalAboutText()}

          {(hasImageGallery || hasAccolades) && (
            <Box
              id="gallery"
              pt={2}
              pb={2}
              sx={{
                background: `white url("${galleryBackgroundImage}") center/cover no-repeat`,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: hasImageGallery && hasAccolades ? 2 : 0,
                }}
              >
                {renderGallery()}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {renderAccolades()}
              </Box>
            </Box>
          )}
        </main>

        <footer className={cssStyles.footer}>
          <div className={cssStyles.copyright}>
            <span>&#169;2023 My Orders Ltd.</span>
          </div>
          <div className={cssStyles.copyright}>
            <Link color="inherit" underline="always" href="/privacy">
              Privacy Policy
            </Link>
          </div>
        </footer>

        <CookieBanner />
        <CustomerMessage
          visible={showCustomerMessage}
          message={customerMessage}
          onClose={customerMessageAcknowledged}
        />
      </>
    );
  } else {
    return (
      <Box m={2}>
        <Typography variant="body" paragraph>
          Loading...
        </Typography>
      </Box>
    );
  }
}

/*
<Paper
  className={cssStyles.content}
  style={{ width: 170, padding: 10 }}
>
  {merchant && (
    <p>We got merchant details</p>
  )}

  <p>{`Order type: ${props.getOrder.type}`}</p>

  <p>
    {`Authenticating: ${user.isAuthenticating} Authenticated: ${user.isAuthenticated}`}
  </p>
</Paper>
*/

function mapStateToProps(state) {
  return {
    getMerchantDetails: getMerchantDetails(state),
  };
}

Home.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getMerchantDetails: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Home);
