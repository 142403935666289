import React from "react";
import PropTypes from "prop-types";

import cssStyles from "./MapMarker.module.scss";

const MapMarker = (props) => {
  return (
    <div
      style={{
        backgroundImage: `url("${props.imageUrl}")`,
        width: props.width || 50,
        height: props.height || 50,
      }}
      className={cssStyles.marker}
    />
  );
};

MapMarker.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default MapMarker;
