import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import { CURRENCY_CODE } from "../../utils/currency";
import PaymentRequestForm from "./PaymentRequestForm";

import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import cssStyles from "./PaymentForm.module.scss";
import merchant from "../../reducers/merchantReducer";
import { PAYMENT_TYPE_CARD } from "./paymentConstants";

const PREFIX = "PaymentForm";

const classes = {
  cardForm: `${PREFIX}-cardForm`,
  validationErrorMessage: `${PREFIX}-validationErrorMessage`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.cardForm}`]: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: 4,
    margin: theme.spacing(2, 0),
  },

  [`& .${classes.validationErrorMessage}`]: {
    color: "red",
  },
}));

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "16px",
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
    },
  },
};

const formatPhoneNumberWithCountryCode = (phoneNumber) => {
  return phoneNumber.charAt(0) === "0"
    ? "+44" + phoneNumber.substring(1)
    : phoneNumber;
};

function PaymentForm(props) {
  const {
    paymentButtonEnabled,
    startCardPayment,
    togglePaymentButtonEnabled,
    submitPlaceholderOrder,
    orderSubmissionFailed,
    paymentSucceeded,
    cardPaymentFailed,
    paymentToken,
    //billingAddress,
    customer,
    cartTotal,
  } = props;

  const stripe = useStripe();
  const elements = useElements();

  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState("");
  const [
    showWalletPaymentFailureMessage,
    shouldShowWalletPaymentFailureMessage,
  ] = useState(false);

  useEffect(() => {
    const submitCardPayment = () => {
      if (!stripe || !elements) {
        return;
      }

      setPaymentInProgress(true);
      submitPlaceholderOrder(PAYMENT_TYPE_CARD)
        .then(async (orderReference) => {
          stripe
            .confirmCardPayment(paymentToken, {
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  //name: `${billingAddress.firstName} ${billingAddress.lastName}`,
                  email: customer.email,
                  phone: formatPhoneNumberWithCountryCode(customer.phone),
                  //address: {
                  //  line1: billingAddress.addressLine1,
                  //  city: billingAddress.addressCity,
                  //  postal_code: billingAddress.addressPostcode,
                  //  country: "GB",
                  //},
                },
              },
            })
            .then((result) => {
              if (result.error) {
                cardPaymentFailed();
                setValidationErrorMessage(result.error.message);

                // console.log("Payment failed");
                // console.log(result);
                if (result.error.payment_intent) {
                  console.error(result.error);
                }
              } else {
                if (
                  result.paymentIntent &&
                  result.paymentIntent.status === "requires_capture"
                ) {
                  paymentSucceeded(orderReference);
                } else {
                  console.error("Stripe confirmCardPayment() failed.", result);
                  // console.log(
                  //   `Stripe payment intent status: ${result.paymentIntent.status}`
                  // );
                }
                setValidationErrorMessage("");
                cardPaymentFailed();
              }

              setPaymentInProgress(false);
            })
            .catch((error) => {
              console.error(error);
              setPaymentInProgress(false);
            });
        })
        .catch((error) => orderSubmissionFailed(error));
    };

    if (startCardPayment && !paymentInProgress) {
      submitCardPayment();
    }
  }, [
    stripe,
    elements,
    paymentToken,
    customer,
    startCardPayment,
    submitPlaceholderOrder,
    orderSubmissionFailed,
    paymentInProgress,
    cardPaymentFailed,
    paymentSucceeded,
  ]);

  function walletPaymentFailed(failed) {
    shouldShowWalletPaymentFailureMessage(failed);
  }

  function cardElementInputChanged(event) {
    if (event.brand === "amex") {
      setValidationErrorMessage(
        "Sorry, but we don't currently accept American Express cards."
      );
      togglePaymentButtonEnabled(false);
    } else {
      setValidationErrorMessage("");
      if (!paymentButtonEnabled) {
        togglePaymentButtonEnabled(true);
      }
    }
  }

  return (
    <Root className={cssStyles.stripePaymentForm}>
      <PaymentRequestForm
        currency={CURRENCY_CODE.toLowerCase()}
        label={merchant.name}
        amount={Math.round(Number(cartTotal) * 100)}
        paymentToken={paymentToken}
        paymentFailed={walletPaymentFailed}
        paymentSucceeded={paymentSucceeded}
        submitPlaceholderOrder={submitPlaceholderOrder}
        orderSubmissionFailed={orderSubmissionFailed}
      />

      <Typography
        variant="caption"
        align="left"
        hidden={!showWalletPaymentFailureMessage}
        className={classes.validationErrorMessage}
      >
        Sorry, something went wrong. Please try again.
      </Typography>

      <Typography variant="h2" align="left" gutterBottom sx={{ marginTop: 2 }}>
        Credit Card
      </Typography>
      <Typography
        variant="body1"
        align="left"
        gutterBottom
        className={cssStyles.promptText}
      >
        All transactions are secure and encrypted
      </Typography>

      <CardElement
        id="stripe-elements"
        className={cssStyles.cardElement}
        onChange={cardElementInputChanged}
        options={CARD_ELEMENT_OPTIONS}
      />

      <Typography
        variant="caption"
        align="left"
        className={classes.validationErrorMessage}
      >
        {validationErrorMessage}
      </Typography>
    </Root>
  );
}

PaymentForm.propTypes = {
  paymentToken: PropTypes.string.isRequired,
  billingAddress: PropTypes.object,
  customer: PropTypes.object.isRequired,
  cartTotal: PropTypes.string.isRequired,
  startCardPayment: PropTypes.bool.isRequired,
  togglePaymentButtonEnabled: PropTypes.func.isRequired,
  cardPaymentFailed: PropTypes.func.isRequired,
  submitPlaceholderOrder: PropTypes.func.isRequired,
  orderSubmissionFailed: PropTypes.func.isRequired,
  paymentSucceeded: PropTypes.func.isRequired,
};

export default PaymentForm;
