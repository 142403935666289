const IMAGE_CDN_URL = "https://my-orders.ams3.digitaloceanspaces.com";

export function getCdnImageUrl(image, merchantName) {
  if (merchantName) {
    const merchantDir = merchantName.toLowerCase().replace(/'| |&/g, "_");
    return `${IMAGE_CDN_URL}/${merchantDir}/${image}`;
  } else {
    return `${IMAGE_CDN_URL}/web/${image}`;
  }
}

export function getCdnMenuImageUrl(image, merchantName) {
  const merchantDir = merchantName.toLowerCase().replace(/'| |&/g, "_");
  return `${IMAGE_CDN_URL}/${merchantDir}/menu/${image}`;
}
