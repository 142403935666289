import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { formatMoney } from "../../utils/currency.mjs";

import cartBlack from "../../images/cart-black.svg";
import cartWhite from "../../images/cart-white.svg";

import cssStyles from "./CartIcon.module.scss";

function CartIcon(props) {
  const { items, amount, animate } = props;
  const cartIsEmpty = 0 === items;

  function animationComplete() {
    if (props.animationComplete) {
      props.animationComplete();
    }
  }

  return (
    <div
      className={clsx({
        [cssStyles.cartContainer]: true,
        [cssStyles.empty]: cartIsEmpty,
        [cssStyles.pulse]: animate,
      })}
      onAnimationEnd={animationComplete}
    >
      {cartIsEmpty && <img src={cartBlack} alt="cart" />}
      {!cartIsEmpty && <img src={cartWhite} alt="cart" />}
      <div className={cssStyles.itemsText}>{items}</div>
      <div className={cssStyles.amountText}>{formatMoney(amount)}</div>
    </div>
  );
}

CartIcon.propTypes = {
  items: PropTypes.number,
  amount: PropTypes.string,
  animate: PropTypes.bool,
  animationComplete: PropTypes.func,
};

export default CartIcon;
