import moment from "moment";
import { COLLECTION_ORDER, DELIVERY_ORDER } from "../reducers/orderConstants";

const TIME_FORMAT = "HH:mm";

const isLunchtime = (tradingDay, now) => {
  var isLunchtime = false;

  if (tradingDay.open_for_lunch) {
    const lunchOpening = moment(tradingDay.lunch_opening_time, TIME_FORMAT);
    const lunchClosing = moment(tradingDay.lunch_closing_time, TIME_FORMAT);
    // console.log('lunchOpening', lunchOpening);
    // console.log('lunchClosing', lunchClosing);
    isLunchtime = now.isSameOrAfter(lunchOpening) && now.isBefore(lunchClosing);
  }

  return isLunchtime;
};

const isDinnertime = (tradingDay, now) => {
  var isDinnertime = false;

  if (tradingDay.open_for_dinner) {
    const dinnerOpening = moment(tradingDay.dinner_opening_time, TIME_FORMAT);
    const dinnerClosing = moment(tradingDay.dinner_closing_time, TIME_FORMAT);
    // console.log('dinnerOpening', dinnerOpening);
    // console.log('dinnerClosing', dinnerClosing);
    isDinnertime =
      now.isSameOrAfter(dinnerOpening) && now.isBefore(dinnerClosing);
  }

  return isDinnertime;
};

const getCurrentTradingDay = (location, now) => {
  const today = now.day();
  const currentTradingDay = location.trading_days.filter(function (tradingDay) {
    return today === tradingDay.day;
  });

  return currentTradingDay[0];
};

export function merchantLocationIsOpen(location) {
  var isOpen = !location.closed;

  if (isOpen) {
    const now = moment();
    const tradingDay = getCurrentTradingDay(location, now);
    isOpen = isLunchtime(tradingDay, now) || isDinnertime(tradingDay, now);
  }

  return isOpen;
}

export function merchantLocationShouldBeOpen(location) {
  const now = moment();
  const tradingDay = getCurrentTradingDay(location, now);
  return isLunchtime(tradingDay, now) || isDinnertime(tradingDay, now);
}

export function nextOpenPeriod(location) {
  const now = moment();
  const today = getCurrentTradingDay(location, now);
  const lunchOpening = moment(today.lunch_opening_time, TIME_FORMAT);
  const dinnerOpening = moment(today.dinner_opening_time, TIME_FORMAT);
  var result = null;

  if (today.open_for_lunch && now.isBefore(lunchOpening)) {
    const open = moment(today.lunch_opening_time, TIME_FORMAT);
    const close = moment(today.lunch_closing_time, TIME_FORMAT);
    result = { open, close };
  } else if (today.open_for_dinner && now.isBefore(dinnerOpening)) {
    const open = moment(today.dinner_opening_time, TIME_FORMAT);
    const close = moment(today.dinner_closing_time, TIME_FORMAT);
    result = { open, close };
  }

  return result;
}

export function nextClosingTime(location) {
  const now = moment();
  const tradingDay = getCurrentTradingDay(location, now);
  var result = null;

  if (isLunchtime(tradingDay, now)) {
    result = moment(tradingDay.lunch_closing_time, TIME_FORMAT);
  } else if (isDinnertime(tradingDay, now)) {
    result = moment(tradingDay.dinner_closing_time, TIME_FORMAT);
  }

  return result;
}

const nextOpenPeriodIsLunchtime = (tradingDay, now) => {
  const lunchOpening = moment(tradingDay.lunch_opening_time, TIME_FORMAT);
  return tradingDay.open_for_lunch && now.isBefore(lunchOpening);
};

const nextOpenPeriodIsDinnertime = (tradingDay, now) => {
  const dinnerOpening = moment(tradingDay.dinner_opening_time, TIME_FORMAT);
  return tradingDay.open_for_dinner && now.isBefore(dinnerOpening);
};

export function allowsOrdersWhenClosed(location) {
  return location.has_order_ahead && location.allows_orders_when_closed;
}

export function allowsOrderAhead(location) {
  return location.has_order_ahead;
}

export function adequateTimeForOrderAhead(location, minTimeMins, orderType) {
  if (merchantLocationIsOpen(location)) {
    const closingTime = nextClosingTime(location);
    const timeUntilClose = moment.duration(closingTime.diff(moment()));
    const currentFulfilmentTime = getCurrentFulfilmentTime(
      location,
      orderType,
      true
    );

    return (
      timeUntilClose.asMinutes() >= minTimeMins &&
      timeUntilClose.asMinutes() >= currentFulfilmentTime.minutes
    );
  } else {
    return true;
  }
}

function createFulfilmentTimeComponents(fulfilmentTime) {
  const components = fulfilmentTime.split(":");
  const hours = parseInt(components[0]);
  const minutes = parseInt(components[1]);

  if (hours === 0) {
    return {
      text: `${minutes} minutes`,
      minutes: minutes,
    };
  } else {
    const hoursText = hours > 1 ? "hours" : "hour";
    const timeText =
      minutes > 0
        ? `${hours} ${hoursText} ${minutes} minutes`
        : `${hours} ${hoursText}`;
    return {
      text: timeText,
      minutes: hours * 60 + minutes,
    };
  }
}

function createBeatTheQueueTimeComponents(beatTheQueueTime) {
  const components = beatTheQueueTime.split("-");
  const upperRangeMinutes = parseInt(components[1]);

  return {
    text: `${beatTheQueueTime} minutes`,
    minutes: upperRangeMinutes,
  };
}

export function getCurrentFulfilmentTime(location, orderType, orderForLater) {
  const now = moment();
  const currentTradingDay = getCurrentTradingDay(location, now);

  if (orderType === COLLECTION_ORDER) {
    if (location.has_beat_the_queue && !orderForLater) {
      return createBeatTheQueueTimeComponents(
        currentTradingDay.beat_the_queue_fulfilment_time
      );
    } else if (currentTradingDay.collection_fulfilment_time) {
      return createFulfilmentTimeComponents(
        currentTradingDay.collection_fulfilment_time
      );
    }
  } else if (orderType === DELIVERY_ORDER) {
    if (currentTradingDay.delivery_fulfilment_time) {
      return createFulfilmentTimeComponents(
        currentTradingDay.delivery_fulfilment_time
      );
    }
  }
}

export function getCurrentBeatTheQueueTime(location) {
  const now = moment();
  const currentTradingDay = getCurrentTradingDay(location, now);

  return currentTradingDay.beat_the_queue_fulfilment_time;
}

export function locationCanAcceptOrdersNow(location) {
  return (
    merchantLocationIsOpen(location) ||
    (allowsOrdersWhenClosed(location) && nextOpenPeriod(location))
  );
}

export function locationCanDeliver(location) {
  return location.can_deliver;
}

export function locationCanDeliverToday(location) {
  const now = moment();
  const tradingDay = getCurrentTradingDay(location, now);
  return tradingDay.delivery;
}

export function locationCanAcceptDeliveryOrdersNow(location) {
  const now = moment();
  const tradingDay = getCurrentTradingDay(location, now);
  var result = false;

  if (locationCanAcceptOrdersNow(location) && tradingDay.delivery) {
    if (
      (isLunchtime(tradingDay, now) && tradingDay.lunch_delivery) ||
      (isDinnertime(tradingDay, now) && tradingDay.dinner_delivery)
    ) {
      result = true;
    } else if (allowsOrdersWhenClosed(location)) {
      if (
        (nextOpenPeriodIsLunchtime(tradingDay, now) &&
          tradingDay.lunch_delivery) ||
        (nextOpenPeriodIsDinnertime(tradingDay, now) &&
          tradingDay.dinner_delivery)
      ) {
        result = true;
      }
    }
  }
  return result;
}

export function locationAllowsCollection(location) {
  return location.allows_collection;
}

export function locationAllowsCollectionToday(location) {
  const now = moment();
  const tradingDay = getCurrentTradingDay(location, now);
  return tradingDay.collection;
}

export function locationCanAcceptCollectionOrdersNow(location) {
  const now = moment();
  const tradingDay = getCurrentTradingDay(location, now);
  var result = false;

  if (locationCanAcceptOrdersNow(location) && tradingDay.collection) {
    if (
      (isLunchtime(tradingDay, now) && tradingDay.lunch_collection) ||
      (isDinnertime(tradingDay, now) && tradingDay.dinner_collection)
    ) {
      result = true;
    } else if (allowsOrdersWhenClosed(location)) {
      if (
        (nextOpenPeriodIsLunchtime(tradingDay, now) &&
          tradingDay.lunch_collection) ||
        (nextOpenPeriodIsDinnertime(tradingDay, now) &&
          tradingDay.dinner_collection)
      ) {
        result = true;
      }
    }
  }

  return result;
}

/*
export function merchantHasOpenLocations(merchant) {
  var result = false;
  for (const location of merchant.locations) {
    if (merchantLocationShouldBeOpen(location)) {
      result = true;
      break;
    }
  }
  return result;
}
*/
