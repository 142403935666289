import {
  SET_ORDER_DETAILS,
  SET_ORDER_CUSTOMER,
  SET_ORDER_DELIVERY_ADDRESS,
  SET_ORDER_REFERENCE,
  SET_ORDER_FULFILMENT_TIME,
} from "../reducers/orderConstants";

export const setOrderDetails = (payload) => (dispatch, getState) => {
  dispatch({
    type: SET_ORDER_DETAILS,
    payload,
  });

  const newState = getState();
  return Promise.resolve(newState.order);
};

export const setOrderCustomer = (payload) => (dispatch, getState) => {
  dispatch({
    type: SET_ORDER_CUSTOMER,
    payload,
  });

  const newState = getState();
  return Promise.resolve(newState.login.isAuthenticated);
};

export const setOrderDeliveryAddress = (payload) => (dispatch, getState) => {
  dispatch({
    type: SET_ORDER_DELIVERY_ADDRESS,
    payload,
  });

  const newState = getState();
  return Promise.resolve(newState.login.isAuthenticated);
};

export const setOrderReference = (payload) => (dispatch) => {
  dispatch({
    type: SET_ORDER_REFERENCE,
    payload,
  });

  return Promise.resolve();
};

export const setOrderFulfilmentTime = (payload) => (dispatch) => {
  dispatch({
    type: SET_ORDER_FULFILMENT_TIME,
    payload,
  });

  return Promise.resolve();
};
