import React, { useState } from "react";
import PropTypes from "prop-types";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import PaymentForm from "./PaymentForm";

function PaymentProvider(props) {
  const [stripePromise] = useState(() => loadStripe(props.apiKey));

  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        paymentToken={props.paymentToken}
        billingAddress={props.billingAddress}
        customer={props.customer}
        cartTotal={props.cartTotal}
        paymentButtonEnabled={props.paymentButtonEnabled}
        startCardPayment={props.startCardPayment}
        togglePaymentButtonEnabled={props.togglePaymentButtonEnabled}
        cardPaymentFailed={props.cardPaymentFailed}
        submitPlaceholderOrder={props.submitPlaceholderOrder}
        orderSubmissionFailed={props.orderSubmissionFailed}
        paymentSucceeded={props.paymentSucceeded}
      />
    </Elements>
  );
}

PaymentProvider.propTypes = {
  apiKey: PropTypes.string.isRequired,
  paymentToken: PropTypes.string.isRequired,
  billingAddress: PropTypes.object,
  customer: PropTypes.object.isRequired,
  cartTotal: PropTypes.string.isRequired,
  paymentButtonEnabled: PropTypes.bool.isRequired,
  startCardPayment: PropTypes.bool.isRequired,
  togglePaymentButtonEnabled: PropTypes.func.isRequired,
  cardPaymentFailed: PropTypes.func.isRequired,
  submitPlaceholderOrder: PropTypes.func.isRequired,
  orderSubmissionFailed: PropTypes.func.isRequired,
  paymentSucceeded: PropTypes.func.isRequired,
};

export default PaymentProvider;
