import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { getCdnImageUrl } from "../../utils/assetUtils";

import { Link } from "react-router-dom";
import AccountMenu from "../Login/AccountMenu";
import CartIcon from "../CartIcon/CartIcon";

import useMediaQuery from "@mui/material/useMediaQuery";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import cssStyles from "./Header.module.scss";

const PREFIX = "Header";

const classes = {
  menuIcon: `${PREFIX}-menuIcon`,
  cartIcon: `${PREFIX}-cartIcon`,
  topNavLinksContainer: `${PREFIX}-topNavLinksContainer`,
  topNavLinks: `${PREFIX}-topNavLinks`,
  topNavLink: `${PREFIX}-topNavLink`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.menuIcon}`]: {
    marginTop: 20,
    marginLeft: 20,
    padding: 0,
  },

  [`& .${classes.cartIcon}`]: {
    padding: 0,
    marginTop: -4,
    borderRadius: 0,
  },

  [`& .${classes.topNavLinksContainer}`]: {
    verticalAlign: "top",
    display: "inline-block",
  },

  [`& .${classes.topNavLinks}`]: {
    paddingTop: 5,
    height: 65,
    display: "flex",
  },

  [`& .${classes.topNavLink}`]: {
    width: 160,
    justifyContent: "center",
    fontSize: 18,
    fontWeight: 400,
    color: theme.palette.primary.main,
    "&:hover": {
      background: "transparent",
      color: theme.palette.primary.main,
    },
    "&:visited": {
      color: theme.palette.primary.main,
    },
  },
}));

function Header(props) {
  const {
    merchant,
    hasImageGallery,
    showNav,
    showLoginIcon,
    showCartIcon,
    animateCartIcon,
    loginCompletionUrl,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const logoImageUrl = getCdnImageUrl("web/logo.webp", merchant.name);
  const cartItemsCount = props.cartItemsCount || 0;
  const cartTotal =
    cartItemsCount === 0 || !props.cartTotal ? "0.00" : props.cartTotal;

  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [sideCartOpen, setSideCartOpen] = useState(false);

  function toggleSideMenu() {
    setSideMenuOpen(!sideMenuOpen);
  }

  function toggleSideCart() {
    setSideCartOpen(!sideCartOpen);
  }

  function cartIconAnimationComplete() {
    props.cartIconAnimationComplete();
  }

  function closeSideMenu() {
    setSideMenuOpen(false);
  }

  return (
    <Root>
      {showNav && isMobile && (
        <Drawer
          hideBackdrop
          open={sideMenuOpen}
          onClose={toggleSideMenu}
          classes={{
            paper: cssStyles.mobileSideMenu,
          }}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={toggleSideMenu}
            onKeyDown={toggleSideMenu}
          >
            <ClickAwayListener onClickAway={closeSideMenu}>
              <List>
                <ListItem component={Link} to="/" button>
                  <ListItemText primary="Home" />
                </ListItem>
                <ListItem component={Link} to="/hours" button>
                  <ListItemText primary="Opening Hours" />
                </ListItem>
                <ListItem component={Link} to="/contact" button>
                  <ListItemText primary="Contact Us" />
                </ListItem>
                {hasImageGallery && (
                  <ListItem component={Link} to="/#gallery" button>
                    <ListItemText primary="Gallery" />
                  </ListItem>
                )}
              </List>
            </ClickAwayListener>
          </div>
        </Drawer>
      )}

      {showCartIcon && (
        <Drawer
          anchor="right"
          open={sideCartOpen}
          onClose={toggleSideCart}
          classes={{ paper: cssStyles.mobileSideCart }}
        >
          <div tabIndex={0} role="button">
            {props.children}
          </div>
        </Drawer>
      )}

      <header className={cssStyles.header}>
        <nav className={cssStyles.headerNav}>
          {(!isMobile || !showNav) && (
            <Link to="/">
              <div
                style={{ backgroundImage: `url("${logoImageUrl}")` }}
                className={cssStyles.logo}
              ></div>
            </Link>
          )}

          {showNav && isMobile && (
            <IconButton
              aria-label="Menu"
              className={classes.menuIcon}
              onClick={toggleSideMenu}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          )}

          {showNav && !isMobile && (
            <div className={classes.topNavLinksContainer}>
              <List className={classes.topNavLinks}>
                <ListItem
                  component={Link}
                  to="/"
                  button
                  className={classes.topNavLink}
                >
                  Home
                </ListItem>
                <ListItem
                  component={Link}
                  to="/hours"
                  button
                  className={classes.topNavLink}
                >
                  Opening Hours
                </ListItem>
                <ListItem
                  component={Link}
                  to="/contact"
                  button
                  className={classes.topNavLink}
                >
                  Contact Us
                </ListItem>
                {hasImageGallery && (
                  <ListItem
                    component={Link}
                    to="/#gallery"
                    button
                    className={classes.topNavLink}
                  >
                    Gallery
                  </ListItem>
                )}
              </List>
            </div>
          )}

          {!showCartIcon && showLoginIcon && (
            <div className={cssStyles.accountMenu}>
              <AccountMenu loginCompletionUrl={loginCompletionUrl} />
            </div>
          )}

          {showCartIcon && (
            <div className={cssStyles.cartIcon}>
              <IconButton
                aria-label="Cart"
                className={classes.cartIcon}
                onClick={toggleSideCart}
                size="large"
              >
                <CartIcon
                  items={cartItemsCount}
                  amount={cartTotal}
                  animate={animateCartIcon}
                  animationComplete={cartIconAnimationComplete}
                />
              </IconButton>
            </div>
          )}
        </nav>
      </header>
    </Root>
  );
}

Header.propTypes = {
  showCartIcon: PropTypes.bool.isRequired,
  showLoginIcon: PropTypes.bool.isRequired,
  loginCompletionUrl: PropTypes.string,
  showNav: PropTypes.bool,
  animateCartIcon: PropTypes.bool,
  cartItemsCount: PropTypes.number,
  cartTotal: PropTypes.string,
  cartIconAnimationComplete: PropTypes.func,
  children: PropTypes.object,
  merchant: PropTypes.object.isRequired,
};

Header.defaultProps = {
  showCartIcon: false,
  showLoginIcon: true,
  showNav: false,
};

export default Header;
