import React, { useState } from "react";
import PropTypes from "prop-types";
import { formatMoney } from "../../utils/currency";
import { getCdnMenuImageUrl } from "../../utils/assetUtils";
import { renderTags } from "../../utils/menu";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import MenuItemOptions from "./MenuItemOptions";
import MenuItemExtras from "./MenuItemExtras";
import QuantityPicker from "./QuantityPicker";

import cssStyles from "./MenuItemDialog.module.scss";
import allergenStyles from "./Allergens.module.scss";

function MenuItemDialog(props) {
  const { item, dialogOpen, onClose, viewOnly, merchantName, collectionOrder } =
    props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const imageSrc =
    item && item.image ? getCdnMenuImageUrl(item.image, merchantName) : null;

  const [addToCartQuantity, setAddToCartQuantity] = useState(1);
  const [optionChoices, setOptionsChoices] = useState([]);
  const [extrasChoices, setExtrasChoices] = useState([]);

  var itemPrice = 0;
  var totalPrice = 0;

  if (item) {
    itemPrice =
      collectionOrder && item.price_collection
        ? Number(item.price_collection)
        : Number(item.price);
    totalPrice = itemPrice;

    for (const optionChoice of optionChoices) {
      totalPrice += Number(optionChoice.price);
    }

    for (const extraChoice of extrasChoices) {
      totalPrice += Number(extraChoice.price);
    }

    totalPrice = totalPrice * addToCartQuantity;
  }

  function updateOptionChoices(optionChoices) {
    setOptionsChoices(optionChoices);
  }

  function updateExtrasChoices(extrasChoices) {
    setExtrasChoices(extrasChoices);
  }

  function updateAddToCartQuantity(addToCartQuantity) {
    setAddToCartQuantity(addToCartQuantity);
  }

  function addToCart() {
    props.addToCartClicked(
      props.item,
      optionChoices,
      extrasChoices,
      addToCartQuantity
    );
  }

  function renderToolbar() {
    return (
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          size="large"
        >
          <CloseIcon />
        </IconButton>

        <Typography
          variant="h6"
          sx={{
            marginLeft: theme.spacing(2),
            flex: 1,
            color: "white",
          }}
        >
          {item.name}
        </Typography>
      </Toolbar>
    );
  }

  function renderImageToolbar() {
    return (
      <Toolbar
        className={cssStyles.imageToolbar}
        sx={{
          alignItems: "start",
          backgroundColor: "primary.main",
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          size="large"
          sx={{ zIndex: 1000, paddingLeft: 1 }}
        >
          <CloseIcon />
        </IconButton>

        <Container
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: 1,
            marginRight: 4,
          }}
        >
          <img
            className={cssStyles.headerImage}
            src={imageSrc}
            alt={item.name}
          />
        </Container>
      </Toolbar>
    );
  }

  if (item === null) {
    return null;
  } else {
    return (
      <Dialog
        id="menu-item-dialog"
        open={dialogOpen}
        onClose={onClose}
        scroll="body"
        fullScreen={fullScreen}
      >
        {fullScreen && (
          <AppBar
            style={{ paddingBottom: item.image ? 40 : 0 }}
            sx={{
              position: "relative",
              height: item.image ? 150 : "auto",
              backgroundColor: item.image ? "#FFF" : "primary.main",
            }}
          >
            {item.image ? renderImageToolbar() : renderToolbar()}
          </AppBar>
        )}

        {!fullScreen && item.image && (
          <Container
            disableGutters
            sx={{
              paddingTop: 2,
              marginBottom: 4,
              backgroundColor: "primary.main",
            }}
          >
            <Container
              disableGutters
              sx={{
                margin: "auto",
                marginRight: 6,
                height: 110,
                backgroundColor: "primary.main",
              }}
            >
              <img
                className={cssStyles.headerImage}
                src={imageSrc}
                alt={item.name}
              />
            </Container>
          </Container>
        )}
        <DialogTitle id="scroll-dialog-title" className={cssStyles.title}>
          {item.name}
        </DialogTitle>
        <DialogContent className={cssStyles.dialogContent}>
          {item.description && (
            <DialogContentText sx={{ marginBottom: 1 }}>
              {item.description}
            </DialogContentText>
          )}

          {renderTags(item, allergenStyles)}

          <DialogContentText
            sx={{ marginTop: theme.spacing(2), color: "black" }}
          >
            {formatMoney(itemPrice)}
          </DialogContentText>

          <MenuItemOptions
            options={item.active_menu_item_options}
            optionChoicesUpdated={updateOptionChoices}
          />

          <MenuItemExtras
            extras={item.active_menu_item_extras}
            extrasChoicesUpdated={updateExtrasChoices}
          />
          {!viewOnly && (
            <QuantityPicker quantityUpdated={updateAddToCartQuantity} />
          )}
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: "center", paddingBottom: theme.spacing(2) }}
        >
          <Button
            onClick={onClose}
            color="primary"
            variant={viewOnly ? "contained" : "outlined"}
          >
            Close
          </Button>
          {!viewOnly && (
            <Button
              id="add-to-basket-button"
              onClick={addToCart}
              color="primary"
              variant="contained"
            >
              {`Add to Basket ${formatMoney(totalPrice)}`}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

MenuItemDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  addToCartClicked: PropTypes.func.isRequired,
  item: PropTypes.object,
  merchantName: PropTypes.string.isRequired,
  collectionOrder: PropTypes.bool.isRequired,
  viewOnly: PropTypes.bool.isRequired,
};

export default MenuItemDialog;
