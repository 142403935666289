import React, { useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { postPasswordResetRequest } from "../../actions/loginActions";
import { getMerchantDetails } from "../../reducers";
import { getCdnImageUrl } from "../../utils/assetUtils";

import { StyledPaper } from "./StyledComponents";

import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../Header/Header";
import PasswordResetForm from "./PasswordResetForm";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import cssStyles from "./Login.module.scss";

const PREFIX = "PasswordReset";

const classes = {
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.paper}`]: {
    maxWidth: 400,
    margin: "0 auto",
    padding: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      borderRadius: 0,
      boxShadow: "none",
    },
  },

  [`& .${classes.heading}`]: {
    marginBottom: theme.spacing(2),
  },
}));

function PasswordReset(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { merchant } = props.getMerchantDetails;
  const backgroundImageUrl = getCdnImageUrl(
    "web/background.webp",
    merchant.name
  );
  const [email, setEmail] = useState(null);

  function sendResetEmail(email) {
    setEmail(email);
    props.dispatch(postPasswordResetRequest(email));
  }

  return (
    <Root>
      <Header showLoginIcon={false} merchant={merchant} />

      <main
        style={
          isMobile
            ? { background: "none" }
            : { backgroundImage: `url("${backgroundImageUrl}")` }
        }
        className={cssStyles.main}
      >
        <StyledPaper className={classes.paper}>
          <Typography variant="h6" gutterBottom>
            Password reset
          </Typography>

          {!email && (
            <Typography variant="body1" gutterBottom>
              Enter your email address below and we'll send you a link to choose
              a new password.
            </Typography>
          )}

          {email && (
            <>
              <p>{`Password reset instructions have been sent to ${email}`}</p>
              <Button
                style={{ color: "white" }}
                variant="contained"
                color="primary"
                href="/"
                fullWidth
              >
                OK
              </Button>
            </>
          )}

          {!email && <PasswordResetForm onSendReset={sendResetEmail} />}
        </StyledPaper>
      </main>
    </Root>
  );
}

function mapStateToProps(state) {
  return {
    getMerchantDetails: getMerchantDetails(state),
  };
}

PasswordReset.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getMerchantDetails: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(PasswordReset);
