import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getLoginDetails,
  getOrder,
  getMerchantDetails,
  getCartDetails,
} from "../../reducers";
import {
  locationCanAcceptOrdersNow,
  allowsOrderAhead,
  adequateTimeForOrderAhead,
} from "../../utils/fulfilment";
import {
  getMerchantLocation,
  merchantHasMultipleLocations,
} from "../../utils/merchant";
import { getCdnImageUrl } from "../../utils/assetUtils";
import { startNewOrder } from "../../utils/newOrder";
import { checkLogInStatus } from "../../actions/loginActions";
import { setOrderDetails } from "../../actions/orderActions";
import CollectionDeliverySelector from "./CollectionDeliverySelector";

import Header from "../Header/Header";
import MyOrdersDialog from "../Dialog/MyOrdersDialog";
import PromotionsSlider from "./promotionsSlider/PromotionsSlider";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {
  COLLECTION_ORDER,
  DELIVERY_ORDER,
} from "../../reducers/orderConstants";

import cssStyles from "./Order.module.scss";

const MINIMUM_TIME_FOR_ORDER_AHEAD_MINS = 120;

const notAcceptingOrdersAtThisTimeMessage = (multilocation) => {
  return (
    <>
      <Typography variant="body1" paragraph>
        {`Sorry, ${
          multilocation ? "this location is" : "we're"
        } not accepting order orders at this time.`}
      </Typography>
      <Typography variant="body1">
        Please check our opening hours for when we're next open.
      </Typography>
    </>
  );
};

function Order(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { merchant, locationId } = props.getMerchantDetails;
  const location = getMerchantLocation(merchant, locationId);
  const backgroundImageUrl = getCdnImageUrl(
    "web/background.webp",
    merchant.name
  );

  const [promotionDetailsIndex, setPromotionDetailsIndex] = useState(0);
  const [promotionDetailsDialogOpen, setPromotionDetailsDialogOpen] =
    useState(false);
  const [errorDialogContent, setErrorDialogContent] = useState({
    title: "",
    body: "",
  });
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [
    refreshCollectionDeliverySelector,
    shouldRefreshCollectionDeliverySelector,
  ] = useState(false);

  const promotionDetails = getPromotionDetails(merchant.promotions);

  useEffect(() => {
    props.dispatch(checkLogInStatus());
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  function proceed(order) {
    if (!order.type) {
      // Will view menu as "view only"
      proceedToMenu(order);
    } else {
      if (
        allowsOrderAhead(location) &&
        adequateTimeForOrderAhead(
          location,
          MINIMUM_TIME_FOR_ORDER_AHEAD_MINS,
          order.type
        )
      ) {
        navigate("/fulfilment");
      } else {
        proceedToMenu(order);
      }
    }
  }

  function proceedToMenu(order) {
    const cart = props.getCartDetails;

    startNewOrder(
      props.dispatch,
      cart,
      order,
      location,
      merchant.promotions,
      merchant.fees
    ).then(() => navigate("/menu"));
  }

  function startOrder(location, order) {
    // Check we're still open and accepting orders
    if (locationCanAcceptOrdersNow(location)) {
      proceed(order);
    } else {
      setErrorDialogContent({
        title: "",
        body: notAcceptingOrdersAtThisTimeMessage(location !== null),
      });
      setErrorDialogOpen(true);
      shouldRefreshCollectionDeliverySelector(
        !refreshCollectionDeliverySelector
      );
    }
  }

  function startCollectionOrder() {
    //console.log("Starting collection order. Location: " + location.name);

    props
      .dispatch(
        setOrderDetails({
          type: COLLECTION_ORDER,
          location,
        })
      )
      .then((order) => {
        startOrder(location, order);
      });
  }

  function startDeliveryOrder(deliveryPostcode, distanceToPostcode) {
    // console.log(
    //   "Let's start a delivery order for postcode " +
    //     deliveryPostcode +
    //     ", Distance: " +
    //     distanceToPostcode
    // );

    props
      .dispatch(
        setOrderDetails({
          type: DELIVERY_ORDER,
          deliveryPostcode: deliveryPostcode,
          distanceToPostcode: distanceToPostcode,
          location,
        })
      )
      .then((order) => {
        startOrder(location, order);
      });
  }

  function showMenu() {
    props
      .dispatch(
        setOrderDetails({
          type: null,
          location,
        })
      )
      .then((order) => {
        proceed(order);
      });
  }

  function showCollectionOrDeliveryMessage(message) {
    setErrorDialogContent({
      title: "",
      body: message,
    });
    setErrorDialogOpen(true);
  }

  function showPromotionDetailsDialog(promotionIndex) {
    setPromotionDetailsIndex(promotionIndex);
    setPromotionDetailsDialogOpen(true);
  }

  function hidePromotionDetailsDialog() {
    setPromotionDetailsDialogOpen(false);
  }

  function getPromotionDetails(promotions) {
    if (promotions.length === 0) {
      return { title: "", body: "" };
    } else {
      return {
        title: `${promotions[promotionDetailsIndex].title_emphasis_text} ${promotions[promotionDetailsIndex].title_text}`,
        body: promotions[promotionDetailsIndex].description,
      };
    }
  }

  function hideErrorDialog() {
    setErrorDialogOpen(false);
  }

  function getPromotionDetailsBodyText(body) {
    if (body) {
      return (
        <>
          {body.split("\n").map((item, i) => (
            <Typography key={i} variant="body2" gutterBottom>
              {item}
            </Typography>
          ))}
        </>
      );
    }
  }

  // console.log(
  //   "merchant location is open: ",
  //   merchantLocationIsOpen(merchant.locations[0])
  // );

  return (
    <div>
      <Header
        showCartIcon={false}
        merchant={merchant}
        loginCompletionUrl={window.location.pathname}
      />

      <main
        style={{ backgroundImage: `url("${backgroundImageUrl}")` }}
        className={cssStyles.main}
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Paper
            sx={{ padding: 2, alignSelf: "center" }}
            className={cssStyles.content}
          >
            <CollectionDeliverySelector
              location={location}
              multiLocation={merchantHasMultipleLocations(merchant)}
              startCollectionOrder={startCollectionOrder}
              startDeliveryOrder={startDeliveryOrder}
              showMenu={showMenu}
              showMessage={showCollectionOrDeliveryMessage}
              refresh={refreshCollectionDeliverySelector}
            />
          </Paper>
        </Grid>
        {merchant.promotions.length > 0 && (
          <Box mt={3} p={2} maxWidth={600} marginLeft="auto" marginRight="auto">
            <PromotionsSlider
              promotions={merchant.promotions}
              showDetails={showPromotionDetailsDialog}
              isMobile={isMobile}
            />
          </Box>
        )}
      </main>

      <MyOrdersDialog
        dialogOpen={promotionDetailsDialogOpen}
        title={promotionDetails.title}
        body={getPromotionDetailsBodyText(promotionDetails.body)}
        buttonText="OK"
        onClose={hidePromotionDetailsDialog}
      />

      <MyOrdersDialog
        dialogOpen={errorDialogOpen}
        title={errorDialogContent.title}
        body={
          <Typography variant="body2" gutterBottom>
            {errorDialogContent.body}
          </Typography>
        }
        buttonText="OK"
        onClose={hideErrorDialog}
      />
    </div>
  );
}

/*
<Paper
  className={cssStyles.content}
  style={{ width: 170, padding: 10 }}
>
  {merchant && (
    <p>We got merchant details</p>
  )}

  <p>{`Order type: ${this.props.getOrder.type}`}</p>

  <p>
    {`Authenticating: ${user.isAuthenticating} Authenticated: ${user.isAuthenticated}`}
  </p>
</Paper>
*/

function mapStateToProps(state) {
  return {
    getLoginDetails: getLoginDetails(state),
    getCartDetails: getCartDetails(state),
    getOrder: getOrder(state),
    getMerchantDetails: getMerchantDetails(state),
  };
}

Order.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getLoginDetails: PropTypes.object.isRequired,
  getCartDetails: PropTypes.object.isRequired,
  getOrder: PropTypes.object.isRequired,
  getMerchantDetails: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Order);
