import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { formatMoney } from "../../utils/currency";
import clsx from "clsx";

import { isDeliveryOrder } from "../../utils/order";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import cssStyles from "./OrderDetails.module.scss";

const PREFIX = "OrderDetails";

const classes = {
  container: `${PREFIX}-container`,
  itemsTable: `${PREFIX}-itemsTable`,
  itemsTableCell: `${PREFIX}-itemsTableCell`,
  itemName: `${PREFIX}-itemName`,
  itemPrice: `${PREFIX}-itemPrice`,
  totalField: `${PREFIX}-totalField`,
  cartTotal: `${PREFIX}-cartTotal`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.container}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.itemsTable}`]: {
    border: "none",
    marginTop: 10,
  },

  [`& .${classes.itemsTableCell}`]: {
    border: "none",
    padding: theme.spacing(1, 0),
  },

  [`& .${classes.itemName}`]: {
    border: "none",
    //backgroundColor: 'red'
  },

  [`& .${classes.itemPrice}`]: {
    width: 75,
    border: "none",
    //backgroundColor: 'yellow'
  },

  [`& .${classes.totalField}`]: {
    fontWeight: 200,
  },

  [`& .${classes.cartTotal}`]: {
    border: "none",
    fontWeight: 300,
    fontSize: 18,
  },
}));

class OrderDetails extends Component {
  renderItems = () => {
    const { items } = this.props;

    return Object.keys(items).map((key) => {
      const item = items[key];

      return (
        <TableRow key={key}>
          <TableCell className={clsx(classes.itemName, classes.itemsTableCell)}>
            {`${item.qty}x ${item.name}`}
          </TableCell>
          <TableCell
            align="right"
            className={clsx(classes.itemPrice, classes.itemsTableCell)}
          >
            {formatMoney(item.qty * item.price)}
          </TableCell>
        </TableRow>
      );
    });
  };

  renderDiscounts = () => {
    const { discounts } = this.props;
    var result = [];

    discounts.forEach(function (discount) {
      result.push(
        <TableRow key={discount.id}>
          <TableCell
            className={clsx(
              classes.itemsTableCell,
              classes.totalField,
              cssStyles.borderTop
            )}
          >
            {discount.lineItemText}
          </TableCell>
          <TableCell
            align="right"
            className={clsx(
              classes.itemsTableCell,
              classes.totalField,
              cssStyles.borderTop
            )}
          >
            {formatMoney(discount.discount)}
          </TableCell>
        </TableRow>
      );
    });

    return result;
  };

  renderItemTotal = () => {
    const { order, cartTotal, deliveryFee } = this.props;

    if (isDeliveryOrder(order) && deliveryFee > 0) {
      return (
        <TableRow key="itemTotal">
          <TableCell
            className={clsx(
              classes.itemsTableCell,
              classes.totalField,
              cssStyles.borderTop
            )}
          >
            Item Total
          </TableCell>
          <TableCell
            align="right"
            className={clsx(
              classes.itemsTableCell,
              classes.totalField,
              cssStyles.borderTop
            )}
          >
            {formatMoney(cartTotal - deliveryFee)}
          </TableCell>
        </TableRow>
      );
    }
  };

  renderDeliveryFee = () => {
    const { order, deliveryFee } = this.props;

    if (isDeliveryOrder(order) && deliveryFee > 0) {
      return (
        <TableRow key="deliveryFee">
          <TableCell
            className={clsx(
              classes.totalField,
              classes.itemsTableCell,
              cssStyles.borderTop
            )}
          >
            Delivery
          </TableCell>
          <TableCell
            align="right"
            className={clsx(
              classes.totalField,
              classes.itemsTableCell,
              cssStyles.borderTop
            )}
          >
            {formatMoney(deliveryFee)}
          </TableCell>
        </TableRow>
      );
    }
  };

  renderOrderCharges = () => {
    const { orderCharges } = this.props;

    var result = [];

    orderCharges.forEach(function (charge) {
      result.push(
        <TableRow key="deliveryFee">
          <TableCell
            className={clsx(
              classes.totalField,
              classes.itemsTableCell,
              cssStyles.borderTop
            )}
          >
            {charge.name}
          </TableCell>
          <TableCell
            align="right"
            className={clsx(
              classes.totalField,
              classes.itemsTableCell,
              cssStyles.borderTop
            )}
          >
            {formatMoney(charge.amount)}
          </TableCell>
        </TableRow>
      );
    });

    return orderCharges.length === 0 ? null : result;
  };

  renderTotal = () => {
    const { cartTotal } = this.props;

    return (
      <TableRow key="cartTotal">
        <TableCell
          className={clsx(
            classes.itemName,
            classes.itemsTableCell,
            classes.cartTotal,
            cssStyles.borderTop
          )}
        >
          Total
        </TableCell>
        <TableCell
          align="right"
          className={clsx(
            classes.itemPrice,
            classes.itemsTableCell,
            classes.cartTotal,
            cssStyles.borderTop
          )}
        >
          {formatMoney(cartTotal)}
        </TableCell>
      </TableRow>
    );
  };

  render() {
    return (
      <StyledPaper className={classes.container}>
        <Typography variant="h2" align="left" gutterBottom>
          Order Summary
        </Typography>
        <Table className={classes.itemsTable}>
          <TableBody>
            {this.renderItems()}
            {this.renderDiscounts()}
            {this.renderItemTotal()}
            {this.renderDeliveryFee()}
            {this.renderOrderCharges()}
            {this.renderTotal()}
          </TableBody>
        </Table>
      </StyledPaper>
    );
  }
}

OrderDetails.propTypes = {
  order: PropTypes.object.isRequired,
  items: PropTypes.object.isRequired,
  discounts: PropTypes.array.isRequired,
  deliveryFee: PropTypes.number.isRequired,
  orderCharges: PropTypes.array.isRequired,
  cartTotal: PropTypes.string.isRequired,
};

export default OrderDetails;
