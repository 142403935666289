import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import PropTypes, { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { connect } from "react-redux";
import { getMerchantDetails, getLoginDetails } from "../../reducers";
import { postTableBookingRequest } from "../../actions/tableBookingActions";
import { setCookie, getCookie } from "../../utils/cookies";
import { getCdnImageUrl } from "../../utils/assetUtils";
import Header from "../Header/Header";
import Spinner from "../Spinner/Spinner";
import MyOrdersDialog from "../Dialog/MyOrdersDialog";

import TableBookingForm from "./TableBookingForm";
import { getBookingConfirmationMessage } from "./ConfirmationText";

import useMediaQuery from "@mui/material/useMediaQuery";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import cssStyles from "./TableBooking.module.scss";

const CONTACT_COOKIE_NAME = "contact";

const getPrefillValue = (field, guest, cookiedValues, loginValues) => {
  return guest
    ? cookiedValues
      ? cookiedValues[field]
      : null
    : loginValues[field];
};

const formatDateForRequest = (date) => {
  return `${date.getDate()}/${date.getMonth() + 1}/${date
    .getFullYear()
    .toString()
    .substr(-2)}`;
};

function TableBooking(props) {
  const { cookies } = props;
  const { merchant } = props.getMerchantDetails;
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const loginDetails = props.getLoginDetails;
  const guest = !loginDetails.isAuthenticated;
  const previousContactDetailsJson = getCookie(cookies, CONTACT_COOKIE_NAME);
  const previousContactDetails = previousContactDetailsJson
    ? JSON.parse(previousContactDetailsJson)
    : null;
  const backgroundImageUrl = getCdnImageUrl(
    "web/background.webp",
    merchant.name
  );

  const prefill = {
    name: getPrefillValue("name", guest, previousContactDetails, loginDetails),
    email: getPrefillValue(
      "email",
      guest,
      previousContactDetails,
      loginDetails
    ),
    phone: getPrefillValue(
      "phone",
      guest,
      previousContactDetails,
      loginDetails
    ),
    marketingOptIn: getPrefillValue(
      "marketingOptIn",
      guest,
      previousContactDetails,
      loginDetails
    ),
  };

  const [location, setLocation] = useState(merchant.locations[0]);
  const [numberOfGuests, setNumberOfGuests] = useState(0);
  const [validateBookingRequest, setShouldValidateBookingRequest] =
    useState(false);
  const [submittingRequest, setSubmittingRequest] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [failureDialogOpen, setFailureDialogOpen] = useState(false);

  function sendBookingRequest(location, request) {
    setShouldValidateBookingRequest(false);

    if (request) {
      const { cookies } = props;
      const {
        selectedDate,
        selectedTime,
        selectedGuests,
        name,
        email,
        phone,
        message,
        marketingOptIn,
      } = request;
      const tableSize = parseInt(selectedGuests);
      setCookie(
        cookies,
        CONTACT_COOKIE_NAME,
        JSON.stringify({
          name,
          email,
          phone,
          marketingOptIn,
        })
      );

      setLocation(location);
      setNumberOfGuests(tableSize);
      setSuccessDialogOpen(false);
      setFailureDialogOpen(false);
      setSubmittingRequest(true);

      postTableBookingRequest({
        locationId: location.id,
        date: formatDateForRequest(selectedDate),
        time: selectedTime,
        tableSize,
        contactName: name,
        contactEmail: email,
        contactPhone: phone,
        notes: message,
        wantsMarketing: marketingOptIn,
      })
        .then(() => {
          setSubmittingRequest(false);
          console.log("Table booking submitted successfully.");
          setSuccessDialogOpen(true);
        })
        .catch((error) => {
          console.log("Table booking failed.");
          console.error(error);
          setSubmittingRequest(false);
          setFailureDialogOpen(true);
        });
    }
  }

  function submit() {
    setShouldValidateBookingRequest(true);
  }

  return (
    <>
      <Spinner text="Submitting Request" active={submittingRequest} />

      <Header
        showCartIcon={false}
        merchant={merchant}
        loginCompletionUrl={window.location.pathname}
      />
      <main
        style={{ backgroundImage: `url("${backgroundImageUrl}")` }}
        className={cssStyles.main}
      >
        <Paper className={cssStyles.content} square={isMobile}>
          <Typography variant="h2" align="center" gutterBottom>
            Book a Table
          </Typography>
          <TableBookingForm
            prefill={prefill}
            locations={merchant.locations.filter(
              (location) => location.has_book_a_table
            )}
            submit={validateBookingRequest}
            sendBookingRequest={sendBookingRequest}
            isMobile={isMobile}
          />
          <Button
            id="request-booking-button"
            variant="contained"
            fullWidth
            color="primary"
            onClick={submit}
            sx={{ mt: 2 }}
          >
            Request Booking
          </Button>
        </Paper>

        <MyOrdersDialog
          dialogOpen={successDialogOpen}
          title="Booking Request Sent!"
          body={getBookingConfirmationMessage(numberOfGuests)}
          buttonText="OK"
          onClose={() => navigate("/")}
          disableBackdropClick={false}
        />

        <MyOrdersDialog
          dialogOpen={failureDialogOpen}
          title="Sorry, something went wrong."
          body={
            <Typography variant="body" paragraph>
              Please call us to arrange your booking on{" "}
              <a href={`tel:${location.phone}`}>{location.phone}</a>
            </Typography>
          }
          buttonText="OK"
          onClose={() => setFailureDialogOpen(false)}
          disableBackdropClick={false}
        />
      </main>
    </>
  );
}

function mapStateToProps(state) {
  return {
    getMerchantDetails: getMerchantDetails(state),
    getLoginDetails: getLoginDetails(state),
  };
}

TableBooking.propTypes = {
  getMerchantDetails: PropTypes.object.isRequired,
  getLoginDetails: PropTypes.object.isRequired,
  cookies: instanceOf(Cookies).isRequired,
};

export default connect(mapStateToProps)(withCookies(TableBooking));
