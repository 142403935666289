import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { formatMoney } from "../../utils/currency";

import cssStyles from "./CartItem.module.scss";

export class CartItem extends PureComponent {
  incrementQty = (id) => {
    this.props.incrementQty(id);
  };

  decrementQty = (id) => {
    this.props.decrementQty(id);
  };

  removeItem = (id) => {
    this.props.removeItem(id);
  };

  render() {
    const { id, name, price, quantity } = this.props;

    return (
      <div>
        <div className={cssStyles.details}>
          {`${quantity}x ${name}`}
          <div className={cssStyles.price}>{`${formatMoney(price)}`}</div>
        </div>
        <div className={cssStyles.actions}>
          <div className={cssStyles.changeQty}>
            <button
              className={cssStyles.qtyArrowUp}
              onClick={() => this.incrementQty(id)}
            />
            <button
              className={cssStyles.qtyArrowDown}
              onClick={() => this.decrementQty(id)}
            />
          </div>
          <button
            className={cssStyles.removeItem}
            onClick={() => this.removeItem(id)}
          />
        </div>
      </div>
    );
  }
}

CartItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  incrementQty: PropTypes.func.isRequired,
  decrementQty: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
};

export default CartItem;
