import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes, { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { connect } from "react-redux";
import {
  getMerchantDetails,
  merchantRequestInProgress,
  merchantRequestSuccess,
  merchantRequestFailed,
} from "../../reducers";
import { getMerchant } from "../../actions/merchantActions";
import { setOrderDetails } from "../../actions/orderActions";
import { getMerchantMessageToCustomers } from "../../utils/merchant";
import { usePrevious } from "../../utils/state";
import { getCdnImageUrl } from "../../utils/assetUtils";
import { setCookie, getCookie } from "../../utils/cookies";

import Header from "../Header/Header";
import MyOrdersDialog from "../Dialog/MyOrdersDialog";
import CustomerMessage from "../CustomerMessage/CustomerMessage";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";

import cssStyles from "./Order.module.scss";

const LOCATION_COOKIE_NAME = "location";

function Location(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    cookies,
    dispatch,
    merchantRequestInProgress,
    merchantRequestSuccess,
  } = props;
  const { merchant, locationId } = props.getMerchantDetails;
  const backgroundImageUrl = getCdnImageUrl(
    "web/background.webp",
    merchant.name
  );
  const previousLocationId = getCookie(cookies, LOCATION_COOKIE_NAME);
  const defaultLocationId =
    previousLocationId &&
    merchant.locations.find(
      (location) => location.id === Number(previousLocationId)
    )
      ? Number(previousLocationId)
      : merchant.locations[0].id;
  const [newLocationId, setNewLocationId] = useState(defaultLocationId);
  const [showCustomerMessage, shouldShowCustomerMessage] = useState(false);
  const [customerMessage, setCustomerMessage] = useState(null);
  const [errorDialogContent, setErrorDialogContent] = useState({
    title: "",
    body: "",
  });
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const handleLocationChange = (event) => {
    setNewLocationId(event.target.value);
  };

  const customerMessageAcknowledged = () => {
    proceed();
  };

  const proceed = useCallback(() => {
    const location = merchant.locations.find(
      (location) => location.id === newLocationId
    );

    dispatch(
      setOrderDetails({
        location,
      })
    ).then(() => {
      navigate("/order");
    });
  }, [navigate, dispatch, merchant.locations, newLocationId]);

  const merchantRequestWasInProgress = usePrevious(
    props.merchantRequestInProgress
  );

  useEffect(() => {
    if (merchantRequestWasInProgress && !merchantRequestInProgress) {
      if (merchantRequestSuccess) {
        const customerMessage = getMerchantMessageToCustomers(
          merchant,
          newLocationId
        );
        if (customerMessage.enabled) {
          setCustomerMessage(customerMessage);
          shouldShowCustomerMessage(true);
        } else {
          proceed();
        }
      } else {
        setErrorDialogContent({
          title: "Sorry, something has gone wrong",
          body: "Please try again later.",
        });
        setErrorDialogOpen(true);
      }
    }
  }, [
    merchantRequestWasInProgress,
    merchantRequestInProgress,
    merchantRequestSuccess,
    merchant,
    newLocationId,
    proceed,
  ]);

  const continueWithSelectedLocation = () => {
    setCookie(cookies, LOCATION_COOKIE_NAME, newLocationId);

    if (newLocationId !== locationId) {
      // Get merchant details from server because the menu returned is location specific
      props.dispatch(getMerchant(newLocationId));
    } else {
      const customerMessage = getMerchantMessageToCustomers(
        merchant,
        newLocationId
      );

      if (customerMessage.enabled) {
        setCustomerMessage(customerMessage);
        shouldShowCustomerMessage(true);
      } else {
        proceed();
      }
    }
  };

  const hideErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  const renderLocations = () => {
    return merchant.locations.map((location) => (
      <MenuItem key={location.id} value={location.id}>
        {location.name}
      </MenuItem>
    ));
  };

  return (
    <div>
      <Header
        showCartIcon={false}
        merchant={merchant}
        loginCompletionUrl={window.location.pathname}
      />

      <main
        style={{ backgroundImage: `url("${backgroundImageUrl}")` }}
        className={cssStyles.main}
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Paper
            className={cssStyles.content}
            sx={{ padding: 2, alignSelf: "center" }}
            square={isMobile}
          >
            <Typography variant="h6" gutterBottom>
              Location
            </Typography>
            <Typography variant="body1" paragraph>
              Choose Your Local Restaurant
            </Typography>
            <FormControl sx={{ minWidth: 250 }}>
              <Select value={newLocationId} onChange={handleLocationChange}>
                {renderLocations()}
              </Select>

              <Button
                id="collection-order-button"
                variant="contained"
                color="primary"
                onClick={continueWithSelectedLocation}
                sx={{ marginTop: 2 }}
                fullWidth
              >
                Continue
              </Button>
            </FormControl>
          </Paper>
        </Grid>
      </main>

      <MyOrdersDialog
        dialogOpen={errorDialogOpen}
        title={errorDialogContent.title}
        body={
          <Typography variant="body2" gutterBottom>
            {errorDialogContent.body}
          </Typography>
        }
        buttonText="OK"
        onClose={hideErrorDialog}
      />

      {customerMessage && (
        <CustomerMessage
          visible={showCustomerMessage}
          message={customerMessage}
          onClose={customerMessageAcknowledged}
        />
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    getMerchantDetails: getMerchantDetails(state),
    merchantRequestInProgress: merchantRequestInProgress(state),
    merchantRequestSuccess: merchantRequestSuccess(state),
    merchantRequestFailed: merchantRequestFailed(state),
  };
}

Location.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getMerchantDetails: PropTypes.object.isRequired,
  cookies: instanceOf(Cookies).isRequired,
};

export default connect(mapStateToProps)(withCookies(Location));
