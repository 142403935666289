import React, { Component } from "react";
import PropTypes from "prop-types";
import ChoiceLabel from "./ChoiceLabel";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  StyledFormControl,
  StyledFormLabel,
  StyledRadioGroup,
} from "./StyledComponents";

export class MenuItemOptions extends Component {
  constructor(props) {
    super(props);

    const { options } = this.props;
    const optionChoices = options.reduce(function (map, option) {
      if (option.active_menu_item_option_choices.length > 0) {
        map[option.id.toString()] =
          option.active_menu_item_option_choices[0].id.toString();
      }

      return map;
    }, {});

    this.state = {
      optionChoices,
    };

    this.optionChoicesUpdated(optionChoices);
  }

  optionChoicesUpdated = (optionChoices) => {
    const { options } = this.props;
    const optionChoiceDetails = Object.values(optionChoices).reduce(function (
      map,
      optionChoiceId
    ) {
      const choiceId = parseInt(optionChoiceId, 10);
      for (const option of options) {
        for (const choice of option.active_menu_item_option_choices) {
          if (choice.id === choiceId) {
            map.push({
              id: choiceId,
              name: choice.name,
              price: choice.price,
            });
            break;
          }
        }
      }

      return map;
    },
    []);

    this.props.optionChoicesUpdated(optionChoiceDetails);
  };

  optionChanged = (event) => {
    const optionChoices = this.state.optionChoices;
    optionChoices[event.target.name] = event.target.value;
    this.setState({ optionChoices });
    this.optionChoicesUpdated(optionChoices);
  };

  renderOptionChoices = (option) => {
    if (option.active_menu_item_option_choices.length > 0) {
      return option.active_menu_item_option_choices.map((choice) => {
        return (
          <FormControlLabel
            key={choice.id}
            value={choice.id.toString()}
            control={<Radio />}
            label={<ChoiceLabel name={choice.name} price={choice.price} />}
          />
        );
      });
    }
    return null;
  };

  render() {
    const { options } = this.props;

    if (options.length > 0) {
      return (
        <>
          {options
            .filter(
              (option) => option.active_menu_item_option_choices.length > 0
            )
            .map((option) => (
              <StyledFormControl component="fieldset" key={option.id}>
                <StyledFormLabel component="legend">
                  {option.name}
                </StyledFormLabel>
                <StyledRadioGroup
                  aria-label={option.name}
                  name={option.id.toString()}
                  value={this.state.optionChoices[option.id]}
                  onChange={this.optionChanged}
                >
                  {this.renderOptionChoices(option)}
                </StyledRadioGroup>
              </StyledFormControl>
            ))}
        </>
      );
    }

    return null;
  }
}

MenuItemOptions.propTypes = {
  options: PropTypes.array.isRequired,
  optionChoicesUpdated: PropTypes.func.isRequired,
};

export default MenuItemOptions;
