import React, { Component } from "react";
import PropTypes, { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { setCookie, getCookie } from "../../utils/cookies";
import { isCollectionOrder } from "../../utils/order";
import { connect } from "react-redux";
import {
  initPostcodeChecker,
  checkDeliveryPostcode,
} from "../../actions/postcodeActions";
import { removeAllItemsFromCart } from "../../actions/cartActions";
import {
  getOrder,
  canDeliverToPostcode,
  getDeliveryPostcode,
  getDistanceToPostcode,
  getDeliveryLocationId,
  checkingDeliveryPostcode,
  checkingDeliveryPostcodeSucceeded,
} from "../../reducers";
import {
  COLLECTION_ORDER,
  DELIVERY_ORDER,
  TABLE_ORDER,
} from "../../reducers/orderConstants";
import { DELIVERY_ADDRESS_COOKIE_NAME } from "../Checkout/Delivery";
import { normalizePostcode, postcodeIsValid } from "../../utils/postcode";
import {
  merchantLocationShouldBeOpen,
  locationCanDeliver,
  locationCanDeliverToday,
  locationCanAcceptDeliveryOrdersNow,
  locationAllowsCollection,
  locationAllowsCollectionToday,
  locationCanAcceptCollectionOrdersNow,
} from "../../utils/fulfilment";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import LocationIcon from "@mui/icons-material/LocationOn";
import { CollectionIcon, DeliveryIcon } from "./icons";
import cssStyles from "./CollectionDeliverySelector.module.scss";

const INVALID_POSTCODE_TEXT = "Sorry, we don't recognise this postcode.";
const CANT_DELIVER_TO_POSTCODE_TEXT =
  "Sorry but we don't currently deliver to this postcode.";
const POSTCODE_CHECK_REQUEST_FAILED_TEXT =
  "Sorry, we couldn't check your postcode. Please try again.";

// TODO: Show message if delivery not available today
const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export class CollectionDeliverySelector extends Component {
  constructor(props) {
    super(props);

    const todayDate = new Date();
    const todayDayIndex = todayDate.getDay();
    const { cookies } = this.props;
    const order = this.props.getOrder;
    const previousDeliveryAddressJson = getCookie(
      cookies,
      DELIVERY_ADDRESS_COOKIE_NAME
    );

    this.currentOrderIsForCollection = isCollectionOrder(order);
    this.previousDeliveryAddress = previousDeliveryAddressJson
      ? JSON.parse(previousDeliveryAddressJson)
      : null;

    this.state = {
      canDeliver: false,
      canDeliverToday: false,
      acceptsDeliveryOrdersNow: false,
      allowsCollection: false,
      allowsCollectionToday: false,
      acceptCollectionOrdersNow: false,
      orderType: COLLECTION_ORDER,
      collectionLocationInvalid: false,
      deliveryPostcode:
        this.previousDeliveryAddress && this.previousDeliveryAddress.postcode
          ? this.previousDeliveryAddress.postcode
          : "",
      deliveryPostcodeInvalid: false,
      postcodeHelperText: null,
      collectionLocationSelect: "",
      collectionLocationId: null,
      collectionLocationLabelWidth: 0,
    };

    this.todayDay = days[todayDayIndex];
    this.collectionLocationLabelRef = React.createRef();
  }

  componentDidMount() {
    const { location } = this.props;

    const canDeliver = locationCanDeliver(location);
    const canDeliverToday = locationCanDeliverToday(location);
    const acceptsDeliveryOrdersNow =
      locationCanAcceptDeliveryOrdersNow(location);

    const allowsCollection = locationAllowsCollection(location);
    const allowsCollectionToday = locationAllowsCollectionToday(location);
    const acceptsCollectionOrdersNow =
      locationCanAcceptCollectionOrdersNow(location);

    var orderType = COLLECTION_ORDER;

    this.props.dispatch(
      initPostcodeChecker({
        postcode: this.state.deliveryPostcode,
      })
    );

    if (this.collectionLocationLabelRef.current !== null) {
      this.setState({
        collectionLocationLabelWidth:
          this.collectionLocationLabelRef.current.offsetWidth,
      });
    }

    if (!allowsCollection) {
      if (canDeliver) {
        orderType = DELIVERY_ORDER;
      } else {
        orderType = TABLE_ORDER;
      }
    }

    this.setState({
      canDeliver,
      canDeliverToday,
      acceptsDeliveryOrdersNow,
      allowsCollection,
      allowsCollectionToday,
      acceptsCollectionOrdersNow,
      orderType,
    });
  }

  componentDidUpdate(prevProps) {
    const { checkingDeliveryPostcodeSucceeded, refresh } = this.props;

    if (prevProps.refresh !== refresh) {
      this.forceUpdate();
    }

    if (prevProps.checkingDeliveryPostcode) {
      if (checkingDeliveryPostcodeSucceeded) {
        const deliveryPostcode = this.props.getDeliveryPostcode;
        if (this.props.canDeliverToPostcode && deliveryPostcode !== null) {
          const distanceToPostcode = this.props.getDistanceToPostcode;

          const { cookies } = this.props;
          var deliveryAddress = {
            line1: null,
            line2: null,
            city: null,
            postcode: deliveryPostcode,
          };

          if (
            this.previousDeliveryAddress &&
            normalizePostcode(deliveryPostcode) ===
              normalizePostcode(this.previousDeliveryAddress.postcode)
          ) {
            deliveryAddress.line1 = this.previousDeliveryAddress.line1;
            deliveryAddress.line2 = this.previousDeliveryAddress.line2;
            deliveryAddress.city = this.previousDeliveryAddress.city;
          }

          setCookie(
            cookies,
            DELIVERY_ADDRESS_COOKIE_NAME,
            JSON.stringify(deliveryAddress)
          );
          if (this.currentOrderIsForCollection) {
            this.props.dispatch(removeAllItemsFromCart()).then(() => {
              this.props.startDeliveryOrder(
                deliveryPostcode,
                distanceToPostcode
              );
            });
          } else {
            this.props.startDeliveryOrder(deliveryPostcode, distanceToPostcode);
          }
        } else {
          this.props.showMessage(CANT_DELIVER_TO_POSTCODE_TEXT);
        }
      } else {
        this.setState({
          postcodeHelperText: POSTCODE_CHECK_REQUEST_FAILED_TEXT,
        });
      }
    }
  }

  handleDeliveryPostcodeChange = (event) => {
    this.setState({
      deliveryPostcode: event.target.value.toUpperCase(),
      deliveryPostcodeInvalid: false,
    });
  };

  handleCollectionLocationChange = (event) => {
    this.setState({
      collectionLocationSelect: event.target.value,
      collectionLocationId: event.target.value,
      collectionLocationInvalid: false,
    });
  };

  startCollectionOrder = () => {
    if (!this.currentOrderIsForCollection) {
      this.props.dispatch(removeAllItemsFromCart()).then(() => {
        this.props.startCollectionOrder();
      });
    } else {
      this.props.startCollectionOrder();
    }
  };

  startDeliveryOrder = (event) => {
    const { deliveryPostcode } = this.state;
    event.preventDefault();

    if (deliveryPostcode.length === 0) {
      this.setState({
        deliveryPostcodeInvalid: true,
        postcodeHelperText: null,
      });
    } else {
      if (postcodeIsValid(this.state.deliveryPostcode)) {
        this.setState({
          deliveryPostcodeInvalid: false,
          postcodeHelperText: null,
        });

        this.props.dispatch(
          checkDeliveryPostcode({
            postcode: this.state.deliveryPostcode,
          })
        );
      } else {
        this.setState({
          deliveryPostcodeInvalid: true,
          postcodeHelperText: INVALID_POSTCODE_TEXT,
        });
      }
    }
  };

  showMenu = () => {
    this.props.showMenu();
  };

  setCollectionOrder = () => {
    this.setState({ orderType: COLLECTION_ORDER });
  };

  setDeliveryOrder = () => {
    this.setState({ orderType: DELIVERY_ORDER });
  };

  renderDeliveryMessage(orderType, acceptsDeliveryOrdersNow, canDeliverToday) {
    if (orderType === DELIVERY_ORDER && !acceptsDeliveryOrdersNow) {
      var message = null;

      if (!canDeliverToday) {
        message = `Sorry, but we don't currently deliver on ${this.todayDay}s`;
      } else if (!acceptsDeliveryOrdersNow) {
        message = `Sorry, but we don't currently deliver at this time of day on ${this.todayDay}s`;
      }

      if (message) {
        return (
          <p className={cssStyles.message} data-testid="delivery-message">
            {message}
          </p>
        );
      }
    }
  }

  renderCollectionMessage(
    orderType,
    acceptsCollectionOrdersNow,
    allowsCollectionToday
  ) {
    if (orderType === COLLECTION_ORDER && !acceptsCollectionOrdersNow) {
      var message = null;

      if (!allowsCollectionToday) {
        message = `Sorry, but we don't currently allow collection on ${this.todayDay}s`;
      } else if (!acceptsCollectionOrdersNow) {
        message = `Sorry, but we don't currently allow collection at this time of day on ${this.todayDay}s`;
      }

      if (message) {
        return (
          <p className={cssStyles.message} data-testid="collection-message">
            {message}
          </p>
        );
      }
    }
  }

  renderIcons = (orderType, allowsCollection, canDeliver) => {
    return (
      <Grid
        container
        justifyContent="space-around"
        sx={{ paddingLeft: 2, paddingRight: 2 }}
      >
        {allowsCollection &&
          this.renderIcon(
            this.setCollectionOrder,
            orderType === COLLECTION_ORDER
              ? cssStyles.imgSelectorSelected
              : cssStyles.imgSelector,
            CollectionIcon,
            "Order for collection",
            "Collection"
          )}

        {canDeliver &&
          this.renderIcon(
            this.setDeliveryOrder,
            orderType === DELIVERY_ORDER
              ? cssStyles.imgSelectorSelected
              : cssStyles.imgSelector,
            DeliveryIcon,
            "Order for delivery",
            "Delivery"
          )}
      </Grid>
    );
  };

  renderIcon = (cta, style, Icon, imgAlt, titleText) => {
    return (
      <Grid
        className={cssStyles.collectionDeliveryGridItem}
        onClick={cta}
        m={2}
        data-testid={`${titleText.toLowerCase()}-icon`}
      >
        <div className={style}>
          <Icon />
        </div>
        <Typography
          variant="subtitle1"
          className={cssStyles.collectionDeliverySubtitle}
          gutterBottom
        >
          {titleText}
        </Typography>
      </Grid>
    );
  };

  renderClosedMessage = (outsideOfBusinessHours) => {
    if (outsideOfBusinessHours) {
      return (
        <Container
          sx={{
            marginTop: 2,
          }}
        >
          <Typography variant="body1" paragraph data-testid="closed-message">
            We're currently closed and not accepting orders in advance.
          </Typography>
          <Typography variant="body1" paragraph>
            Please check our <Link href="/hours">Opening Hours</Link> to see
            when we're next open.
          </Typography>
        </Container>
      );
    } else {
      return (
        <Typography
          variant="body1"
          paragraph
          data-testid="closed-message"
          sx={{
            marginTop: 2,
          }}
        >
          We're not accepting orders at the moment, but please check back soon.
        </Typography>
      );
    }
  };

  renderLocation = (locationName) => {
    return (
      <>
        <Container
          data-testid="location"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LocationIcon sx={{ mr: 1 }} />
          <Typography variant="body1">{locationName}</Typography>
        </Container>
        <Link
          href="/location"
          sx={{
            display: "block",
            color: "secondary.main",
            "&:hover": {
              color: "secondary.main",
            },
            "&:visited": {
              color: "secondary.main",
            },
          }}
        >
          Change
        </Link>
      </>
    );
  };

  render() {
    const {
      canDeliver,
      canDeliverToday,
      acceptsDeliveryOrdersNow,
      allowsCollection,
      allowsCollectionToday,
      acceptsCollectionOrdersNow,
      orderType,
      deliveryPostcode,
      deliveryPostcodeInvalid,
      postcodeHelperText,
    } = this.state;
    const { checkingDeliveryPostcode, location, multiLocation } = this.props;
    const outsideOfBusinessHours = !merchantLocationShouldBeOpen(location);
    const canOrderOnline =
      acceptsDeliveryOrdersNow || acceptsCollectionOrdersNow;

    // console.log("canDeliver", canDeliver);
    // console.log("canDeliverToday", canDeliverToday);
    // console.log("acceptsDeliveryOrdersNow", acceptsDeliveryOrdersNow);
    // console.log("allowsCollection", allowsCollection);
    // console.log("allowsCollectionToday", allowsCollectionToday);
    // console.log("acceptsCollectionOrdersNow", acceptsCollectionOrdersNow);

    if (canOrderOnline) {
      return (
        <>
          <Typography variant="h6" gutterBottom>
            Order Online
          </Typography>

          {multiLocation && this.renderLocation(location.name)}

          {this.renderIcons(orderType, allowsCollection, canDeliver)}

          {orderType === COLLECTION_ORDER && acceptsCollectionOrdersNow && (
            <Button
              id="collection-order-button"
              variant="contained"
              color="primary"
              onClick={this.startCollectionOrder}
              sx={{ marginTop: 1 }}
            >
              Start Collection Order
            </Button>
          )}

          {this.renderCollectionMessage(
            orderType,
            acceptsCollectionOrdersNow,
            allowsCollectionToday
          )}

          {orderType === DELIVERY_ORDER && (
            <div>
              {acceptsDeliveryOrdersNow && (
                <form noValidate onSubmit={this.startDeliveryOrder}>
                  <Box
                    my={1}
                    sx={{
                      maxWidth: 200,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <TextField
                      error={deliveryPostcodeInvalid}
                      id="outlined-name"
                      label="Enter your delivery postcode"
                      value={deliveryPostcode}
                      onChange={this.handleDeliveryPostcodeChange}
                      variant="outlined"
                      autoComplete="postal-code"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={postcodeHelperText}
                    />
                  </Box>

                  <div className={cssStyles.deliveryButtonWrapper}>
                    <Button
                      id="delivery-order-button"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={checkingDeliveryPostcode}
                      sx={{ marginTop: 1 }}
                    >
                      Start Delivery Order
                    </Button>
                    {checkingDeliveryPostcode && (
                      <CircularProgress
                        size={24}
                        className={cssStyles.deliveryButtonProgress}
                      />
                    )}
                  </div>
                </form>
              )}

              {this.renderDeliveryMessage(
                orderType,
                acceptsDeliveryOrdersNow,
                canDeliverToday
              )}
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          <Typography variant="h6" gutterBottom>
            Order Online
          </Typography>

          {multiLocation && this.renderLocation(location.name)}

          <Container
            sx={{
              px: 2,
            }}
          >
            {this.renderClosedMessage(outsideOfBusinessHours)}

            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: 1 }}
              onClick={this.showMenu}
            >
              View Menu
            </Button>
          </Container>
        </>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    getOrder: getOrder(state),
    canDeliverToPostcode: canDeliverToPostcode(state),
    getDeliveryPostcode: getDeliveryPostcode(state),
    getDistanceToPostcode: getDistanceToPostcode(state),
    getDeliveryLocationId: getDeliveryLocationId(state),
    checkingDeliveryPostcode: checkingDeliveryPostcode(state),
    checkingDeliveryPostcodeSucceeded: checkingDeliveryPostcodeSucceeded(state),
  };
}

CollectionDeliverySelector.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  multiLocation: PropTypes.bool.isRequired,
  getOrder: PropTypes.object.isRequired,
  startCollectionOrder: PropTypes.func.isRequired,
  startDeliveryOrder: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  showMenu: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  canDeliverToPostcode: PropTypes.bool.isRequired,
  getDeliveryPostcode: PropTypes.string,
  getDistanceToPostcode: PropTypes.number,
  getDeliveryLocationId: PropTypes.number,
  checkingDeliveryPostcode: PropTypes.bool.isRequired,
  checkingDeliveryPostcodeSucceeded: PropTypes.bool.isRequired,
  cookies: instanceOf(Cookies).isRequired,
};

export default connect(mapStateToProps)(
  withCookies(CollectionDeliverySelector)
);
