import React, { Component } from "react";
import PropTypes from "prop-types";

import ChoiceLabel from "./ChoiceLabel";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  StyledFormGroup,
  StyledFormControl,
  StyledFormLabel,
} from "./StyledComponents";

export class MenuItemExtras extends Component {
  constructor(props) {
    super(props);

    const extrasChoices = [];
    this.state = {
      extrasChoices,
    };

    this.extrasChoicesUpdated(extrasChoices);
  }

  extraIsSelected = (id) => {
    const result = this.state.extrasChoices.includes(id);
    return result;
  };

  extrasChanged = (name) => (event) => {
    const selectedChoiceId = parseInt(name);
    var newChoices = this.state.extrasChoices;

    const index = this.state.extrasChoices.indexOf(selectedChoiceId);
    if (index > -1) {
      if (!event.target.checked) {
        newChoices.splice(index, 1);
        this.setState({ extrasChoices: newChoices });
      }
    } else {
      if (event.target.checked) {
        newChoices.push(selectedChoiceId);
        this.setState({ extrasChoices: newChoices });
      }
    }

    this.extrasChoicesUpdated(newChoices);
  };

  extrasChoicesUpdated = (extrasChoices) => {
    const { extras } = this.props;
    const extrasChoiceDetails = Object.values(extrasChoices).reduce(function (
      map,
      extraChoiceId
    ) {
      for (const extra of extras) {
        for (const choice of extra.active_menu_item_extra_choices) {
          if (choice.id === extraChoiceId) {
            map.push({
              id: extraChoiceId,
              name: choice.name,
              price: choice.price,
            });
            break;
          }
        }
      }

      return map;
    },
    []);

    this.props.extrasChoicesUpdated(extrasChoiceDetails);
  };

  renderExtrasChoices = (extra) => {
    if (extra.active_menu_item_extra_choices.length > 0) {
      return (
        <StyledFormGroup>
          {extra.active_menu_item_extra_choices.map((choice) => (
            <FormControlLabel
              key={choice.id}
              control={
                <Checkbox
                  checked={this.extraIsSelected(choice.id)}
                  onChange={this.extrasChanged(choice.id.toString())}
                  value={choice.id.toString()}
                  name={choice.name}
                />
              }
              label={<ChoiceLabel name={choice.name} price={choice.price} />}
            />
          ))}
        </StyledFormGroup>
      );
    }

    return null;
  };

  render() {
    const { extras } = this.props;

    if (extras.length > 0) {
      return (
        <>
          {extras
            .filter((extra) => extra.active_menu_item_extra_choices.length > 0)
            .map((extra) => (
              <StyledFormControl component="fieldset" key={extra.id}>
                <StyledFormLabel component="legend">
                  {extra.name}
                </StyledFormLabel>

                {this.renderExtrasChoices(extra)}
              </StyledFormControl>
            ))}
        </>
      );
    }

    return null;
  }
}

MenuItemExtras.propTypes = {
  extras: PropTypes.array.isRequired,
  extrasChoicesUpdated: PropTypes.func.isRequired,
};

export default MenuItemExtras;
