/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { Component } from "react";
import PropTypes from "prop-types";

import { withTheme } from "@mui/styles";
import cssStyles from "./SegmentedControl.module.scss";

class SegmentedControl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSegment: 0,
    };

    this.renderSegments = this.renderSegments.bind(this);
  }

  static defaultProps = {
    onChangeSegment: (x) => x,
    selected: 0,
  };

  componentDidMount() {
    this.setState({ selectedSegment: this.props.selected });
  }

  onChange = (selectedSegment) => {
    this.setState({ selectedSegment });
    this.props.onChangeSegment(selectedSegment);
  };

  renderSegments = () => {
    return this.props.segments.map((segment, i) => {
      if (i === this.state.selectedSegment) {
        return (
          <li
            key={i}
            className={cssStyles.selected}
            style={{ backgroundColor: this.props.theme.palette.primary.main }}
            data-testid="seg-option-1"
          >
            {segment.name}
          </li>
        );
      } else {
        return (
          <li
            key={i}
            onClick={() => this.onChange(i)}
            style={{ color: this.props.theme.palette.primary.main }}
            data-testid="seg-option-2"
          >
            {segment.name}
          </li>
        );
      }
    });
  };

  render() {
    return (
      <div
        className={cssStyles.segmentedControl}
        data-testid="segmented-control"
      >
        <ul>{this.renderSegments()}</ul>
      </div>
    );
  }
}

SegmentedControl.propTypes = {
  segments: PropTypes.array.isRequired,
  selected: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
  onChangeSegment: PropTypes.func,
};

export default withTheme(SegmentedControl);
