import {
  INIT_CART,
  ADD_ITEM,
  REMOVE_ITEM,
  INCREMENT_ITEM_QTY,
  DECREMENT_ITEM_QTY,
  REMOVE_ALL_ITEMS,
  APPLY_PROMOTIONS,
} from "./cartConstants";
import getApplicablePromotions from "../utils/getApplicablePromotions.mjs";
import { isCollectionOrder } from "../utils/order";

const initialState = {
  lastUpdate: 0,
  collectionOrder: true,
  locationId: null,
  items: {},
  discounts: [],
  orderCharges: [],
  deliveryFee: 0.0,
  variableDeliveryFees: null,
  applyDeliveryFee: true,
  minDeliveryOrder: 0.0,
  minCollectionOrder: 0.0,
  promotionPromptMessage: null,
};

const parseVariableDeliveryFees = (variableDeliveryFees) => {
  if (
    !Array.isArray(variableDeliveryFees) ||
    variableDeliveryFees.length === 0 ||
    !(
      (variableDeliveryFees[0].hasOwnProperty("orderValue") ||
        variableDeliveryFees[0].hasOwnProperty("distance")) &&
      variableDeliveryFees[0].hasOwnProperty("price")
    )
  ) {
    return null;
  } else {
    if (variableDeliveryFees[0].hasOwnProperty("orderValue")) {
      return variableDeliveryFees.sort((a, b) => a.orderValue > b.orderValue);
    } else if (variableDeliveryFees[0].hasOwnProperty("distance")) {
      return variableDeliveryFees.sort((a, b) => a.distance > b.distance);
    } else {
      return null;
    }
  }
};

const cart = (state = initialState, action) => {
  var item;
  const lastUpdate = (Date.now() / 1000) | 0;

  switch (action.type) {
    case INIT_CART: {
      const { location, order, fees } = action;
      const collectionOrder = isCollectionOrder(order);
      const deliveryFee = collectionOrder
        ? 0.0
        : parseFloat(location.delivery_price);
      const minDeliveryOrder =
        location.minimum_delivery_order_value === null
          ? null
          : parseFloat(location.minimum_delivery_order_value);
      const minCollectionOrder =
        location.minimum_collection_order_value === null
          ? null
          : parseFloat(location.minimum_collection_order_value);

      const orderCharges = [];
      if (fees.order_supplemental_charges !== null) {
        for (const charge of fees.order_supplemental_charges) {
          orderCharges.push(charge);
        }
      }

      return {
        ...state,
        lastUpdate,
        collectionOrder,
        locationId: location.id,
        orderCharges,
        deliveryFee,
        variableDeliveryFees: location.has_variable_delivery_price
          ? parseVariableDeliveryFees(location.variable_delivery_prices)
          : null,
        applyDeliveryFee: true,
        minDeliveryOrder,
        minCollectionOrder,
        promotionPromptMessage: null,
      };
    }
    case ADD_ITEM: {
      const {
        qty,
        id,
        categoryId,
        name,
        price,
        options,
        extras,
        excludedFromOffers,
      } = action.item;
      const nextId = Object.keys(state.items).length.toString();
      const updatedCartItems = Object.assign({}, state.items);

      var quantityUpdated = false;

      for (const key in state.items) {
        item = Object.assign({}, state.items[key]);

        if (item.id === id) {
          // Already have same/similar item in the cart
          if (!item.options && !item.extras) {
            // This cart item does not have options or extras
            if (null === options && null === extras) {
              // Neither does the incoming item so just update the quantity
              item.qty += qty;
              updatedCartItems[key] = item;
              quantityUpdated = true;
              break;
            }
          } else if (item.options && !item.extras) {
            // This cart item has options but no extras
            if (null !== options && null === extras) {
              // The incoming item also has options but no extras

              // Check if the options are the same
              if (sameItemChoices(item.options, options)) {
                item.qty += qty;
                updatedCartItems[key] = item;
                quantityUpdated = true;
                break;
              }
            }
          } else if (item.extras && !item.options) {
            // This cart item has extras but no options
            if (null !== extras && null === options) {
              // The incoming item also has extras but no options

              // Check if the extras are the same
              if (sameItemChoices(item.extras, extras)) {
                item.qty += qty;
                updatedCartItems[key] = item;
                quantityUpdated = true;
                break;
              }
            }
          } else if (item.extras && item.options) {
            // This cart item has options and extras

            if (null !== options && null !== extras) {
              // The incoming item also has extras and options

              // Check if the options and extras are the same
              if (
                sameItemChoices(item.options, options) &&
                sameItemChoices(item.extras, extras)
              ) {
                item.qty += qty;
                updatedCartItems[key] = item;
                quantityUpdated = true;
                break;
              }
            }
          }
        }
      }

      if (!quantityUpdated) {
        //console.log('Add new item. Index: ' + nextId);
        var newItem = {
          id,
          categoryId,
          name,
          price,
          qty,
          options,
          extras,
          excludedFromOffers,
        };
        updatedCartItems[nextId] = newItem;
      }

      return {
        ...state,
        items: updatedCartItems,
        lastUpdate,
      };
    }
    case REMOVE_ITEM: {
      const { cartItemId } = action;

      if (state.items[cartItemId]) {
        const cartItemsCopy = Object.assign({}, state.items);
        delete cartItemsCopy[cartItemId];

        var updatedCartItems = {};
        var index = 0;
        for (const key in cartItemsCopy) {
          updatedCartItems[index.toString()] = cartItemsCopy[key];
          index++;
        }

        return {
          ...state,
          items: updatedCartItems,
          lastUpdate,
        };
      } else {
        // something has gone wrong so empty the cart
        return {
          ...state,
          items: {},
          discounts: [],
          lastUpdate,
        };
      }
    }
    case INCREMENT_ITEM_QTY: {
      const { cartItemId } = action;

      if (state.items[cartItemId]) {
        const updatedCartItems = Object.assign({}, state.items);
        item = Object.assign({}, state.items[cartItemId]);

        item.qty += 1;
        updatedCartItems[cartItemId] = item;
        return {
          ...state,
          items: updatedCartItems,
          lastUpdate,
        };
      } else {
        return state;
      }
    }
    case DECREMENT_ITEM_QTY: {
      const { cartItemId } = action;

      if (state.items[cartItemId]) {
        const updatedCartItems = Object.assign({}, state.items);
        item = Object.assign({}, state.items[cartItemId]);

        item.qty -= 1;
        if (item.qty === 0) {
          delete updatedCartItems[cartItemId];
        } else {
          updatedCartItems[cartItemId] = item;
        }
        return {
          ...state,
          items: updatedCartItems,
          lastUpdate,
        };
      } else {
        return state;
      }
    }
    case REMOVE_ALL_ITEMS:
      return {
        ...state,
        items: {},
        discounts: [],
        lastUpdate,
      };
    case APPLY_PROMOTIONS: {
      const { promotions } = action;

      const applicablePromotions = getApplicablePromotions(
        state.items,
        promotions,
        state.minDeliveryOrder,
        getItemTotalEligibleForDiscounts(state.items),
        state.collectionOrder
      );
      return {
        ...state,
        discounts: applicablePromotions.discounts,
        applyDeliveryFee: applicablePromotions.applyDeliveryFee,
        promotionPromptMessage: applicablePromotions.promotionPromptMessage,
        lastUpdate,
      };
    }
    default:
      return state;
  }
};

export default cart;

const sameItemChoices = (existingChoices, newChoices) => {
  return (
    JSON.stringify(existingChoices.sort((a, b) => a - b)) ===
    JSON.stringify(newChoices.sort((a, b) => a - b))
  );
};

const getOrderValueDeliveryFees = (variableDeliveryFees) => {
  var orderValueDeliveryFees = [];

  if (variableDeliveryFees) {
    for (var fee of variableDeliveryFees) {
      if (fee.hasOwnProperty("orderValue") && fee.hasOwnProperty("price")) {
        orderValueDeliveryFees.push(fee);
      }
    }
  }

  return orderValueDeliveryFees.sort((a, b) => b.orderValue - a.orderValue);
};

const getDistanceDeliveryFees = (variableDeliveryFees) => {
  var distanceDeliveryFees = [];

  if (variableDeliveryFees) {
    for (var fee of variableDeliveryFees) {
      if (fee.hasOwnProperty("distance") && fee.hasOwnProperty("price")) {
        distanceDeliveryFees.push(fee);
      }
    }
  }

  return distanceDeliveryFees.sort((a, b) => b.distance - a.distance);
};

const getItemTotal = (items) => {
  var itemTotal = 0.0;

  Object.keys(items).forEach(function (key) {
    const item = items[key];
    itemTotal += item.price * item.qty;
  });

  return itemTotal;
};

const getItemTotalEligibleForDiscounts = (items) => {
  var itemTotal = 0.0;

  Object.keys(items).forEach(function (key) {
    const item = items[key];
    if (!item.excludedFromOffers) {
      itemTotal += item.price * item.qty;
    }
  });

  return itemTotal;
};

// SELECTORS
// ================================================
export const getCartDetails = (state) => {
  return state.cart;
};

export const getCartItems = (state) => {
  return state.cart.items;
};

export const getOrderCharges = (state) => {
  return state.cart.orderCharges;
};

export const getCartDeliveryFee = (state) => {
  const {
    collectionOrder,
    applyDeliveryFee,
    deliveryFee,
    variableDeliveryFees,
  } = state.cart;
  const { deliveryAddress } = state.order;
  var result = 0;

  if (!collectionOrder && applyDeliveryFee) {
    result = deliveryFee;

    if (variableDeliveryFees !== null) {
      const itemTotal = getItemTotal(state.cart.items);
      var orderValueDeliveryFees =
        getOrderValueDeliveryFees(variableDeliveryFees);
      var orderDistanceDeliveryFees =
        getDistanceDeliveryFees(variableDeliveryFees);

      if (orderValueDeliveryFees.length > 0) {
        var orderValueDeliveryFee = deliveryFee;

        for (var orderValueFee of orderValueDeliveryFees) {
          if (
            itemTotal < orderValueFee.orderValue &&
            orderValueFee.price > orderValueDeliveryFee
          ) {
            orderValueDeliveryFee = orderValueFee.price;
          }
        }

        result = orderValueDeliveryFee;
      } else if (
        orderDistanceDeliveryFees.length > 0 &&
        deliveryAddress.distanceToPostcode > 0
      ) {
        var distanceDeliveryFee = deliveryFee;

        for (var distanceFee of orderDistanceDeliveryFees) {
          if (
            deliveryAddress.distanceToPostcode < distanceFee.distance &&
            distanceFee.price < distanceDeliveryFee
          ) {
            distanceDeliveryFee = distanceFee.price;
          }
        }

        result = distanceDeliveryFee;
      }
    }
  }

  return result;
};

export const getCartItemsTotal = (state) => {
  var itemTotal = 0.0;

  state.cart.items.forEach(function (item) {
    itemTotal += Number((item.price * item.qty).toFixed(2));
  });

  return itemTotal.toFixed(2);
};

export const getCartTotal = (state) => {
  const { discounts, orderCharges } = state.cart;
  var itemTotal = getItemTotal(state.cart.items);
  var discountTotal = 0.0;
  var deliveryFee = Number(getCartDeliveryFee(state));

  discounts.forEach(function (discount) {
    discountTotal += discount.discount;
  });

  if (deliveryFee > 0) {
    itemTotal += deliveryFee;
  }

  for (const charge of orderCharges) {
    itemTotal += Number(charge.amount);
  }

  return (itemTotal - discountTotal).toFixed(2);
};

export const getCartItemsCount = (state) => {
  var cartItemsCount = 0;

  Object.keys(state.cart.items).forEach(function (key) {
    const item = state.cart.items[key];
    cartItemsCount += item.qty;
  });

  return cartItemsCount;
};
