import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isLength from "validator/lib/isLength";
import { normalizePostcode } from "../../utils/postcode";
import { getOrder } from "../../reducers";
import MyOrdersDialog from "../Dialog/MyOrdersDialog";
import Script from "react-load-script";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";

import { MAX_TEXT_FIELD_INPUT_LENGTH } from "../../config";

const EARTH_RADIUS = 6371000.0;
const PREFIX = "DeliveryAddressForm";

const classes = {
  container: `${PREFIX}-container`,
  formControl: `${PREFIX}-formControl`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },

  [`& .${classes.formControl}`]: {
    marginBottom: theme.spacing(2),
  },
}));

const postcodeMismatchMessage = () => {
  return (
    <>
      <Typography variant="body1" paragraph>
        The postcode of the address that you entered does not match the postcode
        that you provided earlier.
      </Typography>
      <Typography variant="body1" paragraph>
        If you require delivery to a different postcode please start a new
        order.
      </Typography>
    </>
  );
};

const capitalize = (text) => {
  return text
    ? text.replace(
        /(^\w|\s\w)(\S*)/g,
        (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
      )
    : null;
};

function DeliveryAddressForm(props) {
  const [deliveryAddress, setDeliveryAddress] = useState({
    line1: props.previousDeliveryAddress?.line1 || "",
    line2: props.previousDeliveryAddress?.line2 || "",
    city: props.previousDeliveryAddress?.city || "",
  });
  const [addressValidationErrors, setAddressValidationErrors] = useState({
    line1: false,
    city: false,
    postcode: false,
  });

  const [submitting, setSubmitting] = useState(false);

  var autocomplete;

  const autocompleteSearchBounds = () => {
    const {
      latitude,
      longitude,
      delivery_radius: deliveryRadius,
    } = props.locationDetails;
    const deltaLatitude = (deliveryRadius / EARTH_RADIUS) * (180 / Math.PI);
    const deltaLongitude =
      ((deliveryRadius / EARTH_RADIUS) * (180 / Math.PI)) /
      Math.cos((latitude * Math.PI) / 180);

    return new google.maps.LatLngBounds(
      new google.maps.LatLng(
        latitude - deltaLatitude,
        longitude - deltaLongitude
      ),
      new google.maps.LatLng(
        latitude + deltaLatitude,
        longitude + deltaLongitude
      )
    );
  };

  useEffect(() => {
    const validateAddress = () => {
      if (
        !isLength(deliveryAddress.line1, {
          min: 1,
          max: MAX_TEXT_FIELD_INPUT_LENGTH,
        })
      ) {
        setAddressValidationErrors({
          ...addressValidationErrors,
          line1: deliveryAddress.line1 !== "",
          city: false,
        });
        props.setDeliveryAddress(null);
      } else if (
        !isLength(deliveryAddress.city, {
          min: 1,
          max: MAX_TEXT_FIELD_INPUT_LENGTH,
        })
      ) {
        setAddressValidationErrors({
          ...addressValidationErrors,
          line1: false,
          city: true,
        });
        props.setDeliveryAddress(null);
      } else {
        setAddressValidationErrors({
          ...addressValidationErrors,
          line1: false,
          city: false,
        });

        props.setDeliveryAddress({
          line1: capitalize(deliveryAddress.line1),
          line2: capitalize(deliveryAddress.line2),
          city: capitalize(deliveryAddress.city),
          postcode: props.postcode,
        });

        setSubmitting(false);
      }
    };

    if (props.submit && !submitting) {
      setSubmitting(true);
      validateAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.submit, submitting]);

  const handleScriptLoad = () => {
    const options = {
      types: ["geocode"],
      componentRestrictions: { country: "gb" },
      bounds: autocompleteSearchBounds(),
      strictBounds: true,
    };

    // To disable any eslint 'google not defined' errors
    /*global google*/
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("addressLine1-input"),
      options
    );

    // Avoid paying for data that you don't need by restricting the
    // set of place fields that are returned to just the address
    // components and formatted address
    autocomplete.setFields(["address_components", "formatted_address"]);
    autocomplete.addListener("place_changed", handlePlaceSelect);
  };

  const handlePlaceSelect = () => {
    const addressObject = autocomplete.getPlace();
    const address = addressObject.address_components;

    // console.log("address", addressObject.formatted_address);

    if (address) {
      var addressComponents = {};
      address.forEach((element1) => {
        element1.types.forEach((element2) => {
          addressComponents[element2] = element1.long_name;
        });
      });

      // console.log("addressComponents", addressComponents);

      if (
        !normalizePostcode(addressComponents.postal_code) ||
        normalizePostcode(addressComponents.postal_code) ===
          normalizePostcode(props.postcode)
      ) {
        setDeliveryAddress({
          ...deliveryAddress,
          line1: `${addressComponents.street_number} ${addressComponents.route}`,
          line2: addressComponents.neighborhood
            ? addressComponents.neighborhood
            : "",
          city: addressComponents.postal_town,
        });
      } else {
        setAddressValidationErrors({
          ...addressValidationErrors,
          postcode: true,
        });
      }
    }
  };

  const closePostcodeMismatchDialog = () => {
    setAddressValidationErrors({ ...addressValidationErrors, postcode: false });
  };

  return (
    <Root>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=Function.prototype`}
        onLoad={handleScriptLoad}
      />

      <form
        noValidate
        autoComplete="off"
        onSubmit={props.onSubmit}
        className={classes.container}
      >
        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            value={deliveryAddress.line1}
            fullWidth
            placeholder="Address line 1"
            id="addressLine1-input"
            label="Address line 1"
            type="search"
            autoComplete="street-address"
            onChange={(e) =>
              setDeliveryAddress({ ...deliveryAddress, line1: e.target.value })
            }
            error={addressValidationErrors.line1}
            inputProps={{
              maxLength: MAX_TEXT_FIELD_INPUT_LENGTH,
            }}
          />
          {addressValidationErrors.line1 && (
            <FormHelperText error={true}>
              Please enter the first line of your address.
            </FormHelperText>
          )}
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            value={deliveryAddress.line2}
            fullWidth
            id="addressLine2-input"
            label="Address line 2 (optional)"
            type="text"
            autoComplete="address-line2"
            onChange={(e) =>
              setDeliveryAddress({ ...deliveryAddress, line2: e.target.value })
            }
            inputProps={{
              maxLength: MAX_TEXT_FIELD_INPUT_LENGTH,
            }}
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            value={deliveryAddress.city}
            fullWidth
            id="addressCity-input"
            label="City"
            type="text"
            autoComplete="address-level2"
            onChange={(e) =>
              setDeliveryAddress({ ...deliveryAddress, city: e.target.value })
            }
            error={addressValidationErrors.city}
            inputProps={{
              maxLength: MAX_TEXT_FIELD_INPUT_LENGTH,
            }}
          />
          {addressValidationErrors.city && (
            <FormHelperText error={true}>
              Please enter your city.
            </FormHelperText>
          )}
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            value={props.postcode}
            fullWidth
            id="addressPostcode-input"
            label="Postcode"
            type="text"
            disabled
            tabIndex={-1}
          />
        </FormControl>
      </form>

      <MyOrdersDialog
        dialogOpen={addressValidationErrors.postcode}
        title="New Postcode for Delivery?"
        body={postcodeMismatchMessage()}
        buttonText="OK"
        onClose={closePostcodeMismatchDialog}
        disableBackdropClick={true}
      />
    </Root>
  );
}

function mapStateToProps(state) {
  return {
    getOrder: getOrder(state),
  };
}

DeliveryAddressForm.propTypes = {
  submit: PropTypes.bool.isRequired,
  postcode: PropTypes.string.isRequired,
  setDeliveryAddress: PropTypes.func.isRequired,
  previousDeliveryAddress: PropTypes.object,
  getOrder: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(DeliveryAddressForm);
