import {
  INIT_CHECKOUT,
  START_CHECKOUT_REQUEST,
  START_CHECKOUT_SUCCESS,
  START_CHECKOUT_FAILURE,
  // GET_PAYMENT_TOKEN_STATUS_REQUEST,
  // GET_PAYMENT_TOKEN_STATUS_SUCCESS,
  // GET_PAYMENT_TOKEN_STATUS_FAILURE,
} from "./checkoutConstants";

const initialState = {
  isRequestingCheckout: false,
  checkoutRequestSucceeded: false,
  checkoutRequestFailed: false,
  checkoutRequestFailureErrorCode: null,
  checkoutPaymentToken: null,
  // isGettingPaymentTokenStatus: false,
  // getPaymentTokenStatusSucceeded: false,
  // getPaymentTokenStatusFailed: false,
  // paymentTokenStatus: null,
};

const checkout = (state = initialState, action) => {
  switch (action.type) {
    case INIT_CHECKOUT:
      return initialState;
    case START_CHECKOUT_REQUEST:
      return {
        ...state,
        isRequestingCheckout: true,
        checkoutRequestSucceeded: false,
        checkoutRequestFailed: false,
        checkoutRequestFailureErrorCode: null,
        checkoutPaymentToken: null,
      };
    case START_CHECKOUT_SUCCESS:
      return {
        ...state,
        isRequestingCheckout: false,
        checkoutRequestSucceeded: true,
        checkoutRequestFailed: false,
        checkoutRequestFailureErrorCode: null,
        checkoutPaymentToken: action.payload.paymentToken,
      };
    case START_CHECKOUT_FAILURE:
      return {
        ...state,
        isRequestingCheckout: false,
        checkoutRequestSucceeded: false,
        checkoutRequestFailed: true,
        checkoutRequestFailureErrorCode: action.payload.errorCode,
        checkoutPaymentToken: null,
      };
    // case GET_PAYMENT_TOKEN_STATUS_REQUEST:
    //   return {
    //     ...state,
    //     isGettingPaymentTokenStatus: true,
    //     getPaymentTokenStatusSucceeded: false,
    //     getPaymentTokenStatusFailed: false,
    //     paymentTokenStatus: null,
    //   };
    // case GET_PAYMENT_TOKEN_STATUS_SUCCESS:
    //   return {
    //     ...state,
    //     isGettingPaymentTokenStatus: false,
    //     getPaymentTokenStatusSucceeded: true,
    //     getPaymentTokenStatusFailed: false,
    //     paymentTokenStatus: action.payload.status,
    //   };
    // case GET_PAYMENT_TOKEN_STATUS_FAILURE:
    //   return {
    //     ...state,
    //     isGettingPaymentTokenStatus: false,
    //     getPaymentTokenStatusSucceeded: false,
    //     getPaymentTokenStatusFailed: true,
    //     paymentTokenStatus: null,
    //   };
    default:
      return state;
  }
};

export default checkout;

// SELECTORS
// ================================================
export const isRequestingCheckout = (state) => {
  return state.checkout.isRequestingCheckout;
};

export const checkoutRequestSucceeded = (state) => {
  return state.checkout.checkoutRequestSucceeded;
};

export const checkoutRequestFailed = (state) => {
  return state.checkout.checkoutRequestFailed;
};

export const checkoutRequestFailureErrorCode = (state) => {
  return state.checkout.checkoutRequestFailureErrorCode;
};

export const getCheckoutPaymentToken = (state) => {
  return state.checkout.checkoutPaymentToken;
};
