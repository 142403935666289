import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getLoginDetails } from "../../reducers";
import { postLogoutRequest } from "../../actions/loginActions";

import Menu from "@mui/material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const PREFIX = "AccountMenu";

const classes = {
  menuPopover: `${PREFIX}-menuPopover`,
  signInLink: `${PREFIX}-signInLink`,
};

const StyledLink = styled(Link)(({ theme }) => ({
  [`& .${classes.menuPopover}`]: {
    top: "65px !important",
  },

  [`&.${classes.signInLink}`]: {
    fontWeight: 400,
    color: theme.palette.primary.main,
    "&:hover": {
      background: "transparent",
      color: theme.palette.primary.main,
    },
    "&:visited": {
      color: theme.palette.primary.main,
    },
  },
}));

export class AccountMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  showAccountMenu = (event) => {
    if (null == this.state.anchorEl) {
      this.setState({ anchorEl: event.currentTarget });
    }
  };

  hideAccountMenu = () => {
    this.setState({ anchorEl: null });
  };

  logout = () => {
    this.hideAccountMenu();
    this.props.dispatch(postLogoutRequest());
  };

  renderMenu = () => {
    const { anchorEl } = this.state;
    const user = this.props.getLoginDetails;

    if (user.isAuthenticated) {
      return (
        <div
          onClick={this.showAccountMenu}
          onKeyPress={this.showAccountMenu}
          role="button"
          tabIndex="0"
          aria-owns={anchorEl ? "simple-menu" : null}
          aria-haspopup="true"
        >
          <div>
            My Account
            <span style={{ marginLeft: 3 }}>⌄</span>
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.hideAccountMenu}
            PopoverClasses={{ paper: classes.menuPopover }}
          >
            <ListItem
              button
              selected={this.state.selectedIndex === 0}
              onClick={this.logout}
            >
              <ListItemText>Log Out</ListItemText>
            </ListItem>
          </Menu>
        </div>
      );
    } else {
      return (
        <StyledLink
          id="header-log-in-link"
          to={{
            pathname: "/login",
            state: {
              completionUrl: this.props.loginCompletionUrl,
            },
          }}
          className={classes.signInLink}
        >
          Log In
        </StyledLink>
      );
    }
  };

  render() {
    return this.renderMenu();
  }
}

function mapStateToProps(state) {
  return {
    getLoginDetails: getLoginDetails(state),
  };
}

AccountMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getLoginDetails: PropTypes.object.isRequired,
  loginCompletionUrl: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(AccountMenu);
