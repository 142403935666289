import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from '../../utils/currency';

const styles = {
  price: {
    color: 'grey',
    marginLeft: 10,
  },
};

const ChoiceLabel = (props) => {
  const { name, price } = props;

  return (
    <div>
      <span>{name}</span>
      <span style={styles.price}>{`+${formatMoney(price)}`}</span>
    </div>
  );
};

ChoiceLabel.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
};

export default ChoiceLabel;
