import React, { useState } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import { Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  getLoginDetails,
  getCartDetails,
  getMerchantDetails,
  getOrder,
  getCartTotal,
  getCartDeliveryFee,
  getCheckoutPaymentToken,
  getDeliveryAddressForOrderSubmission,
} from "../../reducers";
import { SUBMIT_ORDER_FAILURE_CLOSED } from "../../reducers/checkoutConstants";
import { postSubmitOrderRequest } from "../../actions/checkoutActions";
import { setOrderReference } from "../../actions/orderActions";
import { removeAllItemsFromCart } from "../../actions/cartActions";
import {
  getMerchantLocation,
  getMerchantPhoneNumber,
} from "../../utils/merchant";
import {
  isCollectionOrder,
  isDeliveryOrder,
  isBeatTheQueueOrder,
} from "../../utils/order";
import { getCurrentBeatTheQueueTime } from "../../utils/fulfilment";
import { currentOrderIsValid } from "../../utils/currentOrderIsValid";

import { getCdnImageUrl } from "../../utils/assetUtils";
import {
  orderEligibleForOnlinePayment,
  orderRequiresOnlinePayment,
  getMerchantPaymentProvider,
} from "../../utils/merchant";
import Header from "../Header/Header";
import Spinner from "../Spinner/Spinner";
import MyOrdersDialog from "../Dialog/MyOrdersDialog";
import OrderDetails from "./OrderDetails";
import PaymentProvider from "../Payment/PaymentProvider";
//import BillingNameForm from "./BillingNameForm";
//import BillingAddressForm from "./BillingAddressForm";
import { scroller } from "react-scroll";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import {
  StyledBreadcrumbs,
  StyledBreadcrumbLink,
  StyledPaper,
  StyledRadioGroupFormControl,
  StyledRadioGroupFormControlLabel,
  StyledPaymentFormContainer,
} from "./StyledComponents";
import cssStyles from "./Checkout.module.scss";

import { PAYMENT_TYPE_CASH } from "../Payment/paymentConstants";

import { v4 as uuidv4 } from "uuid";

// export const StyledBillingAddressFormContainer = styled("div")({
//   padding: theme.spacing(2, 2, 1, 2),
// });

const APP_PLATFORM = "Web";
const PAY_NOW = "payNow";
const PAY_LATER = "payLater";

// const PAYMENT_REVERSAL_MESSAGE = "If an authorisation was completed against your payment card it will be reversed shortly.";

const merchantClosedMessage = (merchantName, paymentTime) => {
  var result = [];
  var key = 0;

  result.push(
    <Typography
      variant="body1"
      paragraph
      key={key++}
    >{`${merchantName} is now closed.`}</Typography>
  );

  // if (paymentTime === PAY_NOW) {
  //   result.push(
  //     <Typography variant="body1" paragraph key={key++}>
  //       {PAYMENT_REVERSAL_MESSAGE}
  //     </Typography>
  //   );
  // }

  return <>{result}</>;
};

const orderFailureMessage = (merchant, paymentTime) => {
  var result = [];
  var key = 0;

  result.push(
    <Typography variant="body1" paragraph key={key++}>{`Please call ${
      merchant.name
    } on ${getMerchantPhoneNumber(
      merchant
    )} and place your order over the phone.`}</Typography>
  );

  // if (paymentTime === PAY_NOW) {
  //   result.push(
  //     <Typography variant="body1" paragraph key={key++}>
  //       {PAYMENT_REVERSAL_MESSAGE}
  //     </Typography>
  //   );
  // }

  return <>{result}</>;
};

function Payment(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  //const { cookies } = props;
  const { merchant } = props.getMerchantDetails;
  const user = props.getLoginDetails;
  const paymentToken = props.getCheckoutPaymentToken;
  const order = props.getOrder;
  const location = getMerchantLocation(merchant, order.locationId);
  const orderRequestId = uuidv4();
  const cartDetails = props.getCartDetails;
  const cartTotal = props.getCartTotal;
  const cartDeliveryFee = props.getCartDeliveryFee;
  const collectionOrder = isCollectionOrder(order);
  const deliveryOrder = isDeliveryOrder(order);
  const paymentProvider = getMerchantPaymentProvider(merchant);
  const canPayOnline = orderEligibleForOnlinePayment(
    merchant.payment,
    collectionOrder
  );
  const onlinePaymentRequired = orderRequiresOnlinePayment(
    merchant.payment,
    collectionOrder
  );
  const customer = {
    name: order.customerName,
    email: order.customerEmail,
    phone: order.customerPhone,
    wants_marketing: order.customerMarketingOptIn,
    login_id: user.isAuthenticated ? user.userId : null,
  };
  const backgroundImageUrl = getCdnImageUrl(
    "web/background.webp",
    merchant.name
  );

  if (canPayOnline && paymentToken === null) {
    Sentry.captureMessage(
      "Attempt to load payment page without a payment intent."
    );
    navigate("/menu", { replace: true });
  }

  const [orderIsValid] = useState(
    Object.keys(cartDetails.items).length > 0 &&
      currentOrderIsValid({
        merchant,
        order,
        cartItems: cartDetails.items,
      })
  );
  const [paymentTime, setPaymentTime] = useState(PAY_NOW);
  const [placeOrderButtonEnabled, setPlaceOrderButtonEnabled] = useState(true);
  const [startCardPayment, shouldStartCardPayment] = useState(false);
  const [submittingOrder, setSubmittingOrder] = useState(false);
  const [showOrderFailureDialog, shouldShowOrderFailureDialog] =
    useState(false);
  const [orderFailureDialogTitle, setOrderFailureDialogTitle] = useState("");
  const [orderFailureDialogBody, setOrderFailureDialogBody] = useState(null);
  const [billingAddress /*, setBillingAddress*/] = useState(null);
  //const [validateBillingAddress, shouldValidateBillingAddress] = useState(false);
  // const [billingAddressType, setBillingAddressType] = useState(deliveryOrder
  //   ? BILLING_ADDRESS_DELIVERY
  //   : BILLING_ADDRESS_BILLING);
  //const [billingName, setBillingName] = useState(null);
  //const [validateBillingName, shouldValidateBillingName] = useState(false);

  const { dispatch } = props;

  if (!orderIsValid) {
    return <Navigate to="/" />;
  }

  /*
  function updateBillingName (newBillingName) {
    if (newBillingName) {
      const { firstName, lastName } = newBillingName;

      setCookie(cookies, BILLING_FIRST_NAME_COOKIE, firstName);
      setCookie(cookies, BILLING_LAST_NAME_COOKIE, lastName);
    }

    setBillingName(newBillingName);
    shouldValidateBillingName(false);
    shouldStartCardPayment(newBillingName !== null &&
      billingAddressType === BILLING_ADDRESS_DELIVERY);
  };

  function updateBillingAddress (newBillingAddress) {
    if (newBillingAddress) {
      const { addressLine1, addressLine2, addressCity, addressPostcode } =
      newBillingAddress;

      setCookie(cookies, BILLING_ADDRESS_1_COOKIE, addressLine1);
      setCookie(cookies, BILLING_ADDRESS_2_COOKIE, addressLine2);
      setCookie(cookies, BILLING_ADDRESS_CITY_COOKIE, addressCity);
      setCookie(cookies, BILLING_ADDRESS_POSTCODE_COOKIE, addressPostcode);
    }

    setBillingAddress(newBillingAddress);
    shouldValidateBillingAddress(false);
    shouldStartCardPayment(newBillingAddress !== null);
  };
  */

  function renderBreadcrumbs() {
    return isMobile ? null : (
      <StyledBreadcrumbs separator="›" aria-label="breadcrumb">
        <StyledBreadcrumbLink color="inherit" href="/menu">
          Order
        </StyledBreadcrumbLink>
        <StyledBreadcrumbLink color="inherit" href="/information">
          Information
        </StyledBreadcrumbLink>
        {deliveryOrder && (
          <StyledBreadcrumbLink color="inherit" href="/delivery">
            Delivery Address
          </StyledBreadcrumbLink>
        )}
        <Typography color="textPrimary">Payment</Typography>
      </StyledBreadcrumbs>
    );
  }

  function orderComplete(orderReference) {
    dispatch(setOrderReference({ orderReference })).then(() => {
      dispatch(removeAllItemsFromCart()).then(() => {
        navigate("/complete");
      });
    });
  }

  function handlePaymentTimeSelectionChange(event) {
    if (!startCardPayment) {
      setPaymentTime(event.target.value);
    }
  }

  function togglePaymentButtonEnabled(enabled) {
    setPlaceOrderButtonEnabled(enabled);
  }

  function renderPaymentTimeSelection() {
    if (onlinePaymentRequired) {
      return null;
    } else if (canPayOnline) {
      return (
        <StyledRadioGroupFormControl component="fieldset" margin="normal">
          <RadioGroup
            aria-label="paymentTime"
            name="paymentTime"
            value={paymentTime}
            onChange={handlePaymentTimeSelectionChange}
          >
            <StyledRadioGroupFormControlLabel
              id="payment-option-pay-now"
              value={PAY_NOW}
              control={<Radio />}
              label="Pay Now"
              className={cssStyles.firstRadioButtonOption}
            />
            <StyledRadioGroupFormControlLabel
              id="payment-option-pay-later"
              value={PAY_LATER}
              control={<Radio />}
              label="Pay Later"
            />
          </RadioGroup>
        </StyledRadioGroupFormControl>
      );
    } else {
      return renderInPersonPaymentMessage();
    }
  }

  function renderInPersonPaymentMessage() {
    const message = collectionOrder
      ? "Please pay in person when you collect your order."
      : "Please pay the delivery driver when your order is delivered.";

    return (
      <Typography variant="body1" align="left" paragraph>
        {message}
      </Typography>
    );
  }

  function renderPaymentForm() {
    if (canPayOnline) {
      if (paymentTime === PAY_NOW) {
        return (
          <StyledPaymentFormContainer id="card-payment-container">
            <PaymentProvider
              apiKey={paymentProvider.public_key}
              paymentToken={paymentToken.clientSecret}
              billingAddress={billingAddress}
              customer={customer}
              cartTotal={cartTotal}
              paymentButtonEnabled={placeOrderButtonEnabled}
              startCardPayment={startCardPayment}
              togglePaymentButtonEnabled={togglePaymentButtonEnabled}
              cardPaymentFailed={cardPaymentFailed}
              submitPlaceholderOrder={submitPlaceholderOrder}
              orderSubmissionFailed={orderFailureHandler}
              paymentSucceeded={paymentSucceeded}
            />

            {/*
            <Typography
              variant="h2"
              align="left"
              gutterBottom
            >
              Cardholder Name
            </Typography>
            <Typography
              variant="body1"
              align="left"
              gutterBottom
              className={cssStyles.promptText}
            >
              Use the name printed on the card.
            </Typography>

            <BillingNameForm
              prefill={billingNamePrefill}
              submit={shouldValidateBillingName}
              setBillingName={updateBillingName}
            />

            <Typography
              variant="h2"
              align="left"
              gutterBottom
            >
              Billing Address
            </Typography>
            <Typography
              variant="body1"
              align="left"
              gutterBottom
              className={cssStyles.promptText}
            >
              Use the address that matches the card you're using to pay.
            </Typography>
            */}

            {/*renderBillingAddressSelection()*/}
          </StyledPaymentFormContainer>
        );
      } else {
        return renderInPersonPaymentMessage();
      }
    }
  }

  /*
  function handleBillingAddressTypeChange(event) {
    setBillingAddressType(event.target.value);
  };

  renderBillingAddressSelection = () => {
    if (deliveryOrder) {
      return (
        <StyledRadioGroupFormControl
          component="fieldset"
          margin="normal"
          
        >
          <RadioGroup
            aria-label="billingAddress"
            name="billingAddress"
            value={billingAddressType}
            onChange={handleBillingAddressTypeChange}
          >
            <StyledRadioGroupFormControlLabel
              value={BILLING_ADDRESS_DELIVERY}
              control={<Radio />}
              label="Same as delivery address"
              className={cssStyles.firstRadioButtonOption}
            />
            <StyledRadioGroupFormControlLabel
              value={BILLING_ADDRESS_BILLING}
              control={<Radio />}
              label="Use a different billing address"
            />

            {billingAddressType === BILLING_ADDRESS_BILLING && (
              <StyledBillingAddressFormContainer>
                <BillingAddressForm
                  submit={shouldValidateBillingAddress}
                  setBillingAddress={updateBillingAddress}
                />
              </StyledBillingAddressFormContainer>
            )}
          </RadioGroup>
        </StyledRadioGroupFormControl>
      );
    } else {
      return (
        <StyledBillingAddressFormContainer>
          <BillingAddressForm
            submit={shouldValidateBillingAddress}
            setBillingAddress={updateBillingAddress}
          />
        </StyledBillingAddressFormContainer>
      );
    }
  };
  */

  function paymentSucceeded(orderReference) {
    if (startCardPayment) {
      shouldStartCardPayment(false);
    }
    orderComplete(orderReference);
  }

  function cardPaymentFailed() {
    shouldStartCardPayment(false);

    scroller.scrollTo("card-payment-container", {
      duration: 500,
      smooth: true,
      offset: -80,
    });
  }

  async function submitOrder(paymentType, paymentTokenId) {
    const orderItems = cartDetails.items;
    const orderItemsArray = Object.keys(orderItems).map((key) => {
      const item = orderItems[key];
      return {
        id: item.id,
        amount: item.price,
        quantity: item.qty,
        choices: item.options,
        extras: item.extras,
        purchased_with_loyalty: false,
      };
    });
    const deferredDelivery = order.fulfilmentTime
      ? moment(order.fulfilmentTime).utc().format("Y-MM-DD HH:mm:ss")
      : null;
    const beatTheQueueTime = isBeatTheQueueOrder(location, order)
      ? getCurrentBeatTheQueueTime(location)
      : null;

    var orderCharges = [];
    for (const charge of cartDetails.orderCharges) {
      orderCharges.push(charge.id);
    }

    var discounts = null;
    if (cartDetails.discounts) {
      discounts = cartDetails.discounts.map((discount) => {
        return {
          description: discount.lineItemText,
          amount: parseFloat(discount.discount),
          promotion_id: discount.id,
        };
      });
    }

    const orderRequest = {
      request_id: orderRequestId,
      device_id: navigator.userAgent,
      location_id: order.locationId,
      amount: cartTotal,
      loyalty_redemption: false,
      payment_surcharge: null,
      order_charges: orderCharges.length === 0 ? null : orderCharges,
      deferred_delivery: deferredDelivery,
      beat_the_queue_time: beatTheQueueTime,
      delivery_address: props.getDeliveryAddressForOrderSubmission,
      delivery_amount: cartDeliveryFee,
      customer: customer,
      notes: order.messageForRestaurant,
      order_items: orderItemsArray,
      discounts: discounts,
      app_platform: APP_PLATFORM,
      app_version: new Date().toDateString(),
      payment_nonce: null,
      stripe_payment_intent_id: paymentTokenId || null,
      device_data: null,
      payment_type: paymentType,
      placeholder: paymentType !== PAYMENT_TYPE_CASH,
    };

    // console.log(orderRequest);

    return new Promise((resolve, reject) => {
      setSubmittingOrder(true);

      postSubmitOrderRequest({
        locationId: order.locationId,
        orderRequest,
      })
        .then((orderReference) => {
          setSubmittingOrder(false);
          resolve(orderReference);
        })
        .catch((error) => {
          setSubmittingOrder(false);
          reject(error);
        });
    });
  }

  async function submitPlaceholderOrder(paymentType) {
    /*
    shouldValidateBillingName(true);
    if (billingAddressType === BILLING_ADDRESS_BILLING) {
      shouldValidateBillingAddress(true);
    }
    */
    return submitOrder(paymentType, paymentToken.id);
  }

  async function completeOrderWithCashPayment() {
    submitOrder(PAYMENT_TYPE_CASH)
      .then((orderReference) => orderComplete(orderReference))
      .catch((error) => orderFailureHandler(error));
  }

  function orderFailureHandler(error) {
    if (startCardPayment) {
      shouldStartCardPayment(false);
    }

    if (error === SUBMIT_ORDER_FAILURE_CLOSED) {
      //console.log("Failed to submit order because merchant is closed.");
      setOrderFailureDialogTitle(
        "Sorry, but we can't accept your order at this time"
      );
      setOrderFailureDialogBody(
        merchantClosedMessage(merchant.name, paymentTime)
      );
    } else {
      console.error("Failed to submit order.", error);
      setOrderFailureDialogTitle("Sorry, something has gone wrong");
      setOrderFailureDialogBody(orderFailureMessage(merchant, paymentTime));
    }
    shouldShowOrderFailureDialog(true);
  }

  function closeOrderFailureDialog() {
    shouldShowOrderFailureDialog(false);
  }

  function placeOrderButtonClicked() {
    if (!startCardPayment) {
      canPayOnline && paymentTime === PAY_NOW
        ? shouldStartCardPayment(true)
        : completeOrderWithCashPayment();
    }
  }

  return (
    <div>
      <Spinner text="Processing Payment" active={startCardPayment} />
      <Spinner
        id="submit-order-spinner"
        text="Submitting Order"
        active={submittingOrder}
      />

      <Header
        showCartIcon={false}
        merchant={merchant}
        loginCompletionUrl={window.location.pathname}
      />

      <main
        style={
          isMobile
            ? { background: "none" }
            : { backgroundImage: `url("${backgroundImageUrl}")` }
        }
        className={cssStyles.main}
      >
        {isMobile && (
          <StyledPaper square={true}>
            {renderBreadcrumbs()}
            <Typography variant="h2" align="left" gutterBottom>
              Payment
            </Typography>

            {renderPaymentTimeSelection()}

            {renderPaymentForm()}

            <Button
              id="place-order-button"
              variant="contained"
              fullWidth
              sx={{ marginTop: 2 }}
              color="primary"
              onClick={placeOrderButtonClicked}
              disabled={!placeOrderButtonEnabled}
            >
              {canPayOnline && paymentTime === PAY_NOW
                ? "Pay with Card"
                : "Place Order"}
            </Button>
          </StyledPaper>
        )}

        {!isMobile && (
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item xs={8}>
              <StyledPaper>
                {renderBreadcrumbs()}
                <Typography variant="h2" align="left" gutterBottom>
                  Payment
                </Typography>

                {renderPaymentTimeSelection()}

                {renderPaymentForm()}

                <Button
                  id="place-order-button"
                  variant="contained"
                  fullWidth
                  sx={{ marginTop: 2 }}
                  color="primary"
                  onClick={placeOrderButtonClicked}
                  disabled={!placeOrderButtonEnabled}
                >
                  {canPayOnline && paymentTime === PAY_NOW
                    ? "Pay with Card"
                    : "Place Order"}
                </Button>
              </StyledPaper>
            </Grid>

            <Grid item xs={4}>
              <OrderDetails
                order={order}
                items={cartDetails.items}
                discounts={cartDetails.discounts}
                orderCharges={cartDetails.orderCharges}
                cartTotal={cartTotal}
                deliveryFee={cartDeliveryFee}
              />
            </Grid>
          </Grid>
        )}

        <MyOrdersDialog
          dialogOpen={showOrderFailureDialog}
          title={orderFailureDialogTitle}
          body={orderFailureDialogBody}
          buttonText="OK"
          onClose={closeOrderFailureDialog}
          disableBackdropClick={false}
        />
      </main>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    getLoginDetails: getLoginDetails(state),
    getCartDetails: getCartDetails(state),
    getMerchantDetails: getMerchantDetails(state),
    getOrder: getOrder(state),
    getCartDeliveryFee: getCartDeliveryFee(state),
    getCartTotal: getCartTotal(state),
    getCheckoutPaymentToken: getCheckoutPaymentToken(state),
    getDeliveryAddressForOrderSubmission:
      getDeliveryAddressForOrderSubmission(state),
  };
}

Payment.propTypes = {
  dispatch: PropTypes.func.isRequired,

  getLoginDetails: PropTypes.object.isRequired,
  getCartDetails: PropTypes.object.isRequired,
  getMerchantDetails: PropTypes.object.isRequired,
  getOrder: PropTypes.object.isRequired,
  getCartDeliveryFee: PropTypes.number.isRequired,
  getCartTotal: PropTypes.string.isRequired,
  getCheckoutPaymentToken: PropTypes.object,
  getDeliveryAddressForOrderSubmission: PropTypes.object,
};

export default connect(mapStateToProps)(Payment);
