import React, { Component } from "react";
import PropTypes from "prop-types";
import isEmail from "validator/lib/isEmail";
import isLength from "validator/lib/isLength";

import { StyledLoginLink } from "./StyledComponents";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import {
  MAX_NAME_LENGTH,
  MAX_EMAIL_LENGTH,
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
  PASSWORD_VALIDATION_ERROR,
} from "./Login";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class SignupForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      password: "",
      emailError: false,
      nameError: false,
      passwordError: null,
    };
  }

  handleInputChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  onSubmit = (event) => {
    const { name, email, password } = this.state;

    event.preventDefault();

    if (!isLength(name, { min: 1, max: MAX_NAME_LENGTH })) {
      this.setState({ nameError: true });
    } else {
      this.setState({ nameError: false });

      if (
        !isEmail(email) ||
        !isLength(email, { min: 1, max: MAX_EMAIL_LENGTH })
      ) {
        this.setState({ emailError: true });
      } else {
        this.setState({ emailError: false });

        if (
          !isLength(password, {
            min: MIN_PASSWORD_LENGTH,
            max: MAX_PASSWORD_LENGTH,
          })
        ) {
          this.setState({ passwordError: PASSWORD_VALIDATION_ERROR });
        } else {
          this.setState({ passwordError: null });

          this.props.onSignup(name, email, password);
        }
      }
    }
  };

  hideSigbnUpError = () => {
    this.props.hideSignUpError();
  };

  render() {
    const { name, email, password, nameError, emailError, passwordError } =
      this.state;
    const { signUpError } = this.props;

    return (
      <Stack
        component="form"
        mt={2}
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={this.onSubmit}
      >
        <FormControl>
          <TextField
            value={name}
            id="name-input"
            label="Name"
            type="text"
            onChange={this.handleInputChange("name")}
            error={nameError}
          />
          {nameError && (
            <FormHelperText error={true}>
              Please enter your name.
            </FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <TextField
            value={email}
            id="email-input"
            label="Email"
            type="email"
            autoComplete="email"
            onChange={this.handleInputChange("email")}
            error={emailError}
          />
          {emailError && (
            <FormHelperText error={true}>
              Please enter your email address.
            </FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <TextField
            value={password}
            id="password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            onChange={this.handleInputChange("password")}
            error={passwordError !== null}
          />
          {passwordError && (
            <FormHelperText error={true}>{passwordError}</FormHelperText>
          )}
        </FormControl>

        <Button variant="contained" fullWidth color="primary" type="submit">
          Create Account
        </Button>
        <StyledLoginLink href="/login">Log In</StyledLoginLink>

        <Snackbar
          id="error-snackbar"
          open={signUpError !== null}
          onClose={this.hideSignUpError}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert onClose={this.hideSignUpError} severity="info">
            {signUpError}
          </Alert>
        </Snackbar>
      </Stack>
    );
  }
}

SignupForm.propTypes = {
  onSignup: PropTypes.func.isRequired,
  hideSignUpError: PropTypes.func.isRequired,
  signUpError: PropTypes.string,
};

export default SignupForm;
