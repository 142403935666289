import {
  POSTCODE_INIT,
  POSTCODE_REQUEST,
  POSTCODE_SUCCESS,
  POSTCODE_FAILURE,
} from "./postcodeConstants";

const initialState = {
  checkingDeliveryPostcode: false,
  checkingDeliveryPostcodeFailed: false,
  checkingDeliveryPostcodeSucceeded: false,
  canDeliverToPostcode: false,
  distanceToPostcode: 0,
  deliveryPostcode: null,
  deliveryLocationId: null,
};

const postcode = (state = initialState, action) => {
  switch (action.type) {
    case POSTCODE_INIT:
      return initialState;
    case POSTCODE_REQUEST:
      return {
        ...state,
        checkingDeliveryPostcode: true,
        checkingDeliveryPostcodeFailed: false,
        checkingDeliveryPostcodeSucceeded: false,
        canDeliverToPostcode: false,
        distanceToPostcode: 0,
        deliveryPostcode: null,
        deliveryLocationId: null,
      };
    case POSTCODE_SUCCESS:
      return {
        ...state,
        checkingDeliveryPostcode: false,
        checkingDeliveryPostcodeFailed: false,
        checkingDeliveryPostcodeSucceeded: true,
        canDeliverToPostcode: action.payload.canDeliverToPostcode,
        distanceToPostcode: action.payload.distanceToPostcode,
        deliveryPostcode: action.payload.postcode,
        deliveryLocationId: action.payload.locationId,
      };
    case POSTCODE_FAILURE:
      return {
        ...state,
        checkingDeliveryPostcode: false,
        checkingDeliveryPostcodeFailed: true,
        checkingDeliveryPostcodeSucceeded: false,
        canDeliverToPostcode: false,
        deliveryPostcode: null,
        deliveryLocationId: null,
      };
    default:
      return state;
  }
};

export default postcode;

// SELECTORS
// ================================================
export const checkingDeliveryPostcode = (state) => {
  return state.postcode.checkingDeliveryPostcode;
};

export const checkingDeliveryPostcodeSucceeded = (state) => {
  return state.postcode.checkingDeliveryPostcodeSucceeded;
};

export const checkingDeliveryPostcodeFailed = (state) => {
  return state.postcode.checkingDeliveryPostcodeFailed;
};

export const canDeliverToPostcode = (state) => {
  return state.postcode.canDeliverToPostcode;
};

export const getDeliveryPostcode = (state) => {
  return state.postcode.deliveryPostcode;
};

export const getDistanceToPostcode = (state) => {
  return state.postcode.distanceToPostcode;
};

export const getDeliveryLocationId = (state) => {
  return state.postcode.deliveryLocationId;
};
