import { post } from "../api/post";

export const postTableBookingRequest = (payload) => {
  return new Promise((resolve, reject) => {
    post("/table_booking", payload)
      .then((response) => {
        // console.log("post /table_booking result:", response);
        response.success ? resolve() : reject();
      })
      .catch((error) => {
        // console.error("post /table_booking failed:", error);
        reject(error);
      });
  });
};
