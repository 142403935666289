import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import isEmpty from "validator/lib/isEmpty";
import isEmail from "validator/lib/isEmail";
import isMobilePhone from "validator/lib/isMobilePhone";

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "../Switch/Switch";

import { MAX_TEXT_FIELD_INPUT_LENGTH } from "../../config";

const PREFIX = "ContactDetailsForm";

const classes = {
  container: `${PREFIX}-container`,
  formControl: `${PREFIX}-formControl`,
};

const Root = styled("form")(({ theme }) => ({
  [`&.${classes.container}`]: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.formControl}`]: {
    marginBottom: theme.spacing(2),
  },
}));

class ContactDetailsForm extends Component {
  constructor(props) {
    super(props);

    const { prefill } = this.props;

    this.state = {
      name: prefill.name || "",
      email: prefill.email || "",
      phone: prefill.phone || "",
      nameError: false,
      emailError: false,
      phoneError: false,
      marketingOptIn: prefill.marketingOptIn || false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.submit && this.props.submit) {
      this.validateContactDetails();
    }
  }

  handleInputChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleSwitchChange = (name) => (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  validateContactDetails = () => {
    const { name, email, phone, marketingOptIn } = this.state;
    const normalizedPhone = phone.replace(/\s/g, "");

    if (isEmpty(name, { ignore_whitespace: true })) {
      this.setState({
        nameError: true,
        emailError: false,
        phoneError: false,
      });
      this.props.setContactDetails(null);
    } else if (!isEmail(email)) {
      this.setState({
        nameError: false,
        emailError: true,
        phoneError: false,
      });
      this.props.setContactDetails(null);
    } else if (!isMobilePhone(normalizedPhone)) {
      this.setState({
        nameError: false,
        emailError: false,
        phoneError: true,
      });
      this.props.setContactDetails(null);
    } else {
      this.setState({
        nameError: false,
        emailError: false,
        phoneError: false,
      });
      this.props.setContactDetails({
        name: name.trim(),
        email: email.trim(),
        phone: normalizedPhone,
        marketingOptIn,
      });
    }
  };

  render() {
    const {
      name,
      email,
      phone,
      nameError,
      emailError,
      phoneError,
      marketingOptIn,
    } = this.state;

    return (
      <Root noValidate autoComplete="off" className={classes.container}>
        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            value={name}
            fullWidth
            id="name-input"
            label="Name"
            type="text"
            autoComplete="name"
            onChange={this.handleInputChange("name")}
            error={nameError}
            inputProps={{
              maxLength: MAX_TEXT_FIELD_INPUT_LENGTH,
            }}
          />
          {nameError && (
            <FormHelperText error={true}>
              Please enter your name.
            </FormHelperText>
          )}
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            value={email}
            fullWidth
            id="email-input"
            label="Email"
            type="email"
            autoComplete="email"
            onChange={this.handleInputChange("email")}
            error={emailError}
            inputProps={{
              maxLength: MAX_TEXT_FIELD_INPUT_LENGTH,
            }}
          />
          {emailError && (
            <FormHelperText error={true}>
              Please check your email address.
            </FormHelperText>
          )}
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            value={phone}
            fullWidth
            id="phone-input"
            label="Phone"
            type="tel"
            autoComplete="phone"
            onChange={this.handleInputChange("phone")}
            error={phoneError}
          />
          {phoneError && (
            <FormHelperText error={true}>
              Please enter your mobile phone number.
            </FormHelperText>
          )}
        </FormControl>

        <FormControlLabel
          className={classes.formControl}
          control={
            <Switch
              checked={marketingOptIn}
              onChange={this.handleSwitchChange()}
              name="marketingOptIn"
              sx={{ mx: 1 }}
            />
          }
          label="Send me exclusive offers by email"
        />
      </Root>
    );
  }
}

ContactDetailsForm.propTypes = {
  prefill: PropTypes.object.isRequired,
  submit: PropTypes.bool.isRequired,
  setContactDetails: PropTypes.func.isRequired,
};

export default ContactDetailsForm;
