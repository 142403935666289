export const INIT_CHECKOUT = "INIT_CHECKOUT";

export const START_CHECKOUT_REQUEST = "START_CHECKOUT_REQUEST";
export const START_CHECKOUT_SUCCESS = "START_CHECKOUT_SUCCESS";
export const START_CHECKOUT_FAILURE = "START_CHECKOUT_FAILURE";

export const SUBMIT_ORDER_FAILURE_CLOSED = "Closed";

// export const GET_PAYMENT_TOKEN_STATUS_REQUEST =
//   "GET_PAYMENT_TOKEN_STATUS_REQUEST";
// export const GET_PAYMENT_TOKEN_STATUS_SUCCESS =
//   "GET_PAYMENT_TOKEN_STATUS_SUCCESS";
// export const GET_PAYMENT_TOKEN_STATUS_FAILURE =
//   "GET_PAYMENT_TOKEN_STATUS_FAILURE";
