import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-scroll";

import { styled } from "@mui/material/styles";

import cssStyles from "./MenuCategoryList.module.scss";

const HEADER_HEIGHT = 65;

const StyledContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
}));

class MenuCategoryList extends Component {
  categorySelected = () => {
    if (this.props.onCategorySelection) {
      this.props.onCategorySelection();
    }
  };

  render() {
    const { menu, activeCatKey } = this.props;

    return (
      <StyledContainer>
        <ul className={cssStyles.menuCategoryList}>
          {menu.categories.reduce((output, category, categoryIndex) => {
            if (category.menu_items.length > 0) {
              const key = `cat-${categoryIndex}`;
              output.push(
                <li key={key}>
                  <Link
                    to={key}
                    spy={false}
                    smooth={true}
                    duration={250}
                    offset={-HEADER_HEIGHT}
                    className={key === activeCatKey ? cssStyles.activeCat : ""}
                    onClick={this.categorySelected}
                  >
                    {category.name}
                  </Link>
                </li>
              );
            }

            return output;
          }, [])}
        </ul>
      </StyledContainer>
    );
  }
}

MenuCategoryList.propTypes = {
  menu: PropTypes.object.isRequired,
  activeCatKey: PropTypes.string,
  onCategorySelection: PropTypes.func,
};

export default MenuCategoryList;
