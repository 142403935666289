import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const PREFIX = "Spinner";

const classes = {
  content: `${PREFIX}-content`,
  spinner: `${PREFIX}-spinner`,
  text: `${PREFIX}-text`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.content}`]: {
    textAlign: "center",
  },

  [`& .${classes.spinner}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.text}`]: {
    marginTop: theme.spacing(1),
  },
}));

class Spinner extends Component {
  render() {
    const { active, text, id } = this.props;

    return (
      <StyledDialog open={active}>
        <DialogContent id={id || null} className={classes.content}>
          <CircularProgress className={classes.spinner} />
          <Typography className={classes.text}>{text}</Typography>
        </DialogContent>
      </StyledDialog>
    );
  }
}

Spinner.propTypes = {
  id: PropTypes.string,
  active: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export default Spinner;
