import { formatMoney } from "./currency.mjs";

const totalEligibleForDiscounts = (items) => {
  var result = 0.0;

  Object.keys(items).forEach(function (key) {
    var item = items[key];
    if (!item.excludedFromOffers) {
      result += item.price * item.qty;
    }
  });

  return result;
};

const getApplicablePromotions = (
  items,
  promotions,
  minDeliveryOrder,
  cartTotal,
  collectionOrder
) => {
  const eligibleCartTotal = totalEligibleForDiscounts(items);

  var applyDeliveryFee = true;
  var promotionPromptMessage = null;
  var promotionPromptValue = 0;

  var discounts = [];
  var difference;
  var discountAmount;
  var discount;
  var existingPercentageDiscount;

  var promotionTypes = {
    PERCENTAGE: 2,
    DELIVERY: 3,
    FREE_ITEM: 4,
    COLLECTION_ONLY_PERCENTAGE: 8,
  };

  // Only show one message, about the nearest obtainable discount
  promotions.forEach(function (promotion) {
    if (
      promotion.promotion_type_id === promotionTypes.DELIVERY &&
      !collectionOrder
    ) {
      if (cartTotal >= promotion.threshold) {
        // console.log("Order qualifies for free delivery");
        applyDeliveryFee = false;
      } else {
        difference = promotion.threshold - cartTotal;
        if (
          promotionPromptMessage === null ||
          difference < promotionPromptValue
        ) {
          if (cartTotal >= minDeliveryOrder) {
            promotionPromptMessage =
              "Spend an additional " +
              formatMoney(difference) +
              " to qualify for free delivery.";
            promotionPromptValue = difference;
          }
        }
      }
    } else if (promotion.promotion_type_id === promotionTypes.PERCENTAGE) {
      if (
        (promotion.applies_to_collection && collectionOrder) ||
        (promotion.applies_to_delivery && !collectionOrder)
      ) {
        if (eligibleCartTotal >= promotion.threshold) {
          discountAmount = (eligibleCartTotal * promotion.value).toFixed(2);
          discount = {
            type: promotionTypes.PERCENTAGE,
            lineItemText: promotion.line_item_text,
            value: promotion.value,
            discount: discountAmount,
            id: promotion.id,
          };

          // If there's an existing percentage promotion we don't want to apply both. But we will replace the existing promotion
          // if this one is worth more.
          existingPercentageDiscount = false;
          for (var i = 0; i < discounts.length; i++) {
            if (discounts[i].type === promotionTypes.PERCENTAGE) {
              existingPercentageDiscount = true;
              if (discountAmount > discounts[i].discount) {
                discounts[i] = discount;
              }
            }
          }

          if (!existingPercentageDiscount) {
            discounts.push(discount);
          }
        } else {
          difference = promotion.threshold - eligibleCartTotal;
          if (
            promotionPromptMessage === null ||
            difference < promotionPromptValue
          ) {
            promotionPromptMessage =
              "Spend an additional " +
              formatMoney(difference) +
              " to qualify for a " +
              Math.round(promotion.value * 100) +
              "% discount.";

            promotionPromptValue = difference;
          }
        }
      }
    } else if (
      promotion.promotion_type_id === promotionTypes.COLLECTION_ONLY_PERCENTAGE
    ) {
      if (
        (promotion.applies_to_collection && collectionOrder) ||
        (promotion.applies_to_delivery && !collectionOrder)
      ) {
        if (eligibleCartTotal >= promotion.threshold) {
          discountAmount = (eligibleCartTotal * promotion.value).toFixed(2);
          discount = {
            type: promotionTypes.PERCENTAGE,
            lineItemText: promotion.line_item_text,
            value: promotion.value,
            discount: discountAmount,
            id: promotion.id,
          };

          // If there's an existing percentage promotion we don't want to apply both. But we will replace the existing promotion
          // if this one is worth more.
          existingPercentageDiscount = false;
          for (var j = 0; j < discounts.length; j++) {
            if (discounts[j].type === promotionTypes.PERCENTAGE) {
              existingPercentageDiscount = true;
              if (discountAmount > discounts[j].discount) {
                discounts[j] = discount;
              }
            }
          }

          if (!existingPercentageDiscount) {
            discounts.push(discount);
          }
        } else {
          difference = promotion.threshold - eligibleCartTotal;
          if (
            promotionPromptMessage === null ||
            difference < promotionPromptValue
          ) {
            promotionPromptMessage =
              "Spend an additional " +
              formatMoney(difference) +
              " to qualify for a " +
              Math.round(promotion.value * 100) +
              "% discount.";

            promotionPromptValue = difference;
          }
        }
      }
    }
  });

  return {
    applyDeliveryFee,
    discounts,
    promotionPromptMessage,
  };
};

export default getApplicablePromotions;
