import { post } from "../api/post";

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  VERIFY_TOKEN_REQUEST,
  VERIFY_TOKEN_SUCCESS,
  VERIFY_TOKEN_FAILURE,
  LOGOUT,
  PASSWORD_RESET,
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_FAILURE,
  SET_LOGGED_IN_USER_PHONE,
  SET_LOGGED_IN_USER_MARKETING_OPT_IN,
  SET_LOGGED_IN_USER_DELIVERY_ADDRESS,
  PASSWORD_RESET_ERROR_TOKEN_EXPIRED,
} from "../reducers/loginConstants";

export function postLoginRequest(payload) {
  return (dispatch) => {
    dispatch(loginRequest(payload));

    post("/login", payload)
      .then((result) => {
        //console.log("post /login result:", result);
        if (result.success) {
          dispatch(
            loginSuccess({
              token: result.token,
              refreshToken: result.refreshToken,
              userId: result.userId,
              name: result.name,
              email: result.email,
              phone: result.phone,
              marketingOptIn: result.marketingOptIn,
              deliveryAddress: result.deliveryAddress,
              //billingAddress: result.billingAddress,
            })
          );
        } else {
          dispatch(loginFailure(result.message));
        }
      })
      .catch((err) => {
        dispatch(loginFailure(err));
      });
  };
}

export function loginRequest(payload) {
  return { type: LOGIN_REQUEST, payload };
}

export function loginSuccess(payload) {
  localStorage.setItem("token", payload.token);
  localStorage.setItem("refreshToken", payload.refreshToken);
  return { type: LOGIN_SUCCESS, payload };
}

export function loginFailure(payload) {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  return { type: LOGIN_FAILURE, payload };
}

export function postSignupRequest(payload) {
  return (dispatch) => {
    dispatch(signupRequest(payload));

    post("/signup", payload)
      .then((result) => {
        // console.log("post /signup result:", result);
        if (result.success) {
          dispatch(signupSuccess(result));
        } else {
          if (result.error && result.error === "User Exists") {
            dispatch(signupFailure({ err: { message: result.error } }));
          } else {
            dispatch(signupFailure(result));
          }
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(signupFailure(err));
      });
  };
}

export function signupRequest(payload) {
  return { type: SIGN_UP_REQUEST, payload };
}

export function signupSuccess(payload) {
  localStorage.setItem("token", payload.token);
  localStorage.setItem("refreshToken", payload.refreshToken);
  return { type: SIGN_UP_SUCCESS, payload };
}

export function signupFailure(payload) {
  return { type: SIGN_UP_FAILURE, payload };
}

export function postLogoutRequest() {
  return (dispatch) => {
    dispatch(logout());
    post("/logout");
  };
}

export function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  return { type: LOGOUT };
}

export const checkLogInStatus = (payload) => (dispatch, getState) => {
  return new Promise((resolve) => {
    dispatch(verifyTokenRequest(payload));

    const { isAuthenticated } = getState().login;
    if (!isAuthenticated) {
      post("/verifyToken")
        .then((result) => {
          //console.log("post /verifyToken result:", result);

          if (true === result.success) {
            const { userId, email, token, refreshToken } = result;
            dispatch(
              verifyTokenSuccess({ userId, email, token, refreshToken })
            );
          } else {
            dispatch(verifyTokenFailure({ errorMsg: result.message }));
          }
          resolve();
        })
        .catch((err) => {
          console.error("/verifyToken login failure", err);
          dispatch(verifyTokenFailure({ errorMsg: err }));
          resolve();
        });
    }
  });
};

export function verifyTokenRequest(payload) {
  return { type: VERIFY_TOKEN_REQUEST, payload };
}

export function verifyTokenSuccess(payload) {
  localStorage.setItem("token", payload.token);
  localStorage.setItem("refreshToken", payload.refreshToken);
  return { type: VERIFY_TOKEN_SUCCESS, payload };
}

export function verifyTokenFailure(payload) {
  localStorage.removeItem("token");
  localStorage.setItem("refreshToken", payload.refreshToken);
  return { type: VERIFY_TOKEN_FAILURE, payload };
}

export function postPasswordResetRequest(email) {
  return (dispatch) => {
    dispatch(passwordReset());
    post("/passwordReset", { email })
      .then((result) => {
        //console.log("post /passwordReset result:", result);
      })
      .catch((err) => {
        //console.log("/passwordReset login failure", err);
      });
  };
}

export function passwordReset() {
  return { type: PASSWORD_RESET };
}

export function postSetNewPasswordRequest(payload) {
  return (dispatch) => {
    dispatch(newPasswordRequest(payload));

    post("/newPassword", payload)
      .then((result) => {
        //console.log("post /newPassword result:", result);
        if (result.success) {
          dispatch(newPasswordSuccess());
        } else {
          dispatch(
            newPasswordFailure(
              result.error &&
                result.error === PASSWORD_RESET_ERROR_TOKEN_EXPIRED
                ? { message: PASSWORD_RESET_ERROR_TOKEN_EXPIRED }
                : null
            )
          );
        }
      })
      .catch((err) => {
        dispatch(newPasswordFailure(err));
      });
  };
}

export function newPasswordRequest() {
  return { type: NEW_PASSWORD_REQUEST };
}

export function newPasswordSuccess() {
  return { type: NEW_PASSWORD_SUCCESS };
}

export function newPasswordFailure(err) {
  return { type: NEW_PASSWORD_FAILURE, err };
}

export function setLoggedInUserPhone(phone) {
  return { type: SET_LOGGED_IN_USER_PHONE, phone };
}

export function setLoggedInUserMarketingOptIn(marketingOptIn) {
  return { type: SET_LOGGED_IN_USER_MARKETING_OPT_IN, marketingOptIn };
}

export function setLoggedInUserDeliveryAddress(deliveryAddress) {
  return { type: SET_LOGGED_IN_USER_DELIVERY_ADDRESS, deliveryAddress };
}
