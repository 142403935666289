import * as Sentry from "@sentry/react";
import { post } from "../api/post";

import {
  POSTCODE_INIT,
  POSTCODE_REQUEST,
  POSTCODE_SUCCESS,
  POSTCODE_FAILURE,
} from "../reducers/postcodeConstants";

export function initPostcodeChecker() {
  return (dispatch) => {
    dispatch({ type: POSTCODE_INIT });
  };
}

export function checkDeliveryPostcode(payload) {
  return (dispatch) => {
    dispatch(postcodeRequest(payload));

    post("/postcode", payload)
      .then((result) => {
        //console.log("post /canDeliverToPostcode result:", result);

        if (result.success) {
          dispatch(
            postcodeSuccess({
              canDeliverToPostcode: result.canDeliverToPostcode,
              distanceToPostcode: result.distanceToPostcode / 1000,
              postcode: result.postcode,
              locationId: result.locationId,
            })
          );
        } else {
          dispatch(postcodeFailure());
        }
      })
      .catch((err) => {
        console.error("Postcode check failed.");
        Sentry.captureException(err);
        dispatch(postcodeFailure(err));
      });
  };
}

export function postcodeRequest(postcode) {
  return { type: POSTCODE_REQUEST, postcode };
}

export function postcodeSuccess(payload) {
  return { type: POSTCODE_SUCCESS, payload };
}

export function postcodeFailure(payload) {
  return { type: POSTCODE_FAILURE, payload };
}
