import React, { Component } from "react";
import PropTypes from "prop-types";
import isLength from "validator/lib/isLength";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import {
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
  PASSWORD_VALIDATION_ERROR,
} from "./Login";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class NewPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordError: null,
    };
  }

  handleInputChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  onSubmit = (event) => {
    const { password } = this.state;

    event.preventDefault();

    if (
      !isLength(password, {
        min: MIN_PASSWORD_LENGTH,
        max: MAX_PASSWORD_LENGTH,
      })
    ) {
      this.setState({ passwordError: PASSWORD_VALIDATION_ERROR });
    } else {
      this.setState({
        passwordError: null,
      });
      this.props.onSetNewPassword(password);
    }
  };

  hideLoginError = () => {
    this.setState({ showLoginError: false });
  };

  render() {
    const { errorMessage, email } = this.props;
    const { password, passwordError } = this.state;

    return (
      <Stack
        component="form"
        mt={2}
        spacing={2}
        noValidate
        autoComplete="off"
        onSubmit={this.onSubmit}
      >
        <FormControl>
          <TextField
            value={email}
            id="email-input"
            label="Email"
            type="email"
            autoComplete="off"
            readOnly={true}
            disabled
          />
        </FormControl>

        <FormControl>
          <TextField
            value={password}
            id="password-input"
            label="Password"
            type="password"
            autoComplete="current-password"
            onChange={this.handleInputChange("password")}
            error={passwordError !== null}
          />
          {passwordError && (
            <FormHelperText error={true}>{passwordError}</FormHelperText>
          )}
        </FormControl>

        <Button variant="contained" color="primary" type="submit" fullWidth>
          Set New Password
        </Button>

        <Snackbar
          open={errorMessage !== null}
          onClose={this.hideLoginError}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert onClose={this.hideLoginError} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Stack>
    );
  }
}

NewPasswordForm.propTypes = {
  email: PropTypes.string,
  onSetNewPassword: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

export default NewPasswordForm;
