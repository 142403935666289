import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getCartDetails,
  getCartTotal,
  getOrder,
  getOrderCharges,
  getCartDeliveryFee,
} from "../../reducers";
import clsx from "clsx";
import { formatMoney } from "../../utils/currency";
import moment from "moment";
import CartItem from "./CartItem";
import { isCollectionOrder } from "../../utils/order";

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import cssStyles from "./Cart.module.scss";

const minDeliveryOrderValueWarningMessage = (minDeliveryOrderValue) => {
  return `The minimum order value for delivery orders is ${formatMoney(
    minDeliveryOrderValue
  )}`;
};

const minCollectionOrderValueWarningMessage = (minCollectionOrderValue) => {
  return `The minimum order value for collection orders is ${formatMoney(
    minCollectionOrderValue
  )}`;
};

const totalOrderCharges = (orderCharges) => {
  var result = 0.0;

  for (const charge of orderCharges) {
    result += Number(charge.amount);
  }

  return result.toFixed(2);
};

class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promotionPromptMessage: null,
    };
  }

  incrementQty = (id) => {
    this.props.incrementQty(id);
  };

  decrementQty = (id) => {
    this.props.decrementQty(id);
  };

  removeItem = (id) => {
    this.props.removeItem(id);
  };

  renderItems = () => {
    const items = this.props.getCartDetails.items;

    return Object.keys(items).map((key) => {
      const item = items[key];

      return (
        <li className={cssStyles.item} key={key}>
          <CartItem
            id={key}
            name={item.name}
            price={item.price * item.qty}
            quantity={item.qty}
            incrementQty={this.incrementQty}
            decrementQty={this.decrementQty}
            removeItem={this.removeItem}
          />
        </li>
      );
    });
  };

  renderDiscounts = () => {
    const discounts = this.props.getCartDetails.discounts;
    var result = [];

    discounts.forEach(function (discount) {
      result.push(
        <div key={discount.id}>
          <div className={cssStyles.discount}>
            <p className={cssStyles.left}>{discount.lineItemText}</p>
            <p className={cssStyles.right}>{formatMoney(discount.discount)}</p>
          </div>
          <div style={{ clear: "both" }} />
        </div>
      );
    });

    return result;
  };

  renderDelivery = (cartTotal, cart) => {
    if (!cart.collectionOrder && cart.applyDeliveryFee) {
      const deliveryFee = this.props.getCartDeliveryFee;
      const orderCharges = totalOrderCharges(this.props.getOrderCharges);

      if (deliveryFee > 0) {
        return (
          <div>
            <div className={cssStyles.itemTotal}>
              <p className={cssStyles.left}>Item Total</p>
              <p className={cssStyles.right}>
                {formatMoney(cartTotal - deliveryFee - orderCharges)}
              </p>
            </div>
            <div style={{ clear: "both" }} />

            <div className={cssStyles.delivery}>
              <p className={cssStyles.left}>Delivery</p>
              <p className={cssStyles.right}>{formatMoney(deliveryFee)}</p>
            </div>
            <div style={{ clear: "both" }} />
          </div>
        );
      }
    }
  };

  renderOrderCharges = () => {
    const orderCharges = this.props.getOrderCharges;

    var result = [];

    orderCharges.forEach(function (charge) {
      result.push(
        <div key={charge.id}>
          <div className={cssStyles.orderCharge}>
            <p className={cssStyles.left}>{charge.name}</p>
            <p className={cssStyles.right}>{formatMoney(charge.amount)}</p>
          </div>
          <div style={{ clear: "both" }} />
        </div>
      );
    });

    return orderCharges.length === 0 ? null : result;
  };

  renderPromotionPrompt = (cartEmpty, warningMessage) => {
    const cart = this.props.getCartDetails;

    if (!cartEmpty && warningMessage === null && cart.promotionPromptMessage) {
      return (
        <div className={cssStyles.promotionPrompt}>
          {cart.promotionPromptMessage}
        </div>
      );
    } else {
      return null;
    }
  };

  renderWarning = (cartEmpty, warningMessage) => {
    if (!cartEmpty && warningMessage) {
      return <div className={cssStyles.warning}>{warningMessage}</div>;
    } else {
      return null;
    }
  };

  minDeliveryOrderValue = () => {
    const cart = this.props.getCartDetails;
    const cartTotal = this.props.getCartTotal;
    const deliveryFee = this.props.getCartDeliveryFee;

    if (cart.collectionOrder) {
      return true;
    } else {
      return cartTotal - deliveryFee >= cart.minDeliveryOrder;
    }
  };

  minCollectionOrderValue = () => {
    const cart = this.props.getCartDetails;
    const cartTotal = this.props.getCartTotal;

    if (!cart.collectionOrder) {
      return true;
    } else {
      return cartTotal >= cart.minCollectionOrder;
    }
  };

  getWarningMessage = () => {
    const cart = this.props.getCartDetails;
    var warningMessage = null;

    if (!this.minDeliveryOrderValue()) {
      warningMessage = minDeliveryOrderValueWarningMessage(
        cart.minDeliveryOrder
      );
    } else if (!this.minCollectionOrderValue()) {
      warningMessage = minCollectionOrderValueWarningMessage(
        cart.minCollectionOrder
      );
    }
    return warningMessage;
  };

  getFulfilmentText = () => {
    const order = this.props.getOrder;

    if (isCollectionOrder(order)) {
      return (
        <>
          <Typography color="#FFF" variant="subtitle2" gutterBottom>
            Collecting from{" "}
            <span className="bold">{`${order.locationName}, ${order.locationAddress}`}</span>{" "}
            {order.fulfilmentTime !== null &&
              `at ${moment(order.fulfilmentTime).format("h:mm A")}`}
          </Typography>
          {!order.fulfilmentTime && (
            <>
              <Typography
                color="#FFF"
                variant="subtitle2"
                align="center"
                sx={{ marginTop: 2 }}
              >
                Current collection time
              </Typography>
              <Typography
                color="#FFF"
                variant="subtitle1"
                align="center"
                sx={{ fontWeight: "light", fontSize: 24 }}
              >
                {this.props.fulfilmentTime}
              </Typography>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          <Typography color="#FFF" variant="subtitle2" gutterBottom>
            Delivering to{" "}
            <span className="bold">{order.deliveryAddress.postcode}</span>{" "}
            {order.fulfilmentTime !== null &&
              `at ${moment(order.fulfilmentTime).format("h:mm A")}`}
          </Typography>
          {!order.fulfilmentTime && (
            <>
              <Typography
                color="#FFF"
                variant="subtitle2"
                align="center"
                sx={{ marginTop: 2 }}
              >
                Current delivery time
              </Typography>
              <Typography
                color="#FFF"
                variant="subtitle1"
                align="center"
                sx={{ fontWeight: "light", fontSize: 24 }}
              >
                {this.props.fulfilmentTime}
              </Typography>
            </>
          )}
        </>
      );
    }
  };

  startCheckout = () => {
    this.props.startCheckout();
  };

  render() {
    const cart = this.props.getCartDetails;
    const cartEmpty = Object.keys(cart.items).length === 0;
    const cartTotal = cartEmpty ? 0.0 : this.props.getCartTotal;
    const { fulfilmentTime } = this.props;
    const warningMessage = this.getWarningMessage();

    return (
      <Box p={2} className={cssStyles.cart}>
        <Typography variant="h6" align="center">
          Your Order
        </Typography>
        {fulfilmentTime && (
          <Card
            sx={{ backgroundColor: "#31353d", marginTop: 2, marginBottom: 2 }}
          >
            <Box sx={{ padding: 2, paddingBottom: 1 }}>
              {this.getFulfilmentText()}
            </Box>
          </Card>
        )}

        <div
          className={clsx({
            [cssStyles.items]: true,
            [cssStyles.notEmpty]: !cartEmpty,
          })}
        >
          <ul className={cssStyles.itemList}>{this.renderItems()}</ul>

          {this.renderDiscounts()}

          {!cartEmpty && this.renderDelivery(cartTotal, cart)}
          {!cartEmpty && this.renderOrderCharges()}

          <div className={cssStyles.total}>
            <p className={clsx([cssStyles.left, cssStyles.cartTotal])}>Total</p>
            <p className={clsx([cssStyles.right, cssStyles.cartTotal])}>
              {formatMoney(cartTotal)}
            </p>
          </div>
          <div style={{ clear: "both" }} />
        </div>

        <div className={cssStyles.footer}>
          {this.renderPromotionPrompt(cartEmpty, warningMessage)}
          {this.renderWarning(cartEmpty, warningMessage)}

          <Box mt={1}>
            <Button
              id="start-checkout-button"
              variant="contained"
              color="primary"
              fullWidth
              disabled={
                cartEmpty ||
                cartTotal === 0 ||
                !this.minDeliveryOrderValue() ||
                !this.minCollectionOrderValue()
              }
              onClick={this.startCheckout}
            >
              {!cartEmpty ? `Checkout ${formatMoney(cartTotal)}` : "Checkout"}
            </Button>
          </Box>
        </div>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  return {
    getCartDetails: getCartDetails(state),
    getCartTotal: getCartTotal(state),
    getOrder: getOrder(state),
    getOrderCharges: getOrderCharges(state),
    getCartDeliveryFee: getCartDeliveryFee(state),
  };
}

Cart.propTypes = {
  fulfilmentTime: PropTypes.string,
  getCartDetails: PropTypes.object.isRequired,
  getCartTotal: PropTypes.string.isRequired,
  getCartDeliveryFee: PropTypes.number.isRequired,
  getOrder: PropTypes.object.isRequired,
  getOrderCharges: PropTypes.array.isRequired,
  incrementQty: PropTypes.func.isRequired,
  decrementQty: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  startCheckout: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Cart);
