import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import PropTypes, { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { connect } from "react-redux";
import {
  getLoginDetails,
  getOrder,
  getCartDetails,
  getCartDeliveryFee,
  getCartTotal,
  getMerchantDetails,
} from "../../reducers";
import { setOrderCustomer } from "../../actions/orderActions";
import {
  setLoggedInUserPhone,
  setLoggedInUserMarketingOptIn,
} from "../../actions/loginActions";
import { isCollectionOrder, isDeliveryOrder } from "../../utils/order";
import { currentOrderIsValid } from "../../utils/currentOrderIsValid";
import { setCookie, getCookie } from "../../utils/cookies";
import { getCdnImageUrl } from "../../utils/assetUtils";
import { MAX_MULTILINE_TEXT_FIELD_INPUT_LENGTH } from "../../config";
import Header from "../Header/Header";
import OrderDetails from "./OrderDetails";
import ContactDetailsForm from "./ContactDetailsForm";

import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import {
  StyledBreadcrumbs,
  StyledBreadcrumbLink,
  StyledPaper,
} from "./StyledComponents";
import cssStyles from "./Checkout.module.scss";

const ContainerWithBottomMargin = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledContactDetailsContainer = styled("div")(({ theme }) => ({
  padding: 0,
  marginTop: theme.spacing(3),
}));

const CONTACT_COOKIE_NAME = "contact";

const getPrefillValue = (field, guest, cookiedValues, loginValues) => {
  return guest
    ? cookiedValues
      ? cookiedValues[field]
      : null
    : loginValues[field];
};

function Information(props) {
  const { cookies } = props;
  const { merchant } = props.getMerchantDetails;
  const order = props.getOrder;
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const loginDetails = props.getLoginDetails;
  const guest = !loginDetails.isAuthenticated;
  const previousContactDetailsJson = getCookie(cookies, CONTACT_COOKIE_NAME);
  const previousContactDetails = previousContactDetailsJson
    ? JSON.parse(previousContactDetailsJson)
    : null;
  const cartDetails = props.getCartDetails;
  const cartTotal = props.getCartTotal;
  const cartDeliveryFee = props.getCartDeliveryFee;
  const backgroundImageUrl = getCdnImageUrl(
    "web/background.webp",
    merchant.name
  );

  const prefill = {
    name: getPrefillValue("name", guest, previousContactDetails, loginDetails),
    email: getPrefillValue(
      "email",
      guest,
      previousContactDetails,
      loginDetails
    ),
    phone: getPrefillValue(
      "phone",
      guest,
      previousContactDetails,
      loginDetails
    ),
    marketingOptIn: getPrefillValue(
      "marketingOptIn",
      guest,
      previousContactDetails,
      loginDetails
    ),
  };

  const [validateContactDetails, setShouldValidateContactDetails] =
    useState(false);
  const [messageForRestaurant, setMessageForRestaurant] = useState("");
  const [orderIsValid] = useState(
    currentOrderIsValid({
      merchant,
      order,
      cartItems: cartDetails.items,
    })
  );

  if (!orderIsValid) {
    return <Navigate to="/" />;
  }

  function setContactDetails(contactDetails) {
    setShouldValidateContactDetails(false);

    if (contactDetails) {
      const { cookies } = props;

      setCookie(cookies, CONTACT_COOKIE_NAME, JSON.stringify(contactDetails));

      props
        .dispatch(setOrderCustomer({ ...contactDetails, messageForRestaurant }))
        .then((isAuthenticated) => {
          if (isAuthenticated) {
            props.dispatch(setLoggedInUserPhone(contactDetails.phone));
            props.dispatch(
              setLoggedInUserMarketingOptIn(contactDetails.marketingOptIn)
            );
          }

          if (isDeliveryOrder(order)) {
            navigate("/delivery");
          } else {
            navigate("/payment");
          }
        });
    }
  }

  function messageForRestaurantChanged(event) {
    setMessageForRestaurant(event.target.value);
  }

  function renderBreadcrumbs() {
    return isMobile ? null : (
      <StyledBreadcrumbs separator="›" aria-label="breadcrumb">
        <StyledBreadcrumbLink color="inherit" href="/menu">
          Order
        </StyledBreadcrumbLink>
        <Typography color="textPrimary">Information</Typography>
      </StyledBreadcrumbs>
    );
  }

  function renderCollectionDetails() {
    const order = props.getOrder;

    if (isCollectionOrder(order)) {
      return (
        <ContainerWithBottomMargin>
          <Typography variant="h2" align="left" gutterBottom>
            Collecting Order From
          </Typography>
          <Typography variant="body1" align="left" gutterBottom>
            {`${order.locationName}, ${order.locationAddress}`}
          </Typography>
        </ContainerWithBottomMargin>
      );
    }
  }

  function renderContactInformation() {
    return (
      <div>
        <Typography variant="h2" align="left" gutterBottom>
          Contact Information
        </Typography>
        <StyledContactDetailsContainer>
          <ContactDetailsForm
            prefill={prefill}
            submit={validateContactDetails}
            setContactDetails={setContactDetails}
          />
        </StyledContactDetailsContainer>
      </div>
    );
  }

  function renderMessageForRestaurant() {
    return (
      <ContainerWithBottomMargin>
        <Typography variant="h2" align="left" gutterBottom>
          Message for Restaurant
        </Typography>
        <Box sx={{ marginTop: 2 }}>
          <TextField
            id="standard-textarea"
            value={messageForRestaurant}
            onChange={messageForRestaurantChanged}
            placeholder="(optional)"
            maxRows="3"
            inputProps={{
              maxLength: MAX_MULTILINE_TEXT_FIELD_INPUT_LENGTH,
            }}
            fullWidth
            multiline
          />
        </Box>
      </ContainerWithBottomMargin>
    );
  }

  function submit() {
    setShouldValidateContactDetails(true);
  }

  return (
    <>
      <Header
        showCartIcon={false}
        merchant={merchant}
        loginCompletionUrl={window.location.pathname}
      />

      <main
        style={
          isMobile
            ? { background: "none" }
            : { backgroundImage: `url("${backgroundImageUrl}")` }
        }
        className={cssStyles.main}
      >
        {isMobile && (
          <StyledPaper square={true}>
            {renderBreadcrumbs()}
            {renderCollectionDetails()}
            {renderContactInformation()}
            {renderMessageForRestaurant()}
            <Button
              id="continue-button"
              variant="contained"
              color="primary"
              fullWidth
              onClick={submit}
            >
              Continue
            </Button>
          </StyledPaper>
        )}

        {!isMobile && (
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item xs={8}>
              <StyledPaper>
                {renderBreadcrumbs()}
                {renderCollectionDetails()}
                {renderContactInformation()}
                {renderMessageForRestaurant()}
                <Button
                  id="continue-button"
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={submit}
                >
                  Continue
                </Button>
              </StyledPaper>
            </Grid>

            <Grid item xs={4}>
              <OrderDetails
                order={order}
                items={cartDetails.items}
                discounts={cartDetails.discounts}
                orderCharges={cartDetails.orderCharges}
                cartTotal={cartTotal}
                deliveryFee={cartDeliveryFee}
              />
            </Grid>
          </Grid>
        )}
      </main>
    </>
  );
}

function mapStateToProps(state) {
  return {
    getLoginDetails: getLoginDetails(state),
    getOrder: getOrder(state),
    getCartDetails: getCartDetails(state),
    getCartDeliveryFee: getCartDeliveryFee(state),
    getCartTotal: getCartTotal(state),
    getMerchantDetails: getMerchantDetails(state),
  };
}

Information.propTypes = {
  getLoginDetails: PropTypes.object.isRequired,
  getOrder: PropTypes.object.isRequired,
  getCartDetails: PropTypes.object.isRequired,
  getCartDeliveryFee: PropTypes.number.isRequired,
  getCartTotal: PropTypes.string.isRequired,
  getMerchantDetails: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  cookies: instanceOf(Cookies).isRequired,
};

export default connect(mapStateToProps)(withCookies(Information));
