const getPalette = (theme) => {
  return {
    type: "light",
    primary: { main: "#000000", hover: "#6CB949" },
    secondary: { main: "#000000", hover: "#6CB949" },
    accent: "#000000",
    info: {
      main: "#000000",
    },
    error: {
      main: "#FF0040",
    },
    white: {
      main: "#FFF",
    },
  };
};

export default getPalette;
