import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import AddCircle from "@mui/icons-material/AddCircle";
import RemoveCircle from "@mui/icons-material/RemoveCircle";

const PREFIX = "QuantityPicker";

const classes = {
  container: `${PREFIX}-container`,
  icon: `${PREFIX}-icon`,
  quantity: `${PREFIX}-quantity`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.container}`]: {
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },

  [`& .${classes.icon}`]: {
    width: 30,
    height: 30,
    color: theme.palette.primary.main,
    verticalAlign: "bottom",
  },

  [`& .${classes.quantity}`]: {
    display: "inline",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

function QuantityPicker(props) {
  const { quantityUpdated } = props;

  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    quantityUpdated(quantity);
  }, [quantityUpdated, quantity]);

  function incrementQty() {
    setQuantity(quantity + 1);
  }

  function decrementQty() {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }

  return (
    <Root className={classes.container}>
      <RemoveCircle className={classes.icon} onClick={decrementQty} />
      <Typography variant="h6" className={classes.quantity}>
        {quantity}
      </Typography>
      <AddCircle className={classes.icon} onClick={incrementQty} />
    </Root>
  );
}

QuantityPicker.propTypes = {
  quantityUpdated: PropTypes.func.isRequired,
};

export default QuantityPicker;
