import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMerchantDetails } from "../../reducers";
import moment from "moment";

import Header from "../Header/Header";
import { getCdnImageUrl } from "../../utils/assetUtils";

import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import cssStyles from "./Hours.module.scss";

export const StyledPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: 500,
  padding: theme.spacing(2),
  textAlign: "center",
}));

const TIME_FORMAT = "HH:mm";

const getWeekdayNameForIndex = (dayIndex) => {
  return (
    [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][dayIndex] || ""
  );
};

function Hours(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { merchant } = props.getMerchantDetails;
  const backgroundImageUrl = getCdnImageUrl(
    "web/background.webp",
    merchant.name
  );

  function getFirstOpenPeriod(day) {
    if (day.open_for_lunch) {
      const lunchOpening = moment(day.lunch_opening_time, TIME_FORMAT).format(
        "h:mm A"
      );
      const lunchClosing = moment(day.lunch_closing_time, TIME_FORMAT).format(
        "h:mm A"
      );
      return `${lunchOpening} - ${lunchClosing}`;
    } else if (!day.open_for_dinner) {
      return "Closed";
    } else {
      const dinnerOpening = moment(day.dinner_opening_time, TIME_FORMAT).format(
        "h:mm A"
      );
      const dinnerClosing = moment(day.dinner_closing_time, TIME_FORMAT).format(
        "h:mm A"
      );
      return `${dinnerOpening} - ${dinnerClosing}`;
    }
  }

  function getSecondOpenPeriod(day) {
    if (
      (!day.open_for_lunch && day.open_for_dinner) ||
      (!day.open_for_lunch && !day.open_for_dinner)
    ) {
      return "";
    } else if (day.open_for_dinner) {
      const dinnerOpening = moment(day.dinner_opening_time, TIME_FORMAT).format(
        "h:mm A"
      );
      const dinnerClosing = moment(day.dinner_closing_time, TIME_FORMAT).format(
        "h:mm A"
      );
      return `${dinnerOpening} - ${dinnerClosing}`;
    } else if (!day.open_for_lunch) {
      return "Closed";
    }
  }

  function renderHours(hoursText) {
    return (
      <TableCell
        padding="none"
        sx={{
          border: "none",
        }}
      >
        {hoursText}
      </TableCell>
    );
  }

  function renderHoursForDay(day) {
    const weekdayName = getWeekdayNameForIndex(day.day);
    const firstOpenPeriod = getFirstOpenPeriod(day);
    const secondOpenPeriod = getSecondOpenPeriod(day);
    if (isMobile) {
      return (
        <TableRow
          key={day.day}
          sx={{
            "&:last-child td, &:last-child th": { border: 0 },
          }}
        >
          <TableCell
            padding="none"
            sx={{ paddingBottom: 0.5, paddingTop: 0.5 }}
          >
            <Table>
              <TableBody>
                <TableRow sx={{ border: "none" }}>
                  <TableCell rowSpan={2} sx={{ width: 110, border: "none" }}>
                    {weekdayName}
                  </TableCell>
                  {renderHours(firstOpenPeriod)}
                </TableRow>
                <TableRow
                  sx={{
                    display: secondOpenPeriod === "" ? "none" : "table-cell",
                  }}
                >
                  {renderHours(secondOpenPeriod)}
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      );
    } else {
      return (
        <TableRow
          key={day.day}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell>{weekdayName}</TableCell>
          <TableCell>{firstOpenPeriod}</TableCell>
          <TableCell>{secondOpenPeriod}</TableCell>
        </TableRow>
      );
    }
  }

  function renderHoursForLocation(location) {
    const days = location.trading_days
      .slice()
      .sort((a, b) => (a.day > b.day ? 1 : -1));
    var daysJSX = [];

    for (let i = 1; i <= days.length; i++) {
      const j = i === 7 ? 0 : i;
      daysJSX.push(renderHoursForDay(days[j]));
    }

    return daysJSX;
  }

  function renderOpeningHours() {
    var openingHours = [];

    for (let location of merchant.locations) {
      var title;

      if (merchant.locations.length > 1) {
        title = location.name;
      } else {
        title = "Opening Hours";
      }

      openingHours.push(
        <StyledPaper key={location.id} square={isMobile}>
          <Typography variant="h2" gutterBottom>
            {title}
          </Typography>

          <TableContainer>
            <Table>
              <TableBody>{renderHoursForLocation(location)}</TableBody>
            </Table>
          </TableContainer>
        </StyledPaper>
      );
    }

    return openingHours;
  }

  return (
    <>
      <Header showNav={true} merchant={merchant} loginCompletionUrl="/" />
      <main
        style={
          isMobile && merchant.locations.length === 1
            ? { background: "none" }
            : { backgroundImage: `url("${backgroundImageUrl}")` }
        }
        className={cssStyles.main}
      >
        {renderOpeningHours()}
      </main>
    </>
  );
}

function mapStateToProps(state) {
  return {
    getMerchantDetails: getMerchantDetails(state),
  };
}

Hours.propTypes = {
  getMerchantDetails: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Hours);
