import {
  INIT_CART,
  ADD_ITEM,
  REMOVE_ITEM,
  INCREMENT_ITEM_QTY,
  DECREMENT_ITEM_QTY,
  REMOVE_ALL_ITEMS,
  APPLY_PROMOTIONS,
} from "../reducers/cartConstants";

export const initCart = (location, order, promotions, fees) => (dispatch) => {
  dispatch({
    type: INIT_CART,
    location,
    order,
    promotions,
    fees,
  });

  return Promise.resolve();
};

export const addItemToCart = (item) => {
  return (dispatch) => {
    dispatch({
      type: ADD_ITEM,
      item,
    });
    return Promise.resolve();
  };
};

export const removeItemFromCart = (cartItemId) => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ITEM,
      cartItemId,
    });
    return Promise.resolve();
  };
};

export const incrementCartItemQuantity = (cartItemId) => {
  return (dispatch) => {
    dispatch({
      type: INCREMENT_ITEM_QTY,
      cartItemId,
    });
    return Promise.resolve();
  };
};

export const decrementCartItemQuantity = (cartItemId) => {
  return (dispatch) => {
    dispatch({
      type: DECREMENT_ITEM_QTY,
      cartItemId,
    });
    return Promise.resolve();
  };
};

export const applyPromotionsToCart = (promotions) => (dispatch) => {
  dispatch({
    type: APPLY_PROMOTIONS,
    promotions,
  });

  return Promise.resolve();
};

export const removeAllItemsFromCart = () => (dispatch) => {
  dispatch({
    type: REMOVE_ALL_ITEMS,
  });

  return Promise.resolve();
};
