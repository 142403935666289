import * as Sentry from "@sentry/react";
import { merchantHasMultipleLocations } from "./merchant";
import { isCollectionOrder } from "./order";

//const MY_ORDERS_VALIDATION_ERROR = 'My Orders Order Validation Error.';

const validationError = (message) => {
  //throw MY_ORDERS_VALIDATION_ERROR + message;
  if (process.env.NODE_ENV !== "production") {
    console.error(`${window.location.pathname} ${message}`);
  } else {
    Sentry.captureMessage(message);
  }
};

export function currentOrderIsValid(params) {
  const { merchant, order, cartItems } = params;

  if (!order.type) {
    //validationError("Order type is not set.");
    return false;
  }

  if (merchantHasMultipleLocations(merchant) && order.locationId === null) {
    validationError(
      "Merchant has multiple locations but no location has been selected."
    );
    return false;
  }

  if (!isCollectionOrder(order) && order.deliveryAddress.postcode === null) {
    validationError("Order is for delivery but we don't have a postcode.");
    return false;
  }

  if (
    ["/information", "/payment"].some((v) =>
      window.location.pathname.includes(v)
    )
  ) {
    if (!cartItems || cartItems.length === 0) {
      validationError("Attempting to check-out with an empty cart.");
      return false;
    }
  }

  return true;
}
