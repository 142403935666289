import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { formatMoney } from "../../utils/currency";
import { getCdnMenuImageUrl } from "../../utils/assetUtils";
import { renderTags } from "../../utils/menu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import cssStyles from "./MenuItem.module.scss";
import allergenStyles from "./Allergens.module.scss";

const StyledMenuItemContainer = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const StyledTypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  maxHeight: 70,
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
}));

const StyledTypographyCaption = styled(Typography)(({ theme }) => ({
  fontWeight: 100,
  fontSize: 16,
  [theme.breakpoints.down("sm")]: {
    fontSize: 14,
  },
}));

function renderImage(merchantName, itemName, image) {
  if (image) {
    const imageSrc = getCdnMenuImageUrl(image, merchantName);
    return (
      <img className={cssStyles.itemImage} src={imageSrc} alt={itemName} />
    );
  }
}

function MenuItem(props) {
  const { item, numberInCart, merchantName, collectionOrder, isMobile } = props;
  const aboutClass = item.image
    ? cssStyles.aboutContainer
    : cssStyles.aboutContainerNoImage;
  const price =
    collectionOrder && item.price_collection
      ? item.price_collection
      : item.price;

  return (
    <StyledMenuItemContainer className={cssStyles.container}>
      <div className={aboutClass}>
        <StyledTypographyTitle variant="h6" gutterBottom={!isMobile}>
          {item.name}
        </StyledTypographyTitle>
        <StyledTypographyCaption
          className={cssStyles.description}
          sx={{ marginBottom: 0.5 }}
          paragraph
        >
          {item.description}
        </StyledTypographyCaption>

        <div className={cssStyles.price}>
          <Typography variant="subtitle1" display="inline">
            {formatMoney(price)}
          </Typography>
          {numberInCart === 0 && (
            <Box className={cssStyles.allergens} sx={{}}>
              {renderTags(item, allergenStyles)}
            </Box>
          )}

          {numberInCart > 0 && (
            <Typography
              variant="subtitle1"
              display="inline"
              color="secondary"
              ml={2}
            >{`${numberInCart} in basket`}</Typography>
          )}
        </div>
      </div>
      {renderImage(merchantName, item.name, item.image)}
    </StyledMenuItemContainer>
  );
}

MenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  collectionOrder: PropTypes.bool.isRequired,
  numberInCart: PropTypes.number.isRequired,
  merchantName: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default MenuItem;
