import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { postLogoutRequest } from "../../actions/loginActions";
import { postSetNewPasswordRequest } from "../../actions/loginActions";
import { getLoginDetails, getMerchantDetails } from "../../reducers";
import { PASSWORD_RESET_ERROR_TOKEN_EXPIRED } from "../../reducers/loginConstants";
import { usePrevious } from "../../utils/state";
import { getCdnImageUrl } from "../../utils/assetUtils";
import queryString from "query-string";

import Header from "../Header/Header";
import NewPasswordForm from "./NewPasswordForm";
import { StyledPaper } from "./StyledComponents";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import cssStyles from "./Login.module.scss";

const ERROR_MESSAGE_GENERIC =
  "Sorry, but we were unable to reset your password.";
const ERROR_MESSAGE_TOKEN_EXPIRED =
  "Sorry, but your password reset token has expired. Please request a new link to reset your password.";

function NewPassword(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const queryStringValues = queryString.parse(location.search);

  if (
    !queryStringValues ||
    !queryStringValues.email ||
    !queryStringValues.token
  ) {
    console.error("Missing expected query string values.");
    navigate("/", { replace: true });
  }

  const { merchant } = props.getMerchantDetails;
  const loginDetails = props.getLoginDetails;
  const { email, token } = queryStringValues;
  const backgroundImageUrl = merchant
    ? getCdnImageUrl("web/background.webp", merchant.name)
    : null;

  const [errorMessage, setErrorMessage] = useState(null);
  const [resetSuccess, setResetSuccess] = useState(false);

  const previousLoginDetails = usePrevious(props.getLoginDetails);

  useEffect(() => {
    props.dispatch(postLogoutRequest());
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (
      previousLoginDetails &&
      previousLoginDetails.isAuthenticating &&
      !loginDetails.isAuthenticating
    ) {
      if (loginDetails.error) {
        if (loginDetails.error === PASSWORD_RESET_ERROR_TOKEN_EXPIRED)
          setErrorMessage(
            loginDetails.error === PASSWORD_RESET_ERROR_TOKEN_EXPIRED
              ? ERROR_MESSAGE_TOKEN_EXPIRED
              : ERROR_MESSAGE_GENERIC
          );
        setResetSuccess(false);
      } else {
        setErrorMessage(null);
        setResetSuccess(true);
      }
    }
  }, [previousLoginDetails, loginDetails]);

  function setNewPassword(password) {
    setErrorMessage(null);
    props.dispatch(postSetNewPasswordRequest({ email, token, password }));
  }

  return (
    <>
      {merchant && <Header showLoginIcon={false} merchant={merchant} />}

      <main
        style={
          isMobile || !merchant
            ? { background: "none" }
            : { backgroundImage: `url("${backgroundImageUrl}")` }
        }
        className={cssStyles.main}
      >
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Set a new password
          </Typography>

          {resetSuccess && (
            <>
              <p>Your password has been reset</p>
              <Button
                style={{ color: "white" }}
                variant="contained"
                color="primary"
                href="/login"
                fullWidth
              >
                Log In
              </Button>
            </>
          )}

          {!resetSuccess && (
            <NewPasswordForm
              email={email}
              onSetNewPassword={setNewPassword}
              errorMessage={errorMessage}
            />
          )}
        </StyledPaper>
      </main>
    </>
  );
}

function mapStateToProps(state) {
  return {
    getLoginDetails: getLoginDetails(state),
    getMerchantDetails: getMerchantDetails(state),
  };
}

NewPassword.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getMerchantDetails: PropTypes.object.isRequired,
  getLoginDetails: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(NewPassword);
